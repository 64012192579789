<div class="pb-3 mt2r">
  <div *ngIf="!storeStatus" class="row m-2">
    <!-- <p>查無店家 或 店家已下架 test123</p> ---->
  </div>
  <div class="row" *ngIf="storeStatus">
    <div class="col-md-8 col-12 mb-sm-0 mb-2 px-0">
      <div class="mask">
        <button
          class="btn-floating btn-lg mdbWavesEffect overlay hide"
          (click)="subscribeStore(storeInfo)"
        >
          <i
            class="fas fa-heart text-black-50"
            *ngIf="!storeInfo.user_like"
          ></i>
          <i class="fas fa-heart pink-text" *ngIf="storeInfo.user_like"></i>
        </button>
        <app-image *ngIf="storeStatus"></app-image>
      </div>
    </div>
    <div class="col-md-4 col-12 grey lighten-3">
      <div class="row align-middle">
        <div class="mx-auto my-5 pt-2 text-center mt0">
          <h1 class="h1-responsive">{{ storeInfo.store_alias }}</h1>
          <div class="text-md-left mt-4">
            <p class="grey-text">
              <i class="fas fa-map-marker-alt"></i> {{ storeInfo.address }}
            </p>
            <p class="grey-text">
              <i class="fas fa-mountain"></i> 海拔 {{ storeInfo.altitude }} 公尺
            </p>
            <!--<p class="grey-text">
              <i class="fas fa-phone"></i>
              <a href="tel:{{ storeInfo.tel }}">
                {{ storeInfo.tel }} (客服電話)</a
              >
            </p>
            <p class="grey-text">
              <i class="fas fa-mobile-alt"></i>
              <a href="tel:{{ storeInfo.tel_boss }}">
                {{ storeInfo.tel_boss }} (營主電話)</a
              >
            </p>-->
            <p class="grey-text">
              <i class="far fa-calendar-check"></i> 開放訂位日：{{
                storeInfo.open_date | utcDateFormat
              }}
            </p>
            <p class="grey-text">
              <i class="far fa-calendar-times"></i> 最後可訂位日：{{
                storeInfo.close_date | utcDateFormat
              }}
            </p>

            <p class="grey-text">
              <i class="fas fa-times"></i>
              {{ storeInfo.weekday_open | weekdayFormat }}
            </p>
            <div class="d-flex justify-content-center">
              <mdb-badge default="true" class="mx-1 text-center">
                {{ storeInfo.area }}</mdb-badge
              >
              <mdb-badge default="true" class="mx-1 text-center">
                {{ storeInfo.city }}</mdb-badge
              >
              <mdb-badge default="true" class="mx-1 text-center">
                {{ storeInfo.district }}</mdb-badge
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="storeStatus" class="mt-1">
    <div class="row my-1">
      <div
        class="btn-group btn-group-md w-100 px-0 buttons"
        role="group"
        aria-label="Button group with nested dropdown"
      >
        <button
          mdbBtn
          type="button"
          color="info"
          class="waves-light mr-1 px-0 button w-100"
          pageScroll
          [pageScrollOffset]="100"
          [pageScrollDuration]="0"
          href="#casecalendar"
          mdbWavesEffect
        >
          空位查詢
        </button>
        <button
          mdbBtn
          type="button"
          color="info"
          (click)="showBook()"
          class="waves-light ml-1 px-0 button w-100"
          mdbWavesEffect
        >
          {{
            !userLoggedin
              ? "訂位前請先登入"
              : !userVerified
              ? "訂位前請先綁定手機"
              : "立即訂位"
          }}
        </button>
      </div>
    </div>
    <div class="row my-1">
      <div
        class="btn-group btn-group-md w-100 px-0 buttons"
        role="group"
        aria-label="Button group with nested dropdown"
      >
        <button
          mdbBtn
          type="button"
          color="info"
          class="waves-light mr-1 px-0 w-100 button"
          (click)="lineServiceSupport(storeInfo.store_name)"
          mdbWavesEffect
        >
          愛露營客服
        </button>
        <button
          mdbBtn
          *ngIf="storeInfo.store_name == 'glamping900'"
          type="button"
          color="info"
          class="waves-light ml-1 px-0 w-100 button"
          (click)="telCall(storeInfo.tel_boss, false)"
          mdbWavesEffect
        >
          撥打斑比客服 {{ storeInfo.tel_boss }}
        </button>
        <!-- <button
          mdbBtn
          *ngIf="storeInfo.store_name != 'glamping900'"
          type="button"
          color="info"
          class="waves-light ml-1 px-0 w-100 button"
          (click)="telCall(storeInfo.tel_boss, false)"
          mdbWavesEffect
        >
          夜衝預約
        </button> -->
      </div>
    </div>
    <app-info></app-info>
    <app-booking-calendar
      id="casecalendar"
      class="center"
      (clickedDateEvent)="calDateClicked($event)"
      [storeName]="storeInfo.store_name"
      caseIndexType="case"
      [showRestOfCaseIndex]="showRestOfCaseIndex"
      [fromEgg]="false"
    ></app-booking-calendar>
    <app-stuff
      #storeStuff
      [closeDate]="closeDate"
      [openDate]="openDate"
      [weekdayOpen]="weekdayOpen"
      [userLoggedin]="userLoggedin"
      [userVerified]="userVerified"
    ></app-stuff>
    <!-- <div class="text-center">
      <h4 class="title">周邊資訊</h4>
    </div>
    <div class="text-center">
      <agm-map
        style="width: 100%; height: 500px; margin: 0 auto;"
        *ngIf="storeInfo"
        [zoom]="13"
        [scrollwheel]="false"
        [latitude]="storeInfo.gps_latitude"
        [longitude]="storeInfo.gps_longitude"
      >
        <agm-marker
          [zoom]="13"
          [scrollwheel]="false"
          [latitude]="storeInfo.gps_latitude"
          [longitude]="storeInfo.gps_longitude"
        ></agm-marker>
      </agm-map>
    </div> -->

    <!-- <div class="grey lighten-3 py-5"><app-review></app-review></div> -->
  </div>
</div>
