import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from "@angular/fire/compat/firestore";

export interface ImageInterface {
  caption: string;
  fileName: string;
  status: boolean;
  id: string;
  imageType: string;
  orig_url: string;
  path: string;
  storeName: string;
  thumbnail_400c_url: string;
  thumbnail_600c_url: string;
  thumbnail_1200c_url: string;
  openNewPage: boolean;
  routeTo: string;
  sequence: number;
  for_laptop?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class CarouselService {
  imageCollection: AngularFirestoreCollection<ImageInterface>;
  imageLaptopCollection: AngularFirestoreCollection<ImageInterface>;

  constructor(private afs: AngularFirestore) {
    this.imageCollection = this.afs.collection("images", (ref) =>
      ref
        .where("storeName", "==", "carousel")
        .where("status", "==", true)
        .where("for_laptop", "==", false)
        .orderBy("sequence")
    );

    this.imageLaptopCollection = this.afs.collection("images", (ref) =>
      ref
        .where("storeName", "==", "carousel")
        .where("status", "==", true)
        .where("for_laptop", "==", true)
        .orderBy("sequence")
    );
  }

  replaceImageUrlToCdnIp(obj) {
    for (const key in obj) {
      if (
        typeof obj[key] === "string" &&
        obj[key].includes(
          "https://storage.googleapis.com/icamping-tree.appspot.com"
        )
      ) {
        obj[key] = obj[key].split("?")[0];
        obj[key] = obj[key].replace(
          "https://storage.googleapis.com/icamping-tree.appspot.com",
          "https://cdn.icamping.app"
        );
      }
    }
  }

  list(): Observable<any[]> {
    // ['added', 'modified', 'removed']
    return this.imageCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          this.replaceImageUrlToCdnIp(data);
          //console.log(data);
          return { id: a.payload.doc.id, ...data };
        });
      })
    );
  }

  listLaptop(): Observable<any[]> {
    // ['added', 'modified', 'removed']
    return this.imageLaptopCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          this.replaceImageUrlToCdnIp(data);
          //console.log(data);
          return { id: a.payload.doc.id, ...data };
        });
      })
    );
  }
}
