import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/compat/firestore";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, delay, concatMap } from "rxjs/operators";
import { BehaviorSubject, Observable, of, from } from "rxjs";

interface ImageInterface {
  birdview: boolean;
  caption: string;
  fileName: string;
  golden: boolean;
  imageType: string;
  orig_url: string;
  path: string;
  storeName: string;
  thumbnail_256_url: string;
  thumbnail_600c_url: string;
}

interface ConditionInterface {
  name: Array<string>;
}

interface FacilityInterface {
  name: Array<string>;
}

@Injectable()
export class StoreService {
  conditionCollection: AngularFirestoreCollection<ConditionInterface>;
  facilityCollection: AngularFirestoreCollection<FacilityInterface>;
  storeImageCollection: AngularFirestoreCollection<ImageInterface>;
  pageLoadingSubject = new BehaviorSubject<boolean>(true);
  likeStores: Observable<Object>;
  likeStoreNames = new Set();

  constructor(private _http: HttpClient, private afs: AngularFirestore) {
    // console.log(this.likeStores);
    this.getUserLikedStores();
  }

  getUserLikedStores() {
    this.likeStores = this.storesListUserLike();
    this.likeStores.subscribe((res: any) => {
      if (!res.items) {
        return;
      }
      res.items.forEach((storeObj: any) => {
        this.likeStoreNames.add(storeObj.store_name);
      });
    });
  }

  pushLikeStoreName(store_name) {
    this.likeStoreNames.add(store_name);
  }

  removeLikeStoreName(store_name) {
    this.likeStoreNames.delete(store_name);
  }

  getPageLoading() {
    console.log("calling getPageLoading...");
    return this.pageLoadingSubject.asObservable();
  }

  showPageLoading() {
    console.log("calling showPageLoading...");
    // hide page loading bar after 5s.
    from([true, false])
      .pipe(concatMap((bools) => of(bools).pipe(delay(5000))))
      .subscribe((bool) => {
        this.pageLoadingSubject.next(bool);
      });
  }

  hidePageLoading() {
    console.log("calling hidePageLoading...");
    // this.pageLoadingSubject.next(false);
  }

  getLikedStoreNames() {
    return this.likeStoreNames;
  }

  conditionList() {
    this.conditionCollection =
      this.afs.collection<ConditionInterface>("condition");
    return this.conditionCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          return a.payload.doc.data() as ConditionInterface;
        });
      })
    );
  }

  externalLinkStoreNameList(storeName: string) {
    return this._http.get(
      `${environment.cloudEndpointGuestTier2}/externalLink/store_name/list`,
      {
        params: {
          store_name: storeName,
          key: environment.cloudEndpointApiKey,
        },
      }
    );
  }

  facilityList() {
    this.facilityCollection =
      this.afs.collection<FacilityInterface>("facility");
    return this.facilityCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          return a.payload.doc.data() as FacilityInterface;
        });
      })
    );
  }

  reviewStoreNameList(storeName: string) {
    return this._http.get(
      `${environment.cloudEndpointGuestTier2}/review/store_name/list`,
      {
        params: { store_name: storeName, key: environment.cloudEndpointApiKey },
      }
    );
  }

  storesListUserLike() {
    if (this.likeStores === undefined) {
      this.likeStores = this._http.get(
        `${environment.cloudEndpointGuestTier1}/store/list`,
        {
          params: {
            only_show_user_like: true,
            key: environment.cloudEndpointApiKey,
          },
        }
      );
    }
    return this.likeStores;
  }

  storeNameGet(storeName: string) {
    return this._http.get(
      `${environment.cloudEndpointGuestTier1}/store/name/get`,
      {
        params: { store_name: storeName, key: environment.cloudEndpointApiKey },
      }
    );
  }

  replaceImageUrlToCdnIp(obj) {
    for (const key in obj) {
      if (
        typeof obj[key] === "string" &&
        obj[key].includes(
          "https://storage.googleapis.com/icamping-tree.appspot.com"
        )
      ) {
        obj[key] = obj[key].split("?")[0];
        obj[key] = obj[key].replace(
          "https://storage.googleapis.com/icamping-tree.appspot.com",
          "https://cdn.icamping.app"
        );
      }
    }
  }

  storeNameGetImage(storeName: string) {
    this.storeImageCollection = this.afs.collection<ImageInterface>(
      "images",
      (ref) =>
        ref
          .where("storeName", "==", storeName)
          .where("imageType", "==", "store")
          .orderBy("golden", "desc")
    );

    return this.storeImageCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          this.replaceImageUrlToCdnIp(data);
          console.log(data);
          return data;
        });
      })
    );
  }

  storeNameGetImageLimit(storeName: string) {
    this.storeImageCollection = this.afs.collection<ImageInterface>(
      "images",
      (ref) =>
        ref
          .where("storeName", "==", storeName)
          .where("imageType", "==", "store")
          .orderBy("golden", "desc")
          .limit(1)
    );

    return this.storeImageCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          this.replaceImageUrlToCdnIp(data);
          //console.log(data);
          return data;
        });
      })
    );
  }

  storeNameGetBirdEyeViewImage(storeName: string) {
    this.storeImageCollection = this.afs.collection<ImageInterface>(
      "images",
      (ref) =>
        ref
          .where("storeName", "==", storeName)
          .where("birdview", "==", true)
          .limit(1)
    );

    return this.storeImageCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          this.replaceImageUrlToCdnIp(data);
          console.log(data);
          return data;
        });
      })
    );
  }

  storeNameGetStuffImage(storeName: string, stuffName: string) {
    this.storeImageCollection = this.afs.collection<ImageInterface>(
      "images",
      (ref) =>
        ref
          .where("storeName", "==", storeName)
          .where("stuffName", "==", stuffName)
          .where("imageType", "==", "stuff")
          .orderBy("golden", "desc")
    );

    return this.storeImageCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          this.replaceImageUrlToCdnIp(data);
          console.log(data);
          return data;
        });
      })
    );
  }

  storeNameGetStuffImageLimit(storeName: string, stuffName: string) {
    this.storeImageCollection = this.afs.collection<ImageInterface>(
      "images",
      (ref) =>
        ref
          .where("storeName", "==", storeName)
          .where("stuffName", "==", stuffName)
          .where("imageType", "==", "stuff")
          .orderBy("golden", "desc")
          .limit(1)
    );

    return this.storeImageCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          this.replaceImageUrlToCdnIp(data);
          console.log(data);
          return data;
        });
      })
    );
  }

  storeNameGetExperienceStuffGoldenImage(
    storeName: string,
    experienceName: string
  ) {
    this.storeImageCollection = this.afs.collection<ImageInterface>(
      "images",
      (ref) =>
        ref
          .where("storeName", "==", storeName)
          .where("imageType", "==", "experience")
          .where("golden", "==", true)
          .where("experienceName", "==", experienceName)
    );

    return this.storeImageCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          this.replaceImageUrlToCdnIp(data);
          // console.log(data);
          return data;
        });
      })
    );
  }

  storeNameGetExperienceImages(storeName: string, experienceName: string) {
    this.storeImageCollection = this.afs.collection<ImageInterface>(
      "images",
      (ref) =>
        ref
          .where("storeName", "==", storeName)
          .where("isExperience", "==", true)
          .where("experienceName", "==", experienceName)
    );

    return this.storeImageCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          this.replaceImageUrlToCdnIp(data);
          console.log(data);
          return data;
        });
      })
    );
  }

  storeNameGetExperienceGoldenImage(storeName: string, experienceName: string) {
    this.storeImageCollection = this.afs.collection<ImageInterface>(
      "images",
      (ref) =>
        ref
          .where("storeName", "==", storeName)
          .where("golden", "==", true)
          .where("isExperience", "==", true)
          .where("experienceName", "==", experienceName)
    );

    return this.storeImageCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          this.replaceImageUrlToCdnIp(data);
          console.log(data);
          return data;
        });
      })
    );
  }

  stuffStoreNameList(storeName: string) {
    return this._http.get(
      `${environment.cloudEndpointGuestTier1}/stuff/store_name/list`,
      {
        params: { store_name: storeName, key: environment.cloudEndpointApiKey },
      }
    );
  }

  stuffExperienceNameList(experienceName: string) {
    return this._http.get(
      `${environment.cloudEndpointGuestTier1}/stuff/experience_name/list`,
      {
        params: {
          experience_name: experienceName,
          key: environment.cloudEndpointApiKey,
        },
      }
    );
  }
}
