<app-loading-spinner *ngIf="showSpinner"></app-loading-spinner>
<div *ngIf="!showSpinner" class="section magazine-section mt2r">
  <div class="hm">
    <h3 class="text-center h3-responsive">
      <strong>{{ this.slogan ? this.slogan : "營地搜尋結果" }}</strong>
      <a
        *ngIf="this.slogan === ''"
        class="btn btn-info btn-rounded btn-md"
        routerLink="/store/search"
        >重新選擇搜尋條件</a
      >
      <!-- <a *ngIf="this.slogan === '愛露營精選店家'" -->
      <a
        *ngIf="this.slogan.length > 0"
        class="btn btn-info btn-rounded btn-md"
        routerLink="/"
        >回首頁</a
      >
      <!-- <a
        class="btn btn-info btn-rounded btn-md"
        routerLink="{{ this.slogan ? '/' : '/store/search' }}"
        >{{
          this.slogan == "愛露營精選店家" ? "回首頁" : "重新選擇搜尋條件"
        }}</a
      > -->
      <!-- <button
          class="btn btn-info btn-rounded btn-md"
          (click)="showFilter = !showFilter">
            {{showFilter? '隱藏搜尋條件': '顯示搜尋條件'}}
        </button> -->
    </h3>

    <div class="row text-sm-left" *ngIf="stores">
      <div *ngIf="stores.length === 0">
        <div class="col">
          <p class="font-weight-bold dark-grey-text">
            系統搜尋中請稍候！
          </p>
        </div>
      </div>
      <div
        class="col-lg-3 col-sm-6 col-12 mb-3 bighero"
        *ngFor="let store of stores | storeLiker: x"
      >
        <div class="card">
          <div
            class="view overlay rounded z-depth-1 waves-light"
            mdbWavesEffect
            id="{{ store.store_name }}"
          >
            <img class="img-fluid" src="{{ store.image }}" alt="" />
            <a [routerLink]="['/store/' + store.store_name]">
              <div class="mask rgba-white-slight"></div>
            </a>
            <div class="ab-action-btn" (click)="subscribeStore(store)">
              <a
                mdbBtn
                floating="true"
                size="sm"
                class="waves-light btn-tlight"
                mdbWavesEffect
              >
                <mdb-icon
                  *ngIf="store.user_like"
                  fas
                  icon="heart"
                  class="text-danger"
                ></mdb-icon>
                <mdb-icon *ngIf="!store.user_like" fas icon="heart"></mdb-icon>
              </a>
            </div>
          </div>
          <mdb-card-body>
            <a [routerLink]="['/store/' + store.store_name]">
              <div class="row mb-0 text-default tight-txt">
                <mdb-icon
                  class="mr-1 my-1 pink-text"
                  fas
                  icon="map-pin"
                ></mdb-icon>
                <strong> {{ store.city }}・{{ store.district }}</strong
                >・
                <strong>海拔 {{ store.altitude }}</strong>
              </div>
            </a>
            <a
              class="black-text tight-txt"
              [routerLink]="['/store/' + store.store_name]"
            >
              <p class="h6-responsive strong lh-0 tight-txt mt-4">
                <strong>{{ store.store_alias }}</strong>
              </p>
            </a>
          </mdb-card-body>
        </div>
      </div>
      <div
        class="row smallhero bottom_line"
        *ngFor="let store of stores | storeLiker: x"
      >
        <div class="row4">
          <!-- Featured image -->
          <div
            class="view overlay rounded z-depth-1-half waves-light"
            mdbWavesEffect
            id="{{ store.store_name }}"
          >
            <img
              class="img-fluid"
              src="{{ store.image }}"
              alt="{{ store.store_name }}"
            />
            <a [routerLink]="['/store/' + store.store_name]">
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="row6">
          <!-- Post title -->
          <div class="row">
            <a [routerLink]="['/store/' + store.store_name]">
              <h5 class="dark-grey-text">
                <strong>{{ store.store_alias }}</strong>
              </h5>
            </a>
          </div>
          <a [routerLink]="['/store/' + store.store_name]">
            <div class="f13 i-green">
              <mdb-icon
                class="mr-1 my-1 pink-text"
                fas
                icon="map-pin"
              ></mdb-icon>
              <strong> {{ store.city }}・{{ store.district }}</strong
              >・
              <strong>海拔 {{ store.altitude }}</strong>
            </div>
          </a>
          <a
            class="waves-light grey-text f-right"
            mdbWavesEffect
            (click)="subscribeStore(store)"
          >
            <!-- <mdb-icon fas icon="heart" class="text-danger"></mdb-icon> -->
            <!-- <mdb-icon far icon="heart"></mdb-icon> -->
            <mdb-icon
              *ngIf="store.user_like"
              fas
              icon="heart"
              class="text-danger"
            ></mdb-icon>
            <mdb-icon *ngIf="!store.user_like" fas icon="heart"></mdb-icon>
          </a>

          <!-- Excerpt -->

          <!-- Post data -->
        </div>
        <!-- Grid column -->
      </div>
    </div>
  </div>
</div>
