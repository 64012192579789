<div *ngIf="stores$ | async as stores">
  <div [ngSwitch]="stores">
    <!-- <ng-container *ngSwitchCase="!stores">
          <div class="row text-sm-left mt-3">
            <div class="col-lg-3 col-sm-6 col-12 p-sm-2 p-0">
              <app-loading-spinner></app-loading-spinner>
            </div>
          </div>
        </ng-container> -->

    <ng-container *ngSwitchCase="stores">
      <div class="section magazine-section mt2r">
        <div class="hm">
          <!-- <br> -->
          <h3 class="text-center h3-responsive"><strong>熱門搜尋</strong></h3>
          <div class="row text-sm-left">
            <div *ngIf="!stores">
              <div class="col">
                <app-loading-spinner></app-loading-spinner>
              </div>
            </div>
            <div *ngIf="stores.length === 0 && x < 30">
              <app-loading-spinner></app-loading-spinner>
            </div>
            <div *ngIf="stores.length === 0 && x >= 30">
              <div class="col">
                <p class="font-weight-bold dark-grey-text">
                  最近沒有熱門搜尋喔！
                </p>
              </div>
            </div>
            <div
              class="col-lg-3 col-sm-6 col-12 mb-3"
              *ngFor="let store of stores | tagFilter: tags:x"
            >
              <div class="card">
                <div
                  class="view overlay rounded z-depth-1 waves-light"
                  mdbWavesEffect
                  id="{{ store.name }}"
                  (click)="
                    goStoreSearchResultForExperience(
                      store.name,
                      store.tags,
                      store.cities
                    )
                  "
                >
                  <img class="img-fluid" src="{{ store.image }}" alt="" />
                </div>
              </div>
            </div>

            <!-- <div class="row smallhero bottom_line" *ngFor="let store of stores | tagFilter: tags:x">
                            <div class="row4">
                                <div
                                    class="view overlay rounded z-depth-1-half waves-light"
                                    mdbWavesEffect
                                    id="{{ store.name }}"
                                >
                                    <img
                                    class="img-fluid"
                                    src="{{ store.image }}"
                                    alt=""
                                    />
                                    <a (click)="goStoreSearchResultForExperience(
                                        store.name, store.tags, store.cities)">
                                    <div class="mask rgba-white-slight"></div>
                                    </a>
                                </div>
                            </div>
                            <div class="row6">
                                <div class="row">
                                    <a (click)="goStoreSearchResultForExperience(
                                        store.name, store.tags, store.cities)">
                                        <h5 class="dark-grey-text">
                                            <strong>{{ store.name }}</strong>
                                        </h5>
                                    </a>
                                </div>
                            </div>
                        </div> -->
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
