<div class="text-center pb-3 mt2r">
  <div class="row py-2 d-flex flex-wrap">
    <div class="col-md-3"></div>
    <!-- Grid column -->
    <div class="col-md-3 col-12">
      <h6 class="title mb-4 font-bold">相關條款</h6>
      <p>
        <a href="/privacy" target="_blank" class="blue-grey-text">
          服務條款 & 隱私條款
        </a>
      </p>
    </div>

    <div class="col-md-3 col-12">
      <!-- Copyright -->
      <h6 class="title mb-4 font-bold">聯絡我們</h6>
      <p><i class="fa fa-clock-o mr-3"></i> 平日：09:00-12:00 / 13:00-17:00</p>
      <p><i class="fa fa-envelope mr-3"></i> info@icamping.tw</p>
      <p>
        <mdb-icon fab icon="line" class="mr-3"></mdb-icon>
        <a href="https://lin.ee/XzC6RJJ" target="_blank" class="blue-grey-text"
          >愛露營客服
        </a>
      </p>
      <p>
        <i class="fa fa-facebook mr-3"></i>
        <a href="https://fb.me/icamping" target="_blank" class="blue-grey-text">
          https://fb.me/icamping
        </a>
      </p>
      <p class="git-version">
        {{ version.commit }} -
        {{ version.date }}
      </p>
    </div>
    <div class="col-md-3"></div>
    <!-- Grid column -->
  </div>
</div>
