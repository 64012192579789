<app-loading-spinner *ngIf="showSpinner"></app-loading-spinner>
<div *ngIf="!showSpinner" class="mt2r hm">
  <div class="mb-4">
    <div class="row" *ngIf="stores.length === 0">
      <div class="col text-center">
        <p class="font-weight-bold dark-grey-text">目前尚未設定最愛的營地</p>
      </div>
    </div>
    <h3 class="text-center h3-responsive">
      <strong>我的最愛</strong>
    </h3>
  </div>
  <div class="row" *ngIf="stores">
    <div
      class="col-lg-3 col-sm-6 col-12 mb-4 p-sm-2 p-0 bighero"
      *ngFor="let store of stores"
    >
      <div class="card">
        <div class="view overlay hm-white-slight waves-light" mdbWavesEffect>
          <img class="img-fluid" src="{{ store.image }}" alt="" />

          <a href="/store/{{ store.store_name }}">
            <div class="mask"></div>
          </a>
          <div class="ab-action-btn" (click)="subscribeStore(store)">
            <a
              mdbBtn
              floating="true"
              size="sm"
              class="waves-light btn-tlight"
              mdbWavesEffect
            >
              <mdb-icon
                *ngIf="store.user_like"
                fas
                icon="heart"
                class="text-danger"
              ></mdb-icon>
              <mdb-icon *ngIf="!store.user_like" fas icon="heart"></mdb-icon>
            </a>
          </div>
        </div>
        <div class="card-body text-left px-2">
          <div class="row">
            <div class="col-9 px-0">
              <a href="/store/{{ store.store_name }}" class="cyan-text">
                <h6>
                  <i class="fa fa-fire"></i>
                  <strong> {{ store.city }}</strong>
                </h6>
              </a>
              <a class="black-text" href="/store/{{ store.store_name }}">
                <h5 class="h5-responsive">{{ store.store_alias }}</h5>
              </a>
            </div>
            <!-- <div class="col">
                  <a
                    *ngIf="userLoggedin"
                    mdbBtn
                    floating="true"
                    size="lg"
                    class=""
                    mdbWavesEffect
                    (click)="subscribeStore()"
                  >
                    <i
                      class="fas fa-heart gray-text"
                      *ngIf="!storeInfo.user_like"
                    ></i>
                    <i
                      class="fas fa-heart pink-text"
                      *ngIf="storeInfo.user_like"
                    ></i>
                  </a>
                </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row smallhero bottom_line" *ngFor="let store of stores">
      <div class="row4">
        <!-- Featured image -->
        <div
          class="view overlay rounded z-depth-1-half waves-light"
          mdbWavesEffect
          id="{{ store.store_name }}"
        >
          <img
            class="img-fluid"
            src="{{ store.image }}"
            alt="{{ store.store_name }}"
          />
          <a href="/store/{{ store.store_name }}">
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>
      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="row6">
        <!-- Post title -->
        <div class="row">
          <a href="/store/{{ store.store_name }}">
            <h5 class="dark-grey-text">
              <strong>{{ store.store_alias }}</strong>
            </h5>
          </a>
        </div>
        <a href="/store/{{ store.store_name }}">
          <div class="f13 i-green">
            <mdb-icon class="mr-1 my-1 pink-text" fas icon="map-pin"></mdb-icon>
            <strong> {{ store.city }}・{{ store.district }}</strong
            >・
            <strong>海拔 {{ store.altitude }}</strong>
          </div>
        </a>
        <a
          class="waves-light grey-text f-right"
          mdbWavesEffect
          (click)="subscribeStore(store)"
        >
          <!-- <mdb-icon fas icon="heart" class="text-danger"></mdb-icon> -->
          <!-- <mdb-icon far icon="heart"></mdb-icon> -->
          <mdb-icon
            *ngIf="store.user_like"
            fas
            icon="heart"
            class="text-danger"
          ></mdb-icon>
          <mdb-icon *ngIf="!store.user_like" fas icon="heart"></mdb-icon>
        </a>

        <!-- Excerpt -->

        <!-- Post data -->
      </div>
      <!-- Grid column -->
    </div>
  </div>
</div>
