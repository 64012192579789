<div class="text-left pb-3 mt2r">
  <div class="card card-cascade narrower">
    <div class="view view-cascade overlay image-center">
      <img class="card-img-top" src="{{ mesg.image }}" />
      <a>
        <div class="mask rgba-white-slight"></div>
      </a>
    </div>

    <div class="card-body card-body-cascade">
      <div class="h5-responsive pb-2 pt-1 dark-grey-text">
        {{ mesg.store_alias }}
      </div>
      <hr />
      <h6 class="text-muted">{{ mesg.subject }}</h6>
      <p class="dark-grey-text" [innerHtml]="mesg.body"></p>
    </div>
  </div>
</div>
