import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable()
export class TripService {
  constructor(private _http: HttpClient) {}

  caseUserFirebaseUIDList(_status: string) {
    return this._http.get(
      `${environment.cloudEndpointGuestTier1}/case/user_firebase_uid/list`,
      {
        params: {
          status: _status,
          key: environment.cloudEndpointApiKey,
        },
      }
    );
  }

  caseCaseIdList(caseId: string, storeName: string) {
    return this._http.post(
      `${environment.cloudEndpointGuestTier1}/case/case_id/list?key=${environment.cloudEndpointApiKey}`,
      { case_id: caseId, store_name: storeName }
    );
  }

  caseInvoiceCaseIdList(case_id: string) {
    return this._http.post(
      `${environment.cloudEndpointGuestTier2}/invoice/case_id/list?key=${environment.cloudEndpointApiKey}`,
      {
        case_id: case_id,
      }
    );
  }

  queueUserFirebaseUIDList() {
    return this._http.get(
      `${environment.cloudEndpointGuestTier2}/queue/user_firebase_uid/list`,
      {
        params: {
          key: environment.cloudEndpointApiKey,
        },
      }
    );
  }
}
