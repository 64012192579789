<app-loading-spinner *ngIf="showSpinner"></app-loading-spinner>

<div *ngIf="storeInfo">
  <div class="row my-1">
    <div
      class="btn-group btn-group-md w-100 px-0 buttons"
      role="group"
      aria-label="Button group with nested dropdown"
    >
      <button
        mdbBtn
        type="button"
        color="info"
        (click)="introModal.show()"
        class="waves-light ml-0 mr-1 px-3 button"
        mdbWavesEffect
      >
        營區介紹
      </button>
      <button
        mdbBtn
        type="button"
        color="info"
        (click)="facilityModal.show()"
        class="waves-light mx-1 px-3 button"
        mdbWavesEffect
      >
        相關設施
      </button>
      <button
        mdbBtn
        type="button"
        color="info"
        (click)="notesModal.show()"
        class="waves-light ml-1 mr-0 px-3 button"
        mdbWavesEffect
      >
        營區須知
      </button>
    </div>
  </div>
  <div class="row my-1">
    <div
      class="btn-group btn-group-md w-100 px-0 buttons"
      role="group"
      aria-label=""
    >
      <button
        *ngFor="let externalLink of externalBtns; index as i"
        mdbBtn
        type="button"
        (click)="open(externalLink.link)"
        class="grey darken-1 white-text button waves-light px-1"
        [ngClass]="{ bu1: i === 0, bu2: i === 1, bu3: i === 2 }"
        mdbWavesEffect
      >
        {{ externalLink.name }}
      </button>
    </div>
  </div>
  <div class="row my-2">
    <div class="center">
      <img
        class="birdeyeimg img-fluid"
        *ngIf="birdEyeViewImage"
        src="{{ birdEyeViewImage.thumbnail_600c_url }}"
      />
    </div>
  </div>
  <div
    style="overflow-y: auto"
    mdbModal
    #introModal="mdbModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myBasicModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-lg modal-top modal-dialog-scrollable"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="introModal.hide()"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title w-100" id="myModalLabel">營區介紹</h4>
        </div>
        <div class="modal-body">
          <div class="row mx-m15">
            <div class="col-md-6 col-12">
              <img
                class="birdeyeimg img-fluid"
                *ngIf="birdEyeViewImage"
                src="{{ birdEyeViewImage.thumbnail_600c_url }}"
              />
            </div>
            <div class="col-md-6 col-12 grey lighten-3 p-sm-5 p-2 bo-r">
              <div class="mt-2">
                <h4 class="h4-responsive">關於營區</h4>
              </div>
              <p [innerHtml]="storeInfo.description"></p>
              <hr />
              <div class="mt-2" *ngIf="externalLinks">
                <h6 class="h6-responsive">外部連結</h6>
                <p *ngFor="let externalLink of externalLinks">
                  {{ externalLink.sequence }}.
                  <a href="{{ externalLink.link }}">{{ externalLink.name }}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer mb-35">
          <button
            type="button"
            mdbBtn
            color="info"
            class="waves-light btn-sm"
            aria-label="Close"
            (click)="introModal.hide()"
            mdbWavesEffect
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    mdbModal
    #facilityModal="mdbModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myBasicModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="facilityModal.hide()"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title w-100" id="myModalLabel">相關設施</h4>
        </div>
        <div class="modal-body">
          <div class="d-flex align-content-start flex-wrap">
            <div
              class="facilitielabel text-left"
              *ngFor="let facility of storeInfo.facility"
            > 
              <i
                [ngClass]="getIcon(facility)"
                aria-hidden="true"
              >
                {{ facility }}</i
              >
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            mdbBtn
            color="info"
            class="waves-light btn-sm"
            aria-label="Close"
            (click)="facilityModal.hide()"
            mdbWavesEffect
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    mdbModal
    #notesModal="mdbModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myBasicModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="notesModal.hide()"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title w-100" id="myModalLabel">營區須知</h4>
        </div>
        <div class="modal-body">
          <div class="d-flex align-content-start flex-wrap">
            <div
              class="line-breaker text-md-left text-left p-2"
              [innerHtml]="storeInfo.guideline"
            ></div>
            <a
              class="w-100 btn btn-info btn-sm"
              (click)="storeGuideline.show()"
            >
              暸解更多
            </a>
          </div>
        </div>
        <div class="modal-footer mb-35">
          <button
            type="button"
            mdbBtn
            color="info"
            class="waves-light btn-sm"
            aria-label="Close"
            (click)="notesModal.hide()"
            mdbWavesEffect
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--Modal: storeInfo.guideline Modal-->
  <div
    mdbModal
    #storeGuideline="mdb-modal"
    class="modal fade guideline-modal"
    id="modalSubscription"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-lg modal-top modal-dialog-scrollable"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header b-n">
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="storeGuideline.hide()"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <!--Body-->
        <div class="modal-body mb-0">
          <div class="row">
            <div class="col-md-12">
              <div
                class="line-breaker text-md-left p-2"
                [innerHtml]="storeInfo.guidelineMore"
              ></div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-info waves-light"
            data-dismiss="modal"
            (click)="storeGuideline.hide()"
            mdbWavesEffect
          >
            我暸解了
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--Modal: storeInfo.guideline Modal -->
</div>
