<div class="text-center mt2r">
  <h4>營地搜尋</h4>
</div>
<div>
  <div class="md-form mt-0 mb-0 dark-grey-text">
    請選擇區域
    <div class="row">
      <div class="d-flex flex-wrap">
        <div
          class="pl-3 pr-2 mt-0 mb-0 mt-1"
          *ngFor="let areaKey of objectKeys(areas)"
        >
          <mdb-checkbox
            [(ngModel)]="areas[areaKey]"
            [inline]="true"
            [rounded]="true"
            class="checkbox-teal"
            >{{ areaRemove(areaKey) }}
          </mdb-checkbox>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="md-form">
    請選擇營位類型
    <div class="row">
      <div class="d-flex flex-wrap">
        <div class="form-group pl-3 pr-2 mt-0 mb-0 mt-1" *ngFor="let fn of objectKeys(facilities); let i = index">
          <mdb-checkbox [(ngModel)]="facilities[fn]" [inline]="true" [rounded]="true" class="checkbox-teal"
            [tabIndex]="i" (change)="onFacilityChange($event)">
            {{ fn === '全選'? '不拘' : fn }}
          </mdb-checkbox>
        </div>
      </div>
    </div>
  </div> -->
  <div class="md-form">
    請選擇條件
    <div class="row">
      <div class="d-flex flex-wrap">
        <div
          class="form-group pl-3 pr-2 mt-0 mb-0 mt-2"
          *ngFor="let c of objectKeys(conditions); let i = index"
        >
          <mdb-checkbox
            [(ngModel)]="conditions[c]"
            [rounded]="true"
            class="checkbox-teal"
            [tabIndex]="i"
            (change)="onConditionChange($event)"
            >{{ c === "全選" ? "不拘" : c }}</mdb-checkbox
          >
        </div>
      </div>
    </div>
  </div>

  <div class="text-center my-60">
    <button
      mdbRippleRadius
      class="btn btn-default waves-light"
      (click)="goSearch()"
    >
      點我搜尋
    </button>
  </div>
</div>
