<div *ngIf="!storeImages; else storeImagesTemp"></div>

<ng-template #storeImagesTemp>
  <!--Carousel Wrapper-->
  <mdb-carousel class="carousel slide carousel-fade bs" [type]="'carousel-thumbnails'" [animation]="'fade'">
    <!-- <mdb-carousel [isControls]="true" class="carousel slide carousel-fade" [type]="'carousel-thumbnails'" [animation]="'fade'"> -->
    <!--First slide-->
    <mdb-carousel-item *ngFor="let image of storeImages">
      <div class="s-img">
        <img class="img-fluid " src="{{ image.thumbnail_1200c_url }}" alt="First slide" />
      </div>
    </mdb-carousel-item>
    <!--/First slide-->
  </mdb-carousel>

  <mdb-carousel class="carousel slide carousel-fade ss" [animation]="'fade'">
    <!-- <mdb-carousel [isControls]="true" class="carousel slide carousel-fade" [type]="'carousel-thumbnails'" [animation]="'fade'"> -->
    <!--First slide-->
    <mdb-carousel-item *ngFor="let image of storeImages">
      <div class="view w-100">
        <img class="img-fluid " src="{{ image.thumbnail_1200c_url }}" alt="First slide" />
      </div>
    </mdb-carousel-item>
    <!--/First slide-->
  </mdb-carousel>
</ng-template>
