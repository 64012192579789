<app-loading-spinner *ngIf="showSpinner"> </app-loading-spinner>

<div class="text-center pb-3 mt2r" *ngIf="showTmpl && !showSpinner">
  <div class="card testimonial-card">
    <div class="d-flex justify-content-center">
      <div class="col-12">
        <div class="md-form">
          <input autofocus type="number" class="form-control" [(ngModel)]="pincode" placeholder="輸入認證碼"
            style="width: 100% !important" />
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <div class="row w-100">
        <div class="col-sm-6 col-12">
          <div class="text-center mt-1 mr-2">
            <button mdbBtn type="button" color="purple" [disabled]="isDisabled" size="lg"
              (click)="userPinCodeVerify(pincode)" mdbWavesEffect style="width: 100%">
              驗證
            </button>
          </div>
        </div>

        <div class="col-sm-6 col-12">
          <div class="text-center mt-1 mr-2">
            <button mdbBtn type="button" color="info" [disabled]="isDisabled" size="lg" (click)="backToProfile()"
              mdbWavesEffect style="width: 100%">
              <i class="fa fa-mobile-phone ml-2"></i> 重新輸入手機
            </button>
          </div>
        </div>

        <div class="col-sm-6 col-12">
          <div class="text-center mt-1 mr-2">
            <button mdbBtn type="button" color="warning" [disabled]="isDisabled" size="lg" (click)="emailModal.show()"
              mdbWavesEffect style="width: 100%">
              改用Email驗證
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>


  <div mdbModal #emailModal="mdb-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="emailModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h4 class="modal-title w-100 font-weight-bold">
            請輸入您常用的email
          </h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="emailModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body mx-auto">
          <input [mdbValidation]="false" [(ngModel)]="email" mdbInput type="email" id="email"
            class="form-control ml-1 pl-3" mdbActive />
        </div>

        <div class="modal-footer">
          <button type="button" mdbBtn size="sm" color="success" class="waves-light mr-auto" aria-label="Close"
            (click)="emailModal.hide()" mdbWavesEffect>
            取消使用 Email驗證
          </button>
          <button type="button" [disabled]="isDisabled" mdbBtn size="sm" color="danger" id="confirmed-cancel-case"
            class="relative waves-light" (click)="userPinCodeCreateByEmail()" mdbWavesEffect>
            傳送驗證碼至我的Email
          </button>
        </div>
      </div>
    </div>
  </div>

</div>
