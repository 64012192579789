<section class="magazine-section mt-3 mm">
  <h4>已取消訂單</h4>
  <p class="dark-grey-text w-responsive mx-auto mb-5">
    {{ cases.length !== 0 ? "包含：營區,體驗,商品" : "目前沒有訂單喔！" }}
  </p>
  <app-loading-spinner *ngIf="showSpinner"></app-loading-spinner>
  <div class="row">
    <div
      class="col-lg-4 col-md-6 col-sd-12 mb-4 bighero"
      *ngFor="let case of cases"
    >
      <div class="card">
        <div class="view overlay rounded z-depth-1 waves-light" mdbWavesEffect>
          <img class="card-img-top" src="{{ case.image }}" alt="" />
          <a
            href="/me/trip/info/{{ case.store_name }}/{{ case.case_id }}"
            data-toggle="modal"
            data-target="#basicExample"
          >
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>
        <mdb-card-body class="mt-1">
          <a
            href="/me/trip/info/{{ case.store_name }}/{{ case.case_id }}"
            mdbWavesEffect
          >
            <h6 class="h6-responsive black-text">
              <strong>
                <mdb-icon
                  class="mr-1 my-1 pink-text"
                  fas
                  icon="map-pin"
                ></mdb-icon
                >{{ case.store_alias }}
              </strong>
            </h6>
            <div class="col">
              <p class="grey-text">
                <mdb-icon fas icon="campground" class="mr-1 my-1"></mdb-icon
                >{{ case.checkin_date | utcDateFormat }}
              </p>
            </div>
          </a>
        </mdb-card-body>
      </div>
    </div>
    <div class="row smallhero bottom_line" *ngFor="let case of cases">
      <div class="row4">
        <!-- Featured image -->
        <div
          class="view overlay rounded z-depth-1-half waves-light"
          mdbWavesEffect
          id="{{ case.store_name }}"
        >
          <img
            class="img-fluid"
            src="{{ case.image }}"
            alt="{{ case.store_name }}"
          />
          <a
            href="/me/trip/info/{{ case.store_name }}/{{ case.case_id }}"
            data-toggle="modal"
            data-target="#basicExample"
          >
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>
      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="row6">
        <a href="/me/trip/info/{{ case.store_name }}/{{ case.case_id }}">
          <!-- Post title -->
          <div class="row">
            <h5 class="dark-grey-text">
              <strong>{{ case.store_alias }}</strong>
            </h5>
          </div>
          <div class="row">
            <div class="f13 i-green">
              <mdb-icon fas icon="campground"></mdb-icon>
              {{ case.checkin_date | utcDateFormat }}
            </div>
          </div>
        </a>

        <!-- Excerpt -->

        <!-- Post data -->
      </div>
      <!-- Grid column -->
    </div>
  </div>
</section>
