<app-loading-spinner *ngIf="showSpinner"></app-loading-spinner>

<div class="row" *ngIf="case">
  <div class="col-md-12 mb-3">
    <div *ngIf="canDateUpdate">
      <h6>抵達日期(可修改)</h6>
      <div class="row">
        <div class="col hardfix">
          <mdb-date-picker
            mdbInput
            inlineIcon="far fa-calendar-check prefix grey-text"
            [inline]="true"
            [mdbValidation]="false"
            [options]="verifyCheckInDate"
            [placeholder]="'修改抵達日'"
            [(ngModel)]="case.checkin_date"
            (dateChanged)="onDateChange($event)"
          ></mdb-date-picker>
        </div>
      </div>
      <br />
      <h6>拔營日期(不可修改)</h6>
      <p class="grey-text">{{ case.checkout_date }}</p>
    </div>

    <div class="text-center">
      <button
        type="button"
        class="btn btn-default btn-rounded waves-light"
        (click)="search()"
        [disabled]="!canSearch"
        mdbRippleRadius
      >
        查詢是否可修改
      </button>
    </div>
  </div>
</div>

<div class="row" *ngIf="!canDateUpdate">
  <div class="col-sm-12">
    <div class="card p-2">
      <div claaa="column">
        <h6>入營日</h6>
        <p class="grey-text">{{ case.checkin_date | utcDateFormat }}</p>
        <h6>拔營日</h6>
        <p class="grey-text">{{ case.checkout_date | utcDateFormat }}</p>
      </div>
    </div>
  </div>
</div>

<div class="row mt-4" *ngIf="!canDateUpdate || searched">
  <div class="col"></div>
  <div class="col-sm-12">
    <div class="row">
      <div
        class="col-md-3 col-sm-4 col-12 mb-4 mr-3 p-0"
        *ngFor="let stuff of filterEmptyStuff()"
      >
        <div
          class="view overlay rounded z-depth-1 waves-light h-max"
          mdbWavesEffect
        >
          <img class="stuff-img" src="{{ stuff[7] }}" alt=" " />
          <div class="p-2">
            項目：{{ stuff[8] }} ({{ stuff[2] }} {{ stuff[1] }})
          </div>
          <!-- <div>項目：{{ stuff[0] }} {{ stuff[2] }} {{ stuff[1] }}</div> -->
          <div class="px-2">
            <div>請輸入修改後的數量：</div>
            <div class="mt-1 md-form" *ngIf="stuff[6] >= stuff[5]">
              <input
                mdbActive
                type="number"
                [max]="stuff[5]"
                min="0"
                class="form-control pt-1"
                [value]="stuff[2]"
                [(ngModel)]="stuff[2]"
                (change)="chceckQuantitiesValid()"
              />
            </div>
            <div class="mt-1 md-form" *ngIf="stuff[6] < stuff[5]">
              只剩下 {{ stuff[6] }} 個單位，請減量或重新選擇日期
              <input
                mdbActive
                type="number"
                [max]="stuff[6]"
                min="0"
                class="form-control pt-1"
                [value]="stuff[6]"
                [(ngModel)]="stuff[2]"
                (change)="chceckQuantitiesValid()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col"></div>
</div>

<div class="row mt-5" *ngIf="case">
  <div class="col"></div>
  <div class="col-sm-12">
    <div *ngIf="!canDateUpdate || (searched && quantitiesValid)">
      <button
        class="w-100"
        mdbBtn
        type="button"
        color="default"
        block="true"
        mdbWavesEffect
        (click)="caseUpdateModalStep1.show()"
        *ngIf="(case.checkin_date | dayDuration) < 15"
      >
        確認更新
      </button>
      <button
        class="w-100"
        mdbBtn
        type="button"
        color="default"
        block="true"
        mdbWavesEffect
        (click)="caseUpdateModalStep2.show()"
        *ngIf="(case.checkin_date | dayDuration) >= 15"
      >
        確認更新
      </button>
    </div>
  </div>
  <div class="col"></div>
</div>

<!-- Step1 -->
<div
  mdbModal
  #caseUpdateModalStep1="mdb-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="caseUpdateModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="caseUpdateModalStep1.hide()"
        >
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 font-weight-bold">溫馨提醒</h4>
      </div>

      <div class="modal-body mx-auto">
        <div class="row">
          <p class="note note-danger">
            <small>
              本筆訂單在距離露營日 第{{
                case.checkin_date | dayDuration
              }}天修改， 距離露營日14(含)天內，已無法退款100%，確定修改嗎？
            </small>
          </p>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          mdbBtn
          size="sm"
          color="success"
          class="waves-light mr-auto"
          aria-label="Close"
          (click)="caseUpdateModalStep1.hide()"
          mdbWavesEffect
        >
          離開
        </button>
        <button
          type="button"
          mdbBtn
          size="sm"
          color="danger"
          id="case-create"
          class="relative waves-light"
          mdbWavesEffect
          [disabled]="!invoiceValidators()"
          (click)="caseUpdateModalStep2.show(); caseUpdateModalStep1.hide()"
        >
          下一步
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Step2 -->
<div
  mdbModal
  #caseUpdateModalStep2="mdb-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="caseUpdateModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="caseUpdateModalStep2.hide()"
        >
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 font-weight-bold">確定修改訂單?</h4>
      </div>
      <div class="modal-body mx-auto">
        <div class="row text-left" style="font-size: 12px">
          <a target="_blank" href="/icamping-rule"
            >點我查看『愛露營訂位使用規則』</a
          >
        </div>
        <br />

        <div class="agreement-overflow">
          <div class="row text-left">
            <p class="note note-danger">
              <small style="font-size: 11px">
                附註ㄧ：請注意可能因日期不同而產生價差!<br />
                附註二：請確認您的帳戶資訊，以便後續退款流程順利。<br />
                附註三：如未繳款請在以下兩個欄位內皆填入0即可。<br />
                附註四：修改訂單後，無法救回、延期、或做任何改變。
              </small>
            </p>
          </div>
          <div class="md-form mbn-20">
            <input
              type="text"
              id="materialFormCancelBankDesc"
              name="cancelBankDesc"
              class="form-control validate"
              [(ngModel)]="user_bank_description"
              mdbInput
              [mdbValidation]="false"
              placeholder="銀行代碼："
            />
            <div class="row bb-1-p0">
              <div class="row text-left">
                <p class="note note-danger">
                  <small style="font-size: 11px">
                    請輸入正確<strong>銀行代碼</strong
                    ><br />避免無法確認訂單(空位釋出)，或延遲退款程序。
                  </small>
                </p>
              </div>
            </div>
          </div>

          <div class="md-form mb-4">
            <input
              type="text"
              id="materialFormCancelBank"
              name="cancelBank"
              class="form-control validate"
              [(ngModel)]="user_bank"
              mdbInput
              [mdbValidation]="false"
              placeholder="銀行帳號："
            />

            <div class="row bb-1-p0">
              <div class="row text-left">
                <p class="note note-danger">
                  <small style="font-size: 11px">
                    請輸入正確<strong>銀行帳號</strong
                    ><br />避免無法確認訂單(空位釋出)，或延遲退款程序。
                  </small>
                </p>
              </div>
            </div>
          </div>
          <div class="md-form mb-4">
            <input
              type="text"
              id="materialFormCancelInvoiceId"
              name="cancel_invoice_id"
              class="form-control validate"
              [(ngModel)]="invoice_id"
              mdbInput
              [mdbValidation]="false"
              placeholder="統一編號 不需請空白"
            />
            <mdb-error *ngIf="!invoiceValidators()"
              >請留白或是請留白或是輸入8個數字</mdb-error
            >
            <!-- <div class="alert alert-danger bank-desc" role="alert">
              愛露營服務費用皆有開電子發票，營地費用發票請洽詢營主。
            </div> -->

            <div class="row bb-1-p0">
              <div class="row text-left">
                <p class="note note-danger">
                  <small style="font-size: 11px">
                    愛露營服務費用皆有開電子發票，營地費用發票請洽詢營主。
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          mdbBtn
          size="sm"
          color="success"
          class="waves-light mr-auto"
          aria-label="Close"
          (click)="caseUpdateModalStep2.hide()"
          mdbWavesEffect
        >
          不要修改
        </button>
        <button
          type="button"
          mdbBtn
          size="sm"
          color="danger"
          id="case-create"
          class="relative waves-light"
          mdbWavesEffect
          [disabled]="!invoiceValidators()"
          (click)="caseUpdate(case); caseUpdateModalStep2.hide()"
        >
          確定修改
        </button>
      </div>
    </div>
  </div>
</div>
