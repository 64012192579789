import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../../../core/auth.service';
import { StoreService } from '../../../../service/store.service';
import { TripService } from '../../../../service/trip.service';
@Component({
  selector: 'app-me-trip-info-paid',
  templateUrl: './paid.component.html',
  styleUrls: ['./paid.component.scss'],
})
export class MeTripInfoPaidComponent implements OnInit {
  cases: Array<any> = [];
  showSpinner = true;

  constructor(
    public auth: AuthService,
    private _storeService: StoreService,
    private _tripService: TripService,
  ) {}

  logout() {
    window.localStorage.clear();
    window.sessionStorage.clear();
    this.auth.signOut();
  }

  ngOnInit() {
    this._tripService
      .caseUserFirebaseUIDList('paid')
      .subscribe((_cases: any) => {
        // 如果抓不到任何paid訂單,還是要把showSpinner關起來
        if (_cases.items === undefined) {
          this.showSpinner = false;
        } else {
          // 如果有paid訂單，則顯示訂單列表
          _cases.items.forEach((caseObj: any) => {
            this._storeService
              .storeNameGetImageLimit(caseObj.store_name)
              .subscribe((image: any) => {
                caseObj['image'] = image[0].thumbnail_600c_url;
              });
          });
          this.cases = _cases.items;
          this.showSpinner = false;
        }
      });
  }
}
