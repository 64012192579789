import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({ name: "utcDateFormat" })
export class UTCDateFormatPipe implements PipeTransform {
  transform(dateTime: Date): String {
    return moment(dateTime)
      .add(8, "hour")
      .utcOffset("Asia/Taipei")
      .format("YYYY-MM-DD");
  }
}

@Pipe({ name: "utcDateTimeFormat" })
export class UTCDateTimeFormatPipe implements PipeTransform {
  transform(dateTime: Date): String {
    return moment(dateTime)
      .add(8, "hour")
      .utcOffset("Asia/Taipei")
      .format("YYYY-MM-DD HH:mm:ss");
  }
}

@Pipe({ name: "dayDuration" })
export class DayDurationPipe implements PipeTransform {
  transform(dateTime: Date): number {
    // Convert Date object to moment object with Asia/Taipei timezone
    const inputMoment = moment(dateTime).tz("Asia/Taipei");

    // Add one day to the input moment
    inputMoment.add(1, "day");

    // Get current moment in Asia/Taipei timezone
    const now = moment.tz("Asia/Taipei");

    // Calculate difference in days
    const diffInDays = inputMoment.diff(now, "days");

    return diffInDays;
  }
}

@Pipe({ name: "weekdayFormat" })
export class WeekdayFormatPipe implements PipeTransform {
  transform(ws: Array<string>): String {
    const sc = "公休日: 星期";
    let s = "公休日: 星期";

    if (ws[0] === "0") {
      s += "一, ";
    }
    if (ws[1] === "0") {
      s += "二, ";
    }
    if (ws[2] === "0") {
      s += "三, ";
    }
    if (ws[3] === "0") {
      s += "四, ";
    }
    if (ws[4] === "0") {
      s += "五, ";
    }
    if (ws[5] === "0") {
      s += "六, ";
    }
    if (ws[6] === "0") {
      s += "日, ";
    }

    if (sc === s) {
      return "沒有公休日";
    }

    return s.substring(0, s.length - 2);
  }
}

@Pipe({ name: "calWeekdayFormat" })
export class CalWeekdayFormatPipe implements PipeTransform {
  transform(d: String): String {
    if (d === "星期一") {
      return "一";
    }
    if (d === "星期二") {
      return "二";
    }
    if (d === "星期三") {
      return "三";
    }
    if (d === "星期四") {
      return "四";
    }
    if (d === "星期五") {
      return "五";
    }
    if (d === "星期六") {
      return "六";
    }
    if (d === "星期日") {
      return "日";
    }
    return d;
  }
}
