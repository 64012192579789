import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable()
export class MesgService {
  constructor(private _http: HttpClient) {}

  mesgUserIdList() {
    return this._http.get(
      `${environment.cloudEndpointGuestTier2}/mesg/user_id/list`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "googleCloudEndpointToken"
          )}`,
          "Content-Type": "application/json",
        },
        params: { key: environment.cloudEndpointApiKey },
      }
    );
  }

  mesgIdDelete(mesgId: string) {
    return this._http.post(
      `${environment.cloudEndpointGuestTier2}/mesg/id/delete?key=${environment.cloudEndpointApiKey}`,
      { id: mesgId }
    );
  }

  mesgIdGet(mesgId: string) {
    return this._http.get(
      `${environment.cloudEndpointGuestTier2}/mesg/id/get`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "googleCloudEndpointToken"
          )}`,
          "Content-Type": "application/json",
        },
        params: {
          key: environment.cloudEndpointApiKey,
          id: mesgId,
        },
      }
    );
  }
}
