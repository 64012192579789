<!--Section: Pricing v.5-->
<div class="text-left pb-3 mt2r">
  <app-loading-spinner *ngIf="showSpinner"></app-loading-spinner>
  <div *ngIf="!showSpinner && stores.length === 0">
    <div class="row">
      <!-- Grid column -->
      <div class="col">
        <!-- Excerpt -->
        <p class="font-weight-bold dark-grey-text">
          系統搜尋中請稍候！
          <a class="btn btn-info btn-rounded btn-md" routerLink="/camp/search"
            >重新選擇搜尋條件</a
          >
        </p>
      </div>
      <!-- Grid column -->
    </div>
  </div>

  <div *ngIf="stores.length !== 0">
    <h3 class="text-center h3-responsive py-2">
      <strong>營地搜尋結果 </strong>
      <a class="btn btn-info btn-rounded btn-md" routerLink="/camp/search"
        >重新選擇搜尋條件</a
      >
    </h3>
    <div class="row">
      <div
        class="col-lg-3 col-md-4 col-sm-6 col-12 my-3 bighero"
        *ngFor="let store of stores | storeLiker: x; let s = index"
      >
        <div class="card">
          <div class="view overlay hm-white-slight waves-light" mdbWavesEffect>
            <img class="img-fluid" src="{{ store.image }}" alt="" />
            <a (click)="stuffSearch(s)">
              <div class="mask"></div>
              <div
                class="f13 noteline danger-bg-text"
                *ngIf="store.stuff_full == 2"
              >
                全部客滿
              </div>
              <div
                class="f13 noteline dark-grey-bg-text"
                *ngIf="store.stuff_full == 1"
              >
                部分客滿
              </div>
            </a>
          </div>
          <div class="card-body py-45">
            <div (click)="stuffSearch(s)" class="cyan-text mt-m20">
              <a>
                <div class="row mt-m15 mb-0 text-default">
                  <mdb-icon
                    class="mr-1 mb-1 pink-text"
                    fas
                    icon="map-pin"
                  ></mdb-icon>
                  <strong> {{ store.city }}・{{ store.district }}</strong
                  >・
                  <strong> 海拔 {{ store.altitude }}</strong>
                </div>
              </a>
              <a class="black-text">
                <p class="h6-responsive strong lh-0">
                  <strong>{{ store.store_alias }}</strong>
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row smallhero bottom_line"
        *ngFor="let store of stores | storeLiker: x; let s = index"
      >
        <div class="row4">
          <!-- Featured image -->
          <div
            class="view overlay rounded z-depth-1-half waves-light"
            mdbWavesEffect
            id="{{ store.store_name }}"
          >
            <img
              class="img-fluid"
              src="{{ store.image }}"
              alt="{{ store.store_name }}"
            />

            <a (click)="stuffSearch(s)">
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="row6">
          <!-- Post title -->
          <div class="row">
            <a (click)="stuffSearch(s)">
              <h5 class="dark-grey-text">
                <strong>{{ store.store_alias }}</strong>
              </h5>
            </a>
          </div>
          <a (click)="stuffSearch(s)">
            <div class="f13 i-green">
              <mdb-icon
                class="mr-1 my-1 pink-text"
                fas
                icon="map-pin"
              ></mdb-icon>
              <strong> {{ store.city }}・{{ store.district }}</strong
              >・
              <strong>海拔 {{ store.altitude }}</strong>
            </div>
            <div class="row">
              <div class="f13 danger-bg-text" *ngIf="store.stuff_full == 2">
                全部客滿
              </div>
              <div class="f13 dark-grey-bg-text" *ngIf="store.stuff_full == 1">
                部分客滿
              </div>
            </div>
          </a>

          <!-- Excerpt -->

          <!-- Post data -->
        </div>
        <!-- Grid column -->
      </div>
    </div>
  </div>
</div>
