import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable()
export class ExperienceService {
  constructor(private _http: HttpClient) {}

  experienceNameGet(experienceName: string) {
    return this._http.get(
      `${environment.cloudEndpointGuestTier2}/experience/name/get`,
      {
        params: { name: experienceName, key: environment.cloudEndpointApiKey },
      }
    );
  }

  experienceNameList() {
    return this._http.get(
      `${environment.cloudEndpointGuestTier2}/experience/name/list`,
      {
        params: { key: environment.cloudEndpointApiKey },
      }
    );
  }
}
