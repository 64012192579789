import { Component, OnInit } from "@angular/core";
import { HomeService } from "../service/home.service";
import { IMyOptions } from "ng-uikit-pro-standard";
import { Router } from "@angular/router";
import { StoreService } from "../service/store.service";
import * as moment from "moment";
import * as _ from "lodash";

@Component({
  selector: "app-camp-search",
  templateUrl: "./camp-search.component.html",
  styleUrls: ["./camp-search.component.scss"],
})
export class CampSearchComponent implements OnInit {
  _dayBefore = moment().utcOffset("Asia/Taipei").subtract(1, "days");
  dayBefore = this._dayBefore.toDate();
  yearAfter = this._dayBefore.add(1, "years").add(1, "days").toDate();

  myDatePickerOptions: IMyOptions = {
    // Strings and translations
    closeAfterSelect: true,
    showTodayBtn: false,
    showClearDateBtn: false,
    dayLabels: {
      su: "日",
      mo: "一",
      tu: "二",
      we: "三",
      th: "四",
      fr: "五",
      sa: "六",
    },
    dayLabelsFull: {
      su: "日",
      mo: "一",
      tu: "二",
      we: "三",
      th: "四",
      fr: "五",
      sa: "六",
    },
    monthLabels: {
      1: "一月",
      2: "二月",
      3: "三月",
      4: "四月",
      5: "五月",
      6: "六月",
      7: "七月",
      8: "八月",
      9: "九月",
      10: "十月",
      11: "十一月",
      12: "十二月",
    },
    monthLabelsFull: {
      1: "一月",
      2: "二月",
      3: "三月",
      4: "四月",
      5: "五月",
      6: "六月",
      7: "七月",
      8: "八月",
      9: "九月",
      10: "十月",
      11: "十一月",
      12: "十二月",
    },

    // // Buttons
    todayBtnTxt: "今日",
    clearBtnTxt: "清除",
    closeBtnTxt: "確認",

    // // First day of the week
    firstDayOfWeek: "mo",
    selectionTxtFontSize: "14`px",
    dateFormat: "yyyy-mm-dd",
    disableUntil: {
      year: this.dayBefore.getFullYear(),
      month: this.dayBefore.getMonth() + 1,
      day: this.dayBefore.getDate(),
    },
    disableSince: {
      year: this.yearAfter.getFullYear(),
      month: this.yearAfter.getMonth() + 1,
      day: this.yearAfter.getDate() + 1,
    },
  };

  area: any;
  checkinDate = "";
  facilities: any;
  facility: string;
  objectKeys = Object.keys;
  stay: number;

  constructor(
    private _homeService: HomeService,
    private _storeService: StoreService,
    private router: Router
  ) {}

  optionsSelect: Array<any>;

  ngOnInit() {
    const filter = this._homeService.getCampFilter();
    this.area = filter.areaFilter;
    this.facilities = filter.facilityFilter;
    this.stay = filter.stayFilter;
    this.checkinDate = filter.checkinDateFilter;
    const facilityFilter = _.keys(_.pickBy(filter.facilityFilter));

    if (facilityFilter.length === 1 && facilityFilter[0] === "全選") {
      this._storeService.facilityList().subscribe((f: any) => {
        if (f.length > 0) {
          f[0].name.forEach((fa) => {
            this.facilities[fa] = false;
          });
          this.facilities["全選"] = false;
          this.facility = "全選";
        }
      });
    } else {
      for (const fn in this.facilities) {
        if (this.facilities[fn]) {
          this.facility = fn;
        }
      }
    }
    this._storeService.hidePageLoading();
    this.optionsSelect = [
      { value: 1, label: "1晚" },
      { value: 2, label: "2晚" },
      { value: 3, label: "3晚" },
      { value: 4, label: "4晚" },
      { value: 5, label: "5晚" },
    ];
  }

  goSearch() {
    for (const fn in this.facilities) {
      if (this.facility === fn) {
        this.facilities[fn] = true;
      } else {
        this.facilities[fn] = false;
      }
    }

    this._homeService.setCampFilter(
      this.area,
      this.checkinDate,
      this.facilities,
      this.stay
    );

    this.router.navigate([
      "/camp/search-result",
      {
        area: this.area,
        checkin_date: this.checkinDate,
        facility: this.facility,
        quantity: 1,
        stay: this.stay,
      },
    ]);
  }
}
