import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { StoreService } from "../../service/store.service";

@Component({
  selector: "app-info",
  templateUrl: "./info.component.html",
  styleUrls: ["./info.component.scss"],
})
export class StoreInfoComponent implements OnInit {
  birdEyeViewImage: any;
  externalLinks: Array<any> = [];
  externalBtns: Array<any> = [];
  showSpinner = true;
  storeInfo: any;

  facilityIconMap = {
    車停帳邊: "fa fa-car fa-1x",
    室內營位: "fas fa-couch fa-1x",
    吹風機: "fas fa-wind fa-1x",
    沙坑: "fas fa-solar-panel fa-1x",
    "3G/4G訊號": "fa fa-signal fa-1x",
    適合單車: "fa fa-bicycle fa-1x",
    可租裝備: "fa fa-wrench fa-1x",
    餐飲: "fa fa-cutlery fa-1x",
    無手機訊號: "fa fa-unlink fa-1x",
    有WIFI: "fa fa-wifi fa-1x",
    遊樂設施: "fa fa-plug fa-1x",
    遊樂器材: "fas fa-trophy fa-1x",
    提供電源: "fa fa-plug fa-1x",
    "3G/4G訊號微弱": "fa fa-info fa-1x",
    烤肉: "fa fa-fire fa-1x",
    導覽解說: "fa fa-users fa-1x",
    提供手電筒: "fa fa-lightbulb-o fa-1x",
    夜間照明: "fab fa-medapps fa-1x",
    脫水機: "fas fa-fill-drip fa-1x",
    泡湯: "fa fa-bathtub fa-1x",
    戲水池: "fa fa-child fa-1x",
    有冰箱: "fa fa-thermometer-1 fa-1x",
    有洗衣機: "fa fa-tint fa-1x",
    雲海: "fa fa-mixcloud fa-1x",
    近溪流: "fa fa-tint fa-1x",
    玩水: "fa fa-child fa-1x",
    近海岸: "fa fa-life-bouy fa-1x",
    雨棚: "fa fa-umbrella fa-1x",
    高海拔: "fa fa-rocket fa-1x",
    釣魚: "fa fa-anchor fa-1x",
    飲水機: "fas fa-water fa-1x",
    寵物同行: "fa fa-paw fa-1x",
    大草皮: "fa fa-leaf fa-1x",
    森林: "fa fa-tree fa-1x",
    櫻花: "fa fa-asterisk fa-1x",
    步道: "fa fa-blind fa-1x",
    果菜採收: "fa fa-pagelines fa-1x",
    螢火蟲: "fa fa-bug fa-1x",
    夜景: "fa fa-star-o fa-1x",
  };

  constructor(
    private route: ActivatedRoute,
    private _storeService: StoreService,
    private sanitizer: DomSanitizer
  ) {}

  // 強制把Froala Editor的 Powered by訊息給隱藏起來！
  // 注意：ngAfterViewInit() 沒有用！
  ngAfterViewChecked() {
    $("mdb-card-body > p > p:nth-child(2)").hide();
  }

  ngOnInit() {
    this.route.params.subscribe((res) => {
      this._storeService
        .storeNameGet(res.store_name)
        .subscribe((result: any) => {
          this.storeInfo = result.items;
          this.storeInfo.description = this.sanitizer.bypassSecurityTrustHtml(
            result.items.description
          );

          // prettier-ignore
          if (result.items.guideline.length > 100) {
            result.items.guidelineMore = result.items.guideline;
            result.items.guideline = result.items.guideline.substring(0, 500) + '...';
            this.storeInfo.guideline = this.sanitizer.bypassSecurityTrustHtml(
              result.items.guideline,
            );
            this.storeInfo.guidelineMore = this.sanitizer.bypassSecurityTrustHtml(
              result.items.guidelineMore,
            );
          } else {
            result.items.guideline = result.items.guideline;
            this.storeInfo.guideline = this.sanitizer.bypassSecurityTrustHtml(result.items.guideline);
          }

          // hide loading spinner
          this.showSpinner = false;
        });

      this._storeService
        .storeNameGetBirdEyeViewImage(res.store_name)
        .subscribe((result: any) => {
          console.log(result);
          this.birdEyeViewImage = result[0];
        });

      this._storeService
        .externalLinkStoreNameList(res.store_name)
        .subscribe((externalLinks: any) => {
          this.externalLinks = externalLinks.items;
          this.externalBtns = [];
          externalLinks.items.forEach((e) => {
            if (
              e.link.indexOf("facebook") !== -1 ||
              e.link.indexOf("cwa") !== -1 ||
              e.link.indexOf("maps") !== -1
            ) {
              this.externalBtns.push(e);
            }
          });
        });
    });
  }

  open(link) {
    window.open(link, "_blank");
  }

  getIcon(facility: string) {
    if (facility in this.facilityIconMap) {
      return this.facilityIconMap[facility];
    }
    return "fa fa-campground fa-1x";
  }
}
