<section class="section extra-margins text-center pb-3 bmt">
  <div class="text-center pb-3 mt2r">
    <div class="text-center">
      <h4>快速訂位</h4>
    </div>

    <div class="md-form mt-0 mb-0">
      <mdb-date-picker
        class="w-100"
        id="checkinDate"
        name="checkinDate"
        inlineIcon="far fa-calendar-check prefix grey-text"
        [(ngModel)]="checkinDate"
        [inline]="true"
        [options]="myDatePickerOptions"
        [placeholder]="'抵達日期'"
      ></mdb-date-picker>
    </div>
    <div class="md-form mt-3">
      <mdb-select
        [options]="optionsSelect"
        placeholder="住幾個晚上"
        [(ngModel)]="stay"
        class="w-100"
      ></mdb-select>

      <!--<input
        [mdbValidation]="false"
        mdbInput
        type="number"
        min="1"
        max="60"
        id="stay"
        class="py-2 w-100"
        mdbActive
        [(ngModel)]="stay"
      />
      <i class="fas fa-bed prefix yoyo grey-text"></i>
      <label for="inputIconEx2">住幾個晚上</label>-->
    </div>

    <div class="md-form mt-0 mb-0 dark-grey-text">
      請選擇營區
      <div class="row">
        <div class="d-flex flex-wrap">
          <div class="form-group pl-3 pr-2 mt-0 mb-0">
            <input
              name="area"
              type="radio"
              id="north"
              [(ngModel)]="area"
              value="北部營區"
              class="form-check-input"
            />
            <label for="north">北部營區</label>
          </div>
          <div class="form-group pl-3 pr-2 mt-0 mb-0">
            <input
              name="area"
              type="radio"
              id="central"
              [(ngModel)]="area"
              value="中部營區"
              class="form-check-input"
            />
            <label for="central">中部營區</label>
          </div>
          <div class="form-group pl-3 pr-2 mt-0 mb-0">
            <input
              name="area"
              type="radio"
              id="south"
              [(ngModel)]="area"
              value="南部營區"
              class="form-check-input"
            />
            <label for="south">南部營區</label>
          </div>
          <div class="form-group pl-3 pr-2 mt-0 mb-0">
            <input
              name="area"
              type="radio"
              id="east"
              [(ngModel)]="area"
              value="東部營區"
              class="form-check-input"
            />
            <label for="east">東部營區</label>
          </div>
        </div>
      </div>
    </div>

    <br />
    <br />

    <div class="md-form mt-0 mb-0 dark-grey-text">
      請選擇營位類型
      <div class="row">
        <div class="d-flex flex-wrap">
          <div
            class="form-group pl-3 pr-2 mt-0 mb-0"
            *ngFor="let fn of objectKeys(facilities)"
          >
            <input
              name="{{ fn }}"
              type="radio"
              id="{{ fn }}"
              [(ngModel)]="facility"
              value="{{ fn }}"
              class="form-check-input"
            />
            <label for="{{ fn }}">{{ fn === "全選" ? "草地" : fn }}</label>
          </div>
        </div>
      </div>
    </div>

    <br />
    <br />

    <div class="text-center">
      <button
        mdbRippleRadius
        class="btn btn-default waves-light"
        (click)="goSearch()"
      >
        送出
      </button>
    </div>
  </div>
</section>
