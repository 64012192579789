import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// prettier-ignore
import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { CampSearchComponent } from "./camp-search/camp-search.component";
import { CampSearchResultComponent } from "./camp-search/camp-search-result/camp-search-result.component";
import { CoreModule } from "./core/core.module";
import { DeviceDetectorService } from "ngx-device-detector";
import { environment } from "../environments/environment";
import { ExperienceComponent } from "./experience/experience.component";
import { FooterComponent } from "./footer/footer.component";
import { FormsModule } from "@angular/forms";
import { HomeComponent } from "./home/home.component";
import { HomePrivacyComponent } from "./home/privacy/privacy.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LikeComponent } from "./like/like.component";
import { LoadingSpinnerComponent } from "./loading-spinner/loading-spinner.component";
import { LoginComponent } from "./login/login.component";
// prettier-ignore
import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';
import { MeComponent } from "./me/me.component";
import { MeOrderComponent } from "./me/order/order.component";
import { MesgComponent } from "./mesg/mesg.component";
import { MesgInfoComponent } from "./mesg/info/info.component";
import { MeTripComponent } from "./me/trip/trip.component";
import { MeTripEditComponent } from "./me/trip/edit/edit.component";
import { MeTripInfoComponent } from "./me/trip/info/info.component";
import { MeTripInfoUnpayComponent } from "./me/trip/info/unpay/unpay.component";
import { MeTripInfoPaidComponent } from "./me/trip/info/paid/paid.component";
import { MeTripInfoCompletedComponent } from "./me/trip/info/completed/completed.component";
import { MeTripInfoCancelledComponent } from "./me/trip/info/cancelled/cancelled.component";
import { MeTripInfoCaseDetailComponent } from "./me/trip/info/case-detail/case-detail.component";
import { MeTripEditCaseDetailComponent } from "./me/trip/edit/case-detail/case-detail.component";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { StoreComponent } from "./store/store.component";
import { StoreImageComponent } from "./store/image/image.component";
import { StoreInfoComponent } from "./store/info/info.component";
import { StoreSearchComponent } from "./store-search/store-search.component";
import { StoreSearchResultComponent } from "./store-search-result/store-search-result.component";
import { StoreSearchMetadataComponent } from "./store-search-metadata/store-search-metadata.component";
import { StoreStuffComponent } from "./store/stuff/stuff.component";
import { StoreStuffSearchResultComponent } from "./store/stuff/search-result/search-result.component";
import { UserProfileComponent } from "./user/profile/profile.component";
import { UserVerifyComponent } from "./user/verify/verify.component";
import { UserCaseMigrationComponent } from "./user/case-migration/case-migration.component";
import { QueueComponent } from "./me/trip/info/queue/queue.component";
import { SettingComponent } from "./setting/setting.component";

// service
import { BookingService } from "./service/booking.service";
import { ExperienceService } from "./service/experience.service";
import { HomeService } from "./service/home.service";
import { MesgService } from "./service/mesg.service";
import { SeoService } from "./service/seo.service";
import { StoreService } from "./service/store.service";
import { TripService } from "./service/trip.service";
import { UserService } from "./service/user.service";
import { PwaService } from "./service/pwa.service";
import { HttpConfigInterceptorService } from "./service/http-config-interceptor.service";

// pipe
import { CaseStatusFormatPipe } from "./pipe/case-status.pipe";
// import { UTCDateFormatPipe } from './pipe/datetime.pipe';
// import { UTCDateTimeFormatPipe } from './pipe/datetime.pipe';
import {
  UTCDateFormatPipe,
  UTCDateTimeFormatPipe,
  DayDurationPipe,
  WeekdayFormatPipe,
  CalWeekdayFormatPipe,
} from "./pipe/datetime.pipe";
// import { WeekdayFormatPipe } from './pipe/datetime.pipe';
import { MmPipe } from "./mm.pipe";
import {
  StorePipe,
  StoreSlicePipe,
  StoreLikePipe,
  TagPipe,
  HotSearchPipe,
} from "./pipe/store.pipe";
import { TruncatePipe } from "./pipe/truncate.pipe";

// service-worker
import { ServiceWorkerModule } from "@angular/service-worker";
import { IcampingIntroComponent } from "./icamping-intro/icamping-intro.component";
import { IcampingRuleComponent } from "./icamping-rule/icamping-rule.component";
import { StestComponent } from "./stest/stest.component";

import * as $ from "jquery";
import { HotSearchComponent } from "./hot-search/hot-search.component";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/moment";
import * as moment from "moment";
import { BookingCalendarComponent } from "./store/calendar/calendar.component";
import { CalendarUtilsModule } from "./store/calendar/header/module";
import { StoreTelCallComponent } from "./store-tel-call/store-tel-call.component";

export function momentAdapterFactory() {
  return adapterFactory(moment);
}
window["$"] = $;
window["jQuery"] = $;

declare var Hammer: any;
export const firebaseConfig = environment.firebaseConfig;

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    pan: { direction: Hammer.DIRECTION_All },
    swipe: { direction: Hammer.DIRECTION_VERTICAL },
  };

  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: "auto",
      inputClass: Hammer.SUPPORT_POINTER_EVENTS
        ? Hammer.PointerEventInput
        : Hammer.TouchInput,
      recognizers: [
        [
          Hammer.Swipe,
          {
            direction: Hammer.DIRECTION_HORIZONTAL,
          },
        ],
      ],
    });
    return mc;
  }
}
@NgModule({
  declarations: [
    AppComponent,
    CampSearchComponent,
    CampSearchResultComponent,
    CaseStatusFormatPipe,
    ExperienceComponent,
    FooterComponent,
    HomeComponent,
    HomePrivacyComponent,
    LikeComponent,
    LoadingSpinnerComponent,
    LoginComponent,
    MeComponent,
    MeOrderComponent,
    MesgComponent,
    MesgInfoComponent,
    MeTripComponent,
    MeTripEditCaseDetailComponent,
    MeTripEditComponent,
    MeTripInfoCancelledComponent,
    MeTripInfoCaseDetailComponent,
    MeTripInfoCompletedComponent,
    MeTripInfoComponent,
    MeTripInfoPaidComponent,
    MeTripInfoUnpayComponent,
    MmPipe,
    QueueComponent,
    SettingComponent,
    StoreComponent,
    StoreImageComponent,
    StoreInfoComponent,
    StoreSearchComponent,
    StoreSearchResultComponent,
    StoreSearchMetadataComponent,
    StoreStuffComponent,
    StoreStuffSearchResultComponent,
    StorePipe,
    StoreSlicePipe,
    StoreLikePipe,
    TagPipe,
    HotSearchPipe,
    TruncatePipe,
    UserCaseMigrationComponent,
    UserProfileComponent,
    UserVerifyComponent,
    UTCDateFormatPipe,
    UTCDateTimeFormatPipe,
    DayDurationPipe,
    WeekdayFormatPipe,
    IcampingIntroComponent,
    IcampingRuleComponent,
    StestComponent,
    HotSearchComponent,
    BookingCalendarComponent,
    CalWeekdayFormatPipe,
    StoreTelCallComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AngularFireMessagingModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    FormsModule,
    HammerModule,
    HttpClientModule,
    MDBBootstrapModulesPro.forRoot(),
    ReactiveFormsModule,
    ToastModule.forRoot(),
    ServiceWorkerModule.register("/ngsw-worker.js", {
      enabled: environment.production,
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: momentAdapterFactory,
    }),
    CalendarUtilsModule,
  ],
  providers: [
    BookingService,
    ExperienceService,
    HomeService,
    MesgService,
    MDBSpinningPreloader,
    SeoService,
    StoreService,
    TripService,
    UserService,
    PwaService,
    DeviceDetectorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptorService,
      multi: true,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    //{ provide: SETTINGS, useValue: {} },
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}
