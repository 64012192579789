import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'store-tel-call',
  templateUrl: './store-tel-call.component.html',
  styleUrls: ['./store-tel-call.component.scss']
})
export class StoreTelCallComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private _location: Location
  ) { }

  ngOnInit(): void {
    const store_tel = this.route.snapshot.paramMap.get('store_tel');
    window.open(`tel:${store_tel}`);
    this._location.back();
  }

}
