import { Component, OnInit } from "@angular/core";
import { StoreService } from "../service/store.service";
import { UserService } from "../service/user.service";

@Component({
  selector: "app-like",
  templateUrl: "./like.component.html",
  styleUrls: ["./like.component.scss"],
})
export class LikeComponent implements OnInit {
  showSpinner = true;
  stores: Array<any> = [];
  constructor(
    private _storeService: StoreService,
    private _userService: UserService
  ) {}

  ngOnInit() {
    window.sessionStorage.setItem("urlBeforeLogin", "/like");

    this._storeService.storesListUserLike().subscribe((result: any) => {
      if (!result.items) {
        this.showSpinner = false;
        return;
      }
      result.items.forEach((storeObj: any) => {
        this._storeService
          .storeNameGetImageLimit(storeObj.store_name)
          .subscribe((image: any) => {
            storeObj["image"] = image[0].thumbnail_600c_url;
          });

        storeObj["user_like"] = true;
      });

      this.stores = result.items;
      this.showSpinner = false;
    });
  }

  // remove(i: number) {
  //   alert(i);
  // }

  subscribeStore(store) {
    store.user_like = !store.user_like;
    if (store.user_like) {
      this._storeService.pushLikeStoreName(store.store_name);
    } else {
      this._storeService.removeLikeStoreName(store.store_name);
    }
    this._userService
      .userLikeUpdate(store.store_name, !store.user_like)
      .subscribe((resp: any) => {
        this._storeService.getUserLikedStores();
      });
  }
}
