import { BookingService } from "../../../../service/booking.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { StoreService } from "../../../../service/store.service";
import { ToastService } from "ng-uikit-pro-standard";
import { TripService } from "../../../../service/trip.service";
import { UserService } from "../../../../service/user.service";
import * as _ from "lodash";
import * as moment from "moment";

@Component({
  selector: "app-me-trip-info-case-detail",
  templateUrl: "./case-detail.component.html",
  styleUrls: ["./case-detail.component.scss"],
})
export class MeTripInfoCaseDetailComponent implements OnInit, OnDestroy {
  navigationSubscription;

  _: any = _;
  case: any;
  invoices: any;
  caseId: string;
  editable = false;
  error_mesg: string;
  refundedPrice = 0;
  refundPenalty = 0;
  refundPrice = 0;
  rules: any;
  showSpinner = true;
  bossTel = null;
  status: string;
  storeName: string;
  storeActive = true;
  cancelLock = false;
  // 20220516: cancelled_by_covid 收手續費
  caseFreePenalty = ["cancelled_by_boss_fix"];

  // parameters for asking user bank infos
  user_bank = "";
  user_bank_description = "";
  invoice_id = "";
  needBankInfo = true;
  userIsBoss = false;
  userStoreName = "";

  constructor(
    private _bookingService: BookingService,
    private _storeService: StoreService,
    private _tripService: TripService,
    private _userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.init();
      }
    });
  }

  invoiceValidators() {
    return /^(\d{8}|)$/.test(this.invoice_id);
  }

  filterEmptyStuff() {
    return this.case.stuff_info.filter((s) => s[2] > 0);
  }

  toInt(n: string) {
    return parseInt(n, null);
  }

  telCall(x, isIC = true) {
    console.log(x);
    if (isIC) {
      window.open(`tel:${x}`);
    } else {
      this.router.navigate([`/store-tel/${x}`]);
    }
  }

  isTransferToBoss(c: any) {
    if (c.postpone_status) {
      return false;
    }
    return c.tag_color && c.tag_color === "#FF4081";
  }

  init() {
    this.caseId = this.route.snapshot.paramMap.get("case_id");
    this.storeName = this.route.snapshot.paramMap.get("store_name");
    if (!this.caseId || !this.storeName) {
      this.router.navigate(["/", {}]);
    }

    // To get user bank info.
    this._userService.userGet().subscribe((resp: any) => {
      if (resp.status !== "ok" || resp.error_mesg !== "") {
        return;
      }
      this.userIsBoss = resp.item.boss;
      this.userStoreName = resp.item.store_name;
      this.user_bank = resp.item.user_bank;
      this.user_bank_description = resp.item.user_bank_description;
      // this.invoice_id = resp.item.invoice_id;
    });

    this._storeService
      .storeNameGet(this.storeName)
      .subscribe((storeRes: any) => {
        this.bossTel = storeRes.items.tel_boss;
        this.storeActive = storeRes.items.status
          ? storeRes.items.status
          : this.storeActive;
      });

    this._bookingService.ruleGet().subscribe((result: any) => {
      console.log(result);
      this.rules = result.item;
    });

    this._tripService
      .caseCaseIdList(this.caseId, this.storeName)
      .subscribe((_case: any) => {
        console.log(_case);
        this._storeService.hidePageLoading();
        if (_case.item === undefined) {
          this.showSpinner = false;
          this.case = _case.item;
          this.error_mesg = _case.error_mesg;
          return;
        }
        _case.item.stuff_info = JSON.parse(_case.item.stuff_info);
        _case.item.cancel_fee = parseInt(_case.item.cancel_fee, 0);
        _case.item.service_fee = parseInt(_case.item.service_fee, 0);
        this.invoice_id = _case.item.invoice_id;

        // 在stuff藏一個原本的價錢
        _case.item.stuff_info.forEach((stuff) => {
          // console.log(stuff);
          // Get Stuff images
          this._storeService
            .storeNameGetStuffImageLimit(this.storeName, stuff[0])
            .subscribe((stuffImage: any) => {
              // console.log(stuffImage[0]);
              // stuff['image'] = this.sanitizer.bypassSecurityTrustStyle(`url(${stuffImage[0].thumbnail_600c_url})`);
              stuff[6] = stuffImage[0].thumbnail_600c_url;
            });
          stuff[5] = stuff[2];
        });

        // Get Store image
        this._storeService
          .storeNameGetImageLimit(this.storeName)
          .subscribe((image: any) => {
            _case.item["storeImage"] = image[0].thumbnail_600c_url;
          });

        if (_case.item.refund_price) {
          this.refundedPrice = 0;
          this.refundPenalty = _.sum(
            _.map(_case.item.refund_penalty, this.toInt)
          );
          _case.item.refund_minus_quantity = _.map(
            _case.item.refund_minus_quantity,
            this.toInt
          );

          _case.item.quantity_rates = _.map(
            _case.item.quantity_rates,
            this.toInt
          );

          this.refundPrice = _.sum(_.map(_case.item.refund_price, this.toInt));

          _.map(_case.item.refunded_price, this.toInt).forEach((r) => {
            if (r >= 0) {
              this.refundedPrice += r;
            }
          });
        }
        console.log(_case.item);
        this.case = _case.item;
        this.editable =
          moment()
            .add(2, "days")
            .subtract(8, "hours")
            .isSameOrBefore(_case.item.checkin_date) ||
          (_case.item.postpone_reason == "postponed_by_boss_weather" &&
            _case.item.postpone_status == true);
        this.showSpinner = false;
      });

    this._tripService
      .caseInvoiceCaseIdList(this.caseId)
      .subscribe((result: any) => {
        this.invoices = result.items;
      });
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  editCase() {
    this.router.navigate([
      `/me/trip/edit/${this.storeName}/${this.caseId}`,
      {},
    ]);
  }

  hideBankInfo() {
    return !(
      this.userIsBoss &&
      this.case.store_name == this.userStoreName &&
      this.case.status == "paid"
    );
  }

  cancelCase() {
    if (this.cancelLock) {
      alert("取消中！");
      return;
    }
    this.cancelLock = true;
    this.case.cancel_reason = "cancelled_by_guest_app";
    this.case.store_name = this.storeName;
    this.case.user_bank = this.user_bank;
    this.case.user_bank_description = this.user_bank_description;
    this.case.invoice_id = this.invoice_id;

    this._bookingService.caseCancel(this.case).subscribe((result: any) => {
      if (result.error_mesg === "" && result.status === "ok") {
        console.log(result);
        this.cancelLock = false;
        location.reload();
      } else {
        this.toastService.error(
          `取消失敗 [${result.error_mesg}]，請聯絡客服人員，謝謝。`
        );
      }
    });
  }

  postponeRevert() {
    if (
      !this.case.postpone_status ||
      this.case.postpone_reason !== "postponed_by_boss_weather"
    ) {
      return;
    }

    this._bookingService
      .casePostponeStatusUpdate(this.caseId, this.storeName)
      .subscribe((result: any) => {
        this.case.postpone_status = false;
      });
  }
  // postponeCase() {
  //   let caseStoreName = '';
  //   if (this.storeNameSelected !== 'ALL') {
  //     caseStoreName = this.storeNameSelected;
  //   } else {
  //     caseStoreName = this.mdbModalCase.store_name;
  //   }

  //   this._bookingService
  //     .casePostponeStatusUpdate({
  //       postpone_reason: this.mdbModalReason,
  //       case_id: this.mdbModalCase.case_id,
  //       store_name: caseStoreName,
  //     })
  //     .subscribe((result: any) => {
  //       if (result.error_mesg === '' && result.status === 'ok') {
  //         // const index = this.cases.indexOf(_case);
  //         // this.cases.splice(index, 1);
  //         alert('延期完成！');
  //       } else {
  //         alert('延期失敗！');
  //       }
  //     });
  // }

  copyBankTransferAccount() {
    // Get the text field
    let copyText = document
      .getElementById("bank-transfer-account")
      .innerHTML.replace(/ /g, "");
    console.log(copyText);
    navigator.clipboard.writeText(copyText);
  }

  pending_confirm() {
    if (!this.invoiceValidators()) {
      this.toastService.error(`通知已付款失敗，請輸入正確統一編號，謝謝。`);
      return;
    }
    this._bookingService
      .caseStatusUpdate(
        this.caseId,
        "pending_confirm",
        this.storeName,
        this.user_bank,
        this.user_bank_description,
        this.invoice_id
      )
      .subscribe((result: any) => {
        if (result.error_mesg === "" && result.status === "ok") {
          this.toastService.success("已通知愛露營匯款完成");
          this.case.status = "pending_confirm";
          this.case.invoice_id = this.invoice_id;
        }
      });
  }
}
