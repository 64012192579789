import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class BookingService {
  storesCaseIndexQuickSearch: Array<any> = [];
  lastCaseIndexQuickSearchArea = "";
  lastCaseIndexQuickSearchCheckinDate = "";
  lastCaseIndexQuickSearchCheckoutDate = "";
  lastCaseIndexQuickSearchFacility = "";
  lastCaseIndexQuickSearchQty = "";

  constructor(private _http: HttpClient) {}

  caseIndexCalList(storeName: string) {
    return this._http.post(
      `${environment.cloudEndpointGuestTier1}/case_index/cal_list?key=${environment.cloudEndpointApiKey}`,
      {
        store_name: storeName,
      }
    );
  }

  caseIndexStoreCalList(caseModel: any) {
    return this._http.post(
      `${environment.cloudEndpointGuestTier1}/case_index/store_cal_list?key=${environment.cloudEndpointApiKey}`,
      caseModel
    );
  }

  caseIndexDateRestCount(
    checkinDate: any,
    checkoutDate: any,
    storeName: string,
    _quantity: any,
    caseId?: string
  ) {
    return this._http.post(
      `${environment.cloudEndpointGuestTier1}/case_index/date/rest_count?key=${environment.cloudEndpointApiKey}`,
      {
        checkin_date: checkinDate,
        checkout_date: checkoutDate,
        store_name: storeName,
        quantity: _quantity,
        case_id: caseId,
      }
    );
  }

  caseIndexQuickSearch(
    _area: string,
    checkinDate: string,
    checkoutDate: string,
    facility: string,
    _quantity: string
  ) {
    return this._http.post(
      `${environment.cloudEndpointGuestTier1}/case_index/quick_search?key=${environment.cloudEndpointApiKey}`,
      {
        area: _area,
        checkin_date: checkinDate,
        checkout_date: checkoutDate,
        // experience_name: experience,
        facility_name: facility,
        quantity: _quantity,
      }
    );
  }

  matchLastCaseIndexQuickSearch(
    _area: string,
    checkinDate: string,
    checkoutDate: string,
    facility: string,
    _quantity: string
  ) {
    return (
      this.lastCaseIndexQuickSearchArea === _area &&
      this.lastCaseIndexQuickSearchCheckinDate === checkinDate &&
      this.lastCaseIndexQuickSearchCheckoutDate === checkoutDate &&
      this.lastCaseIndexQuickSearchFacility === facility &&
      this.lastCaseIndexQuickSearchQty === _quantity &&
      this.storesCaseIndexQuickSearch.length > 0
    );
  }

  getStoresCaseIndexQuickSearch() {
    return this.storesCaseIndexQuickSearch;
  }

  setStoresCaseIndexQuickSearch(
    _area: string,
    checkinDate: string,
    checkoutDate: string,
    facility: string,
    _quantity: string,
    storesCaseIndexQuickSearch: any
  ) {
    this.lastCaseIndexQuickSearchArea = _area;
    this.lastCaseIndexQuickSearchCheckinDate = checkinDate;
    this.lastCaseIndexQuickSearchCheckoutDate = checkoutDate;
    this.lastCaseIndexQuickSearchFacility = facility;
    this.lastCaseIndexQuickSearchQty = _quantity;
    this.storesCaseIndexQuickSearch = storesCaseIndexQuickSearch;
    return storesCaseIndexQuickSearch;
  }

  caseCreate(caseModelMetadata: any, caseModel: any) {
    const quantityList = [];
    Object.values(caseModel).forEach((e) => {
      quantityList.push(e[0]);
    });

    return this._http.post(
      `${environment.cloudEndpointGuestTier1}/case/create?key=${environment.cloudEndpointApiKey}`,
      {
        user_tel: caseModelMetadata.user_tel,
        checkin_date: caseModelMetadata.checkin_date,
        checkout_date: caseModelMetadata.checkout_date,
        stuff_name_list: Object.keys(caseModel),
        stuff_quantity_list: quantityList,
        // stuff_quantity_list: Object.values(caseModel),
        store_name: caseModelMetadata.store_name,
      }
    );
  }

  caseStatusUpdate(
    _case_id,
    _status,
    _store_name,
    _user_bank,
    _user_bank_description,
    _invoice_id
  ) {
    return this._http.post(
      `${environment.cloudEndpointGuestTier1}/case/status/update?key=${environment.cloudEndpointApiKey}`,
      {
        case_id: _case_id,
        status: _status,
        store_name: _store_name,
        user_bank_description: _user_bank_description,
        user_bank: _user_bank,
        invoice_id: _invoice_id,
      }
    );
  }

  casePostponeStatusUpdate(_case_id, _store_name) {
    return this._http.post(
      `${environment.cloudEndpointGuestTier1}/case/postpone_status/update?key=${environment.cloudEndpointApiKey}`,
      {
        case_id: _case_id,
        store_name: _store_name,
      }
    );
  }

  caseCancel(caseModel: any) {
    console.log(caseModel);
    return this._http.post(
      `${environment.cloudEndpointGuestTier1}/case/cancel?key=${environment.cloudEndpointApiKey}`,
      caseModel
    );
  }

  caseDateUpdate(caseModel: any) {
    return this._http.post(
      `${environment.cloudEndpointGuestTier1}/case/date/update?key=${environment.cloudEndpointApiKey}`,
      caseModel
    );
  }

  caseQuantityUpdate(caseModel: any) {
    return this._http.post(
      `${environment.cloudEndpointGuestTier1}/case/quantity/update?key=${environment.cloudEndpointApiKey}`,
      caseModel
    );
  }

  queueCreate(queueModel: any) {
    return this._http.post(
      `${environment.cloudEndpointGuestTier2}/queue/create?key=${environment.cloudEndpointApiKey}`,
      queueModel
    );
  }

  ruleGet() {
    return this._http.get(`${environment.cloudEndpointGuestTier2}/rule/get`, {
      params: { key: environment.cloudEndpointApiKey },
    });
  }
}
