import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from '../service/home.service';

@Component({
  selector: 'hot-search',
  templateUrl: './hot-search.component.html',
  styleUrls: ['./hot-search.component.scss']
})
export class HotSearchComponent implements OnInit {
  stores$: any;
  keepRefreshStores;
  x = 0;
  showSpinner = true;
  tags = [];

  constructor(
    private router: Router,
    private _homeService: HomeService,
  ) { }

  ngOnInit(): void {

    this.stores$ = this._homeService.getExperience();
    console.log(this.stores$);

    this.keepRefreshStores = setInterval(() => {
      this.x++;
      if (this.x > 180) {
        clearInterval(this.keepRefreshStores);
      }
    }, 1000);
  }

  goStoreSearchResultForExperience(name: string, tags = [], cities = []) {
    console.log(tags);
    console.log(cities);
    this._homeService.setExperienceFilter(tags, cities);
    this._homeService.setStoreSrearchResultSlogan(name);
    this.router.navigate(['/store/search-result']);
  }
}
