<div class="text-center pb-3 mt2r">
  <app-loading-spinner *ngIf="showSpinner"> </app-loading-spinner>

  <div class="row" *ngIf="mesgs.length === 0">
    <!-- Grid column -->
    <div class="col">
      <!-- Excerpt -->
      <p class="font-weight-bold dark-grey-text">目前沒有訊息喔！</p>
    </div>
    <!-- Grid column -->
  </div>

  <!-- Small news -->
  <div *ngIf="mesgs">
    <!-- Grid row -->
    <div class="row">
      <div
        class="col-lg-3 col-md-4 col-sm-6 col-12 px-sm-2 px-0"
        *ngFor="let mesg of mesgs; let i = index"
      >
        <!-- Card -->
        <div class="card mb-4">
          <!-- Card image -->
          <div class="view overlay">
            <img class="card-img-top" src="{{ mesg.image }}" />
            <a href="/mesg/{{ mesg.id }}">
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>
          <!-- Card content -->
          <div class="card-body">
            <div class="row">
              <div class="col-10 text-left">
                <a class="dark-grey-text" href="/mesg/{{ mesg.id }}">
                  <div class="h5-responsive">{{ mesg.store_alias }}</div>
                  <div class="text-muted i-green">{{ mesg.subject }}</div>
                  <div class="text-muted grey-text">
                    <i class="far fa-clock pr-1"></i
                    >{{ mesg.ctime | utcDateFormat }}
                  </div>
                </a>
              </div>
              <div class="col-2 text-right d-flex align-items-center">
                <a class="grey-text" (click)="deleteMesg(i)"
                  ><i class="far fa-trash-alt fa-2x"></i
                ></a>
              </div>
            </div>
          </div>

          <!-- Card footer
          <div class="rounded-bottom i-green-l-bg text-center pt-3">
            <ul class="list-unstyled list-inline font-small">
              <li class="list-inline-item , white-text">
                <
              </li>

              <li class="list-inline-item">
                <a class="white-text" (click)="deleteMesg(i)"
                  ><i class="far fa-trash-alt ml-4 fa-2x"></i
                ></a>
              </li>
            </ul>
          </div>-->
        </div>
        <!-- Card -->
      </div>
    </div>
    <!-- Grid row -->
  </div>
  <!-- Small news -->
</div>
