import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "caseStatusFormat" })
export class CaseStatusFormatPipe implements PipeTransform {
  transform(caseStatus: any): String {
    const cs = {
      cancel: "已取消",
      checkout: "已拔營",
      paid: "已付款",
      payment_reminder: "提醒付款",
      pending_confirm: "待確認",
      pending_refund: "待退款",
      refunded: "已退款",
      rejuect_refunded: "拒絕退款",
      unpay: "未付款",

      cancelled_by_boss_fix: "休園",
      cancelled_by_boss_weather: "天候不佳",
      cancelled_by_guest_callin: "客人來電取消",
      cancelled_by_guest_app: "客人自行取消",
      cancelled_by_boss_repair: "道路維修",
      cancelled_by_boss_maintenance: "營區整修",
      cancelled_by_boss_close: "營區公休",
      cancelled_by_icamping: "訂單不成立",
      cancelled_by_cron: "繳費逾期",
      cancelled_by_covid: "因疫情取消",

      postponed_by_boss_weather: "天候不佳但園區開放",
      postponed_by_covid: "因疫情延期",
    }[caseStatus];

    if (cs === null) {
      return caseStatus;
    }
    return cs;
  }
}
