import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "mwl-utils-calendar-header",
  template: `
    <div class="row text-center">
      <div class="col-md-12">
        <h3>{{ viewDate | calendarDate:(view + "ViewTitle"):locale}}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="btn-group">
          <button mdbBtn type="button" color="dark-green" class="waves-light" mdbWavesEffect
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="viewDateChange.next(viewDate)">
            <mdb-icon fas icon="angle-double-left"></mdb-icon>
            上個月
          </button>

          <button mdbBtn type="button" color="dark-green" class="waves-light" mdbWavesEffect
          mwlCalendarToday
          [(viewDate)]="viewDate"
          (viewDateChange)="viewDateChange.next(viewDate)">
            今天
          </button>

          <button mdbBtn type="button" color="dark-green" class="waves-light" mdbWavesEffect
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="viewDateChange.next(viewDate)">
          下個月
          <mdb-icon fas icon="angle-double-right"></mdb-icon>
          </button>

        </div>
      </div>
    </div>
    <br />
  `,
  styleUrls: ['../calendar.component.scss'],
})
export class CalendarHeaderComponent {
  @Input() view: string;

  @Input() viewDate: Date;

  @Input() locale: string = "en";

  @Output() viewChange: EventEmitter<string> = new EventEmitter();

  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();
}
