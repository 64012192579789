import { AuthService } from '../core/auth.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from '../service/home.service';
import { StoreService } from '../service/store.service';
import { UserService } from '../service/user.service';

import * as _ from 'lodash';


@Component({
  selector: 'store-search-metadata',
  templateUrl: './store-search-metadata.component.html',
  styleUrls: ['./store-search-metadata.component.scss']
})
export class StoreSearchMetadataComponent implements OnInit {
  experiences$: any;
  name = '';
  targetExp: any = undefined;
  stores: Array<any> = [];
  showSpinner = true;
  slogan = '';
  x = 0;
  keepRefreshStores;

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private _homeService: HomeService,
    private _userService: UserService,
    private _storeService: StoreService,
  ) { }

  ngOnInit(): void {
    this.name = this.route.snapshot.paramMap.get('name');
    this._homeService.experienceListName(false).subscribe((result: any) => {
      if (!result.items) {
        this.router.navigate(['/']);
      }
      for (let index = 0; index < result.items.length; index++) {
        const exp = result.items[index];
        if (exp.name === this.name) {
          this.targetExp = exp;
          break;
        }
      }

      if (this.targetExp == undefined) {
        this.router.navigate(['/']);
        return;
      }

      this.slogan = this.targetExp.name;
      this._homeService.getStores().subscribe((stores:any) => {
        if (stores.length >= 1) {
          this.stores = this._filter(stores);
        } else {
          this.stores = stores
        }
        this.showSpinner = false;
        this._storeService.hidePageLoading();
      });
    });

    this.keepRefreshStores = setInterval(() => {
      this.x++;
      if (this.x > 180) {
        clearInterval(this.keepRefreshStores);
      }
    }, 1000);
  }

  _filter(stores) {
    const cityFilter = this.targetExp.cities;
    const tagFilter = this.targetExp.tags;
    if (cityFilter && cityFilter.length > 0) {
      stores = stores
      .filter((it) => {
        return cityFilter.some((f) => {
          return JSON.stringify(it.city).includes(f);
        });
      })
    }

    if (tagFilter && tagFilter.length > 0) {
      stores = stores
      .filter((it) => {
        if (!it.facility) {
          return false;
        }

        return tagFilter.every((f) => {
          return JSON.stringify(it.facility).includes(f);
        });
      });
    }

    return stores;
  }

  subscribeStore(store) {
    if (!this.auth.userLoggedin) {
      this.router.navigate(['/login']);
      return;
    }
    
    store.user_like = !store.user_like;
    if (store.user_like) {
      this._storeService.pushLikeStoreName(store.store_name);
    } else {
      this._storeService.removeLikeStoreName(store.store_name);
    }
    this._userService
      .userLikeUpdate(store.store_name, !store.user_like)
      .subscribe((resp: any) => {
        this._storeService.getUserLikedStores();
      });
  }
}
