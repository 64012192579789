import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { StoreService } from '../../service/store.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class StoreImageComponent implements OnInit {
  storeImages: any;

  constructor(private route: ActivatedRoute,
              private _storeService: StoreService) { }

  ngOnInit() {
    this.route.params.subscribe((res) => {
      this._storeService.storeNameGetImage(res.store_name)
        .subscribe((result: any) => {
          //console.log(result);
          this.storeImages = result.filter((img) => {
            return img.thumbnail_1200c_url
          });
        })
    });
  }
}
