import { Component, OnInit } from '@angular/core';
import { HomeService } from '../service/home.service';
import { StoreService } from '../service/store.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
//import { forEach } from '@angular/router/src/utils/collection';


@Component({
  selector: 'app-store-search',
  templateUrl: './store-search.component.html',
  styleUrls: ['./store-search.component.scss']
})
export class StoreSearchComponent implements OnInit {
  objectKeys = Object.keys;
  facilities: any;
  areas: any;
  conditions: any;

  constructor(
    private router: Router,
    private _homeService: HomeService,
    private _storeService: StoreService,
  ) {
    this._storeService.hidePageLoading();
  }

  ngOnInit() {
    const filter = this._homeService.getStoreFilter();
    this.areas = filter.areaFilter;
    this.conditions = filter.conditionFilter;
    this.facilities = filter.facilityFilter;
  }

  goSearch() {
    this._homeService.setDefaultExperienceFilter();
    // this._homeService.setAllStoreFilter('');
    this._homeService.setStoreSrearchResultSlogan('');
    this._homeService.setStoreFilter(
      this.areas, this.conditions, this.facilities
    );
    this.router.navigate(['/store/search-result']);
  }

  onFacilityChange(e) {
    const index = e.element.tabIndex;
    if (index !== 0) {
      this.facilities['全選'] = false;
      return;
    } else {
      Object.keys(this.facilities).forEach(k => {
        if (k !== '全選') {
          this.facilities[k] = false;
        }
      });
    }
  }

  onConditionChange(e) {
    const index = e.element.tabIndex;
    if (index !== 0) {
      this.conditions['全選'] = false;
      return;
    } else {
      Object.keys(this.conditions).forEach(k => {
        if (k !== '全選') {
          this.conditions[k] = false;
        }
      });
    }
  }

  areaRemove(a: string) {
    return a.replace('營區','');
  }
}
