// import { trackByWeekDayHeaderDate } from 'angular-calendar/modules/common/util';
import { BookingService } from '../../service/booking.service';
import { CalendarEvent } from 'angular-calendar';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-booking-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class BookingCalendarComponent implements OnInit, OnChanges {
  showSpinner = true;
  @Input() storeName: string;
  @Input() caseIndexType: string;
  @Input() showRestOfCaseIndex = false;
  @Input() fromEgg = true;

  @Output() clickedDateEvent = new EventEmitter<Date>();
  // trackByWeekDayHeaderDate = trackByWeekDayHeaderDate;
  caseModel: any;
  caseIndexMap: any;
  events: any = [];
  lastClickedDate: string = '';
  view: string;
  viewDate1: Date;
  viewDate2: Date;
  viewDate3: Date;
  locale = 'zh-Hant';
  // locale = 'en';
  sumOfStuffsCap = 0;

  stuffs: any = [
    { name: '目前無訂單', cap: 0, sold: 0 },
  ];

  headElements = ['營地名稱', '總數量', '剩餘'];
  headElementKeys = ['name', 'cap', 'rest'];

  constructor(private _bookingService: BookingService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showRestOfCaseIndex.firstChange) {
      return;
    }

    this.events.forEach(event => {
      event.title = `${this.sumOfStuffsCap - parseInt(event.title, 10)}`;
    });
  }

  ngOnInit() {
    this.view = 'month';
    const currentDate = moment();
    this.viewDate1 = currentDate.toDate();
    this.lastClickedDate = this.calDateClicked(currentDate.toDate());
    this.viewDate2 = moment(currentDate).add(1, 'M').toDate();
    this.viewDate3 = moment(currentDate).add(2, 'M').toDate();
    this.caseModel = {
      case_index_type: this.caseIndexType,
      store_name: this.storeName,
    };

    this._bookingService
      .caseIndexStoreCalList(this.caseModel)
      .subscribe((result: any) => {
        console.log(result);
        if (result.items) {
          result.items.forEach(dateInfo => {
            dateInfo.end = new Date(dateInfo.end);
            dateInfo.start = new Date(dateInfo.start);
          });
          this.events = result.items;
          this.sumOfStuffsCap = result.stuff_total ? result.stuff_total : this.sumOfStuffsCap;
          this.caseIndexMap = JSON.parse(result.case_index_map);
          this.dateClicked(currentDate.toDate());
        }
        this.showSpinner = false;
      });
  }

  calDateClicked(date: Date) {
    console.log(date);
    const dt_year = date.getFullYear();
    const dt_month = date.getMonth() + 1;
    const dt_date = date.getDate();
    // 雷: https://blog.csdn.net/xllily_11/article/details/54929917
    // const dt_year = new Date(`${date}Z`).getFullYear();
    // const dt_month = new Date(`${date}Z`).getMonth() + 1;
    // const dt_date = new Date(`${date}Z`).getDate();
    let casesFilterKeyWord = '';
    if (dt_month < 10) {
      casesFilterKeyWord = `${dt_year}-0${dt_month}-${dt_date}`;
      if (dt_date < 10) {
        casesFilterKeyWord = `${dt_year}-0${dt_month}-0${dt_date}`;
      }
    } else if (dt_date < 10) {
      casesFilterKeyWord = `${dt_year}-${dt_month}-0${dt_date}`;
      if (dt_month < 10) {
        casesFilterKeyWord = `${dt_year}-0${dt_month}-0${dt_date}`;
      }
    } else {
      casesFilterKeyWord = `${dt_year}-${dt_month}-${dt_date}`;
    }
    return casesFilterKeyWord;
  }

  dateClicked(date): void {
    if (this.caseIndexMap == undefined) return;
    let d = this.calDateClicked(date);
    this.stuffs = this.caseIndexMap[d] ? this.caseIndexMap[d] : []
    this.stuffs.sort(function (a, b) {
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    this.lastClickedDate = d;
    this.clickedDateEvent.emit(date);
  }
}
