import { AuthService, UserInterface } from "../core/auth.service";
import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { SeoService } from "../service/seo.service";
import { StoreService } from "../service/store.service";
import { UserService } from "../service/user.service";
import {
  ActivatedRoute,
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from "@angular/router";

import { StoreStuffComponent } from "./stuff/stuff.component";

import * as moment from "moment";
@Component({
  selector: "app-store",
  templateUrl: "./store.component.html",
  styleUrls: ["./store.component.scss"],
})
export class StoreComponent implements OnInit {
  @ViewChild("storeStuff") private storeStuff: StoreStuffComponent;

  closeDate: Date;
  lineLink: string;
  navigationSubscription;
  openDate: Date;
  showRestOfCaseIndex = false;
  showSpinner = true;
  storeInfo: any;
  storeStatus = false;
  userAuth;
  userLoggedin = false;
  userVerified = false;
  weekdayOpen: Array<string>;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private _storeService: StoreService,
    private _seo: SeoService,
    private _userService: UserService
  ) {
    this.navigationSubscription = this.router.events.subscribe((event: any) => {
      if (event.url != undefined) {
        // 強制開啟 chrome
        // if (
        //   !!navigator.userAgent
        //     .split(";")[0]
        //     .split(" ")[1]
        //     .match(/iPhone|iPod|iPad/)
        // ) {
        //   if (
        //     navigator.userAgent.indexOf("FBAV") > -1 ||
        //     navigator.userAgent.indexOf("FBAN") > -1 ||
        //     navigator.userAgent.indexOf("Line") > -1
        //   ) {
        //     location.href = "googlechromes://m.icamping.app/" + this.router.url;
        //   }

        //   console.log(navigator.userAgent);
        // }

        // 強制轉址
        // https://github.com/axacheng/icamping-core/issues/786
        // 2023/03/04 [戈巴侖露營區] 轉到 [杉嵐營地]
        if (event.url.includes("/store/gbl141")) {
          this.router.navigate([`/store/sr348`]);
        } else if (event.url.includes("/store/sp310")) {
          this.router.navigate([`/store/jcs324`]);
          // https://github.com/axacheng/icamping-core/issues/807
        } else if (event.url.includes("/store/jstd143")) {
          this.router.navigate([`/store/cswy378`]);
        } else if (event.url.includes("/store/cskf277")) {
          this.router.navigate([`/store/zys484`]);
        }
      }

      if (event instanceof NavigationStart) {
        console.log("NavigationStart:" + this.router.url);
        window.sessionStorage.setItem("storeNaviStart", this.router.url); // /store/yssl286
      }

      if (event instanceof NavigationEnd) {
        console.log("NavigationEnd:" + this.router.url);
        if (this.router.url === "/") {
          this._storeService.hidePageLoading();
        } else {
          this.storeStatus = false;
          this.fetchStoreInfo();
        }
        window.sessionStorage.setItem("storeNaviEnd", this.router.url); // login
      }
    });

    this.userAuth = this.auth.user$.subscribe((user: UserInterface) => {
      if (!user) {
        return;
      }
      this.userLoggedin = true;
      if (user.verified && !this.userVerified) {
        this.userVerified = true;
      }
    });
  }

  ngOnInit() {
    this.fetchStoreInfo();
  }

  fetchStoreInfo() {
    const store_name = this.route.snapshot.paramMap.get("store_name");
    console.log(store_name);

    this._storeService.storeNameGet(store_name).subscribe((result: any) => {
      console.log(result);
      if (result.error_mesg.length !== 0) {
        this._storeService.hidePageLoading();
        this.router.navigate([`/`]);
        return;
      }

      this.storeStatus = true;
      // populate meta tags for SEO
      this._storeService
        .storeNameGetImageLimit(store_name)
        .subscribe((image: any) => {
          console.log(image[0].thumbnail_400c_url);
          this._seo.generateTags({
            title: result.items.store_alias,
            description: result.items.description,
            image: image[0].thumbnail_400c_url,
            slug: `/store/${result.items.store_name}`,
          });
          this._storeService.hidePageLoading();
        });

      this.storeInfo = result.items;
      this.closeDate = moment(result.items.close_date)
        .add(8, "hour")
        .utcOffset("Asia/Taipei")
        .toDate();
      this.openDate = moment(result.items.open_date)
        .add(8, "hour")
        .utcOffset("Asia/Taipei")
        .toDate();
      this.weekdayOpen = result.items.weekday_open;

      if (this.storeInfo.store_name === "glamping900") {
        this.lineLink = "https://lin.ee/hxtQunx";
      } else {
        this.lineLink = "https://lin.ee/XzC6RJJ";
      }
    });
  }

  subscribeStore(store) {
    if (!this.auth.userLoggedin) {
      this.router.navigate(["/login"]);
      return;
    }

    store.user_like = !store.user_like;
    if (store.user_like) {
      this._storeService.pushLikeStoreName(store.store_name);
    } else {
      this._storeService.removeLikeStoreName(store.store_name);
    }
    this._userService
      .userLikeUpdate(store.store_name, !store.user_like)
      .subscribe((resp: any) => {
        this._storeService.getUserLikedStores();
      });
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
      this.userAuth.unsubscribe();
    }
  }

  calDateClicked(date: any) {
    const d = moment(date).format("YYYY-MM-DD");
    console.log(d);
    // this.casesFilterKeyWord = d;
  }

  telCall(x, isIC = true) {
    if (isIC) {
      window.open(`tel:${x}`);
    } else {
      this.router.navigate([`/store-tel/${x}`]);
    }
  }

  lineServiceSupport(storeName: string) {
    if (storeName === "glamping900") {
      this.lineLink = "https://lin.ee/hxtQunx";
    } else {
      this.lineLink = "https://lin.ee/XzC6RJJ";
    }

    window.open(this.lineLink);
  }

  showBook() {
    if (!this.userLoggedin) {
      this.storeStuff.goUserLogin();
    } else if (!this.userVerified) {
      this.storeStuff.goUserVerify();
    } else {
      if (this.storeStuff.stuffs.length <= 0) {
        return;
      }
      this.storeStuff.stuffSearchModalShow(this.storeStuff.stuffs[0]);
    }
  }
}
