<div class="scrolling-wrapper-flexbox">
  <div class="card">
    <h2>Card</h2>
  </div>
  <div class="card">
    <h2>Card</h2>
  </div>
  <div class="card">
    <h2>Card</h2>
  </div>
  <div class="card">
    <h2>Card</h2>
  </div>
  <div class="card">
    <h2>Card</h2>
  </div>
  <div class="card">
    <h2>Card</h2>
  </div>
  <div class="card">
    <h2>Card</h2>
  </div>
  <div class="card">
    <h2>Card</h2>
  </div>
  <div class="card">
    <h2>Card</h2>
  </div>
</div>
