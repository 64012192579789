<div class="text-center pb-3">
  <div *ngIf="experienceImages">
    <mdb-carousel
      [isControls]="true"
      class="carousel slide carousel-fade main_banner"
      [animation]="'fade'"
      [interval]="'0'"
    >
      <mdb-slide *ngFor="let experienceImage of experienceImages">
        <div class="view hm-black-light main_banner">
          <img
            class="d-block w-100 "
            src="{{ experienceImage.thumbnail_600c_url }}"
            alt=""
          />
          <div class="mask waves-light" mdbRippleRadius></div>
        </div>
        <div class="carousel-caption">
          <h2 class="h2-responsive mt-5">{{ experienceImage.storeAlias }}</h2>
          <h5 class="h5-responsive mt-5">
            {{ experienceImage.experienceName }}@{{ experienceImage.stuffName }}
          </h5>
        </div>
      </mdb-slide>
    </mdb-carousel>

    <div class="m-sm-5 m-3" [innerHtml]="experienceDescription"></div>

    <div class="row">
      <div
        class="col-lg-3 col-sm-6 col-12 mb-2"
        *ngFor="let stuff of stuffs; let i = index"
      >
        <div class="card" *ngIf="stuff.image">
          <div class="view overlay rounded z-depth-1 waves-light h-max">
            <img
              class="img-fluid"
              src="{{ stuff.image.thumbnail_600c_url }}"
              alt=""
            />
          </div>
          <div class="card-body">
            <h4 class="card-title">主題{{ i + 1 }}: {{ stuff.name }}</h4>
            <div [innerHtml]="stuff.description | truncate: [100]"></div>

            <button
              mdbBtn
              type="button"
              color="secondary"
              mdbWavesEffect
              (click)="getStore(stuff.store_name)"
            >
              {{ stuff.store_alias }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
