<section class="magazine-section mt-3 mm">
  <h4>排隊中訂單</h4>
  <p class="dark-grey-text w-responsive mx-auto mb-5">
    {{
      queues.length !== 0 ? "包含：營區,體驗,商品" : "目前沒有排隊中訂單喔！"
    }}
  </p>
  <app-loading-spinner *ngIf="showSpinner"></app-loading-spinner>
  <div class="row">
    <div
      class="col-lg-4 col-md-6 col-sd-12 mb-4 bighero"
      *ngFor="let queue of queues"
    >
      <div class="card">
        <div class="view overlay rounded z-depth-1 waves-light" mdbWavesEffect>
          <img class="card-img-top" src="{{ queue.image }}" alt="" />
        </div>
        <mdb-card-body class="mt-1">
          <h6 class="h6-responsive black-text">
            <strong>
              <mdb-icon
                class="mr-1 my-1 pink-text"
                fas
                icon="map-pin"
              ></mdb-icon
              >{{ queue.store_alias }}
            </strong>
          </h6>
          <div class="col">
            <p class="grey-text">
              <mdb-icon fas icon="campground" class="mr-1 my-1"></mdb-icon
              >{{ queue.checkin_date | utcDateFormat }} -
              {{ queue.checkout_date | utcDateFormat }}
            </p>
          </div>
        </mdb-card-body>
      </div>
    </div>
    <div class="row smallhero bottom_line" *ngFor="let queue of queues">
      <div class="row4">
        <!-- Featured image -->
        <div
          class="view overlay rounded z-depth-1-half waves-light"
          mdbWavesEffect
          id="{{ queue.stuff_name }}"
        >
          <img
            class="img-fluid"
            src="{{ queue.image }}"
            alt="{{ queue.stuff_name }}"
          />
        </div>
      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="row6">
        <!-- Post title -->
        <div class="row">
          <h5 class="dark-grey-text">
            <strong>{{ queue.store_alias }}</strong>
          </h5>
        </div>
        <div class="row">
          <div class="f13 i-green text-left">
            <mdb-icon fas icon="campground"></mdb-icon>
            {{ queue.checkin_date | utcDateFormat }} 至<br />
            {{ queue.checkout_date | utcDateFormat }}
          </div>
        </div>

        <!-- Excerpt -->

        <!-- Post data -->
      </div>
      <!-- Grid column -->
    </div>
  </div>
</section>
