import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MesgService } from '../../service/mesg.service';
import { StoreService } from '../../service/store.service';

@Component({
  selector: 'app-mesg-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class MesgInfoComponent implements OnInit {
  showSpinner = true;
  mesg: any;

  constructor(
    private route: ActivatedRoute,
    private _mesgService: MesgService,
    private _storeService: StoreService,
  ) {}

  ngOnInit() {
    const msUrlSafe = this.route.snapshot.paramMap.get('mesg_id');
    let defaultImageUrl =
      'https://firebasestorage.googleapis.com/v0/b/icamping-tree.appspot.com/'
    defaultImageUrl +=
      'o/images%2Fstore%2Ffs009%2Ficon1200.jpg?';
    defaultImageUrl +=
      'alt=media&token=dff26bf5-2c7d-47e3-a825-0783b52bdcf5';

    this._mesgService.mesgIdGet(msUrlSafe).subscribe((result: any) => {
      if (result.status === 'ok' && result.error_mesg === '') {
        result.items['image'] = defaultImageUrl;

        this._storeService
          .storeNameGetImageLimit(result.items.store_name)
          .subscribe((image: any) => {
            // console.log(image);
            result.items['image'] = image[0].orig_url;
          });

        console.log(result);

        this.mesg = result.items;
      } else {
        alert('Failed to get message!');
      }
    });
  }
}
