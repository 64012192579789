<!--Main Navigation-->

<header class="intro-header">
  <!--Navbar-->

  <mdb-navbar
    #navbar
    SideClass="navbar navbar-light navbar-light white lighten-5 navbar-expand-lg scrolling-navbar sticky-top"
    [containerInside]="true"
  >
    <div class="d-flex justify-content-between w100 mt-10">
      <div class="d-flex-center">
        <mdb-navbar-brand>
          <a (click)="sidenav.toggle()" class="f25 p-2">
            <i class="fa fa-bars"></i>
          </a>
          <a (click)="toogleSearch()" class="f25 p-2 ml-2">
            <i class="fa fa-search"></i>
          </a>
        </mdb-navbar-brand>
      </div>
      <div class="d-flex justify-content-center">
        <a routerLink="/"
          ><img
            src="/assets/images/ic-logo-full.png"
            class="logo-m img-fluid flex-center"
        /></a>
      </div>
      <div class="d-flex-center">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item waves-light nav-filler">
            <a
              class="nav-link f25 m-auto"
              mdbWavesEffect
              type="button"
              routerLink="{{ !(auth.user$ | async) ? '/login' : '/like' }}"
            >
              <mdb-icon fas icon="heart" class="text-danger"></mdb-icon>
            </a>
          </li>
          <!-- <li class="nav-item dropdown" dropdown>
            <a
              dropdownToggle
              mdbWavesEffect
              type="button"
              class="nav-link dropdown-toggle waves-light"
              mdbWavesEffect
            >
              Basic dropdown<span class="caret"></span
            ></a>
            <div
              *dropdownMenu
              class="dropdown-menu dropdown dropdown-primary"
              role="menu"
            >
              <a class="dropdown-item waves-light" mdbWavesEffect href="#"
                >Action</a
              >
              <a class="dropdown-item waves-light" mdbWavesEffect href="#"
                >Another action</a
              >
              <a class="dropdown-item waves-light" mdbWavesEffect href="#"
                >Something else here</a
              >
              <div class="divider dropdown-divider"></div>
              <a class="dropdown-item waves-light" mdbWavesEffect href="#"
                >Separated link</a
              >
            </div>
          </li> -->
          <li class="nav-item waves-light nav-filler dropdown" dropdown>
            <a
              dropdownToggle
              mdbWavesEffect
              type="button"
              class="nav-link waves-light f25 m-auto"
            >
              <i class="fa fa-user"></i>
            </a>
            <div
              *dropdownMenu
              class="dropdown-menu dropdown dropdown-primary"
              role="menu"
            >
              <a
                class="dropdown-item waves-light"
                mdbWavesEffect
                *ngIf="auth.user$ | async"
                routerLink="/me/order"
              >
                <i class="fa fa-campground"></i> 我的行程</a
              >
              <a
                class="dropdown-item waves-light"
                mdbWavesEffect
                *ngIf="auth.user$ | async"
                routerLink="/mesg"
              >
                <i class="fa fa-message"></i> 我的訊息</a
              >
              <a
                class="dropdown-item waves-light"
                mdbWavesEffect
                *ngIf="auth.user$ | async"
                routerLink="/like"
              >
                <mdb-icon fas icon="heart" class="text-danger"></mdb-icon>
                我的最愛
              </a>
              <a
                class="dropdown-item waves-light"
                mdbWavesEffect
                *ngIf="auth.user$ | async"
                routerLink="/setting"
              >
                <i class="fa fa-cog"></i> 設定</a
              >
              <div
                class="divider dropdown-divider"
                *ngIf="auth.user$ | async"
              ></div>
              <a
                class="dropdown-item waves-light"
                mdbWavesEffect
                routerLink="/icamping-rule"
              >
                <i class="fa fa-exclamation-circle"></i>
                愛露營使用規則</a
              >
              <a
                class="dropdown-item waves-light"
                mdbWavesEffect
                routerLink="/icamping-intro"
              >
                <i class="fa fa-comments"></i>
                聯絡我們
              </a>
              <a
                class="dropdown-item waves-light"
                mdbWavesEffect
                *ngIf="auth.user$ | async"
                (click)="logout()"
                ><i class="fa fa-sign-out-alt"></i> 登出</a
              >
              <a
                class="dropdown-item waves-light"
                mdbWavesEffect
                *ngIf="!(auth.user$ | async)"
                routerLink="/login"
                ><i class="fa fa-sign-in"></i> 登入</a
              >
            </div>
          </li>
        </ul>
        <!-- <ul class="navbar-nav ml-auto">
              <li
                class="nav-item waves-light text-center"
                mdbRippleRadius
                *ngIf="auth.user$ | async"
              >
                <a
                  class="nav-link"
                  (click)="navbar.hide()"
                  routerLink="/camp/search"
                  >🗓 快速訂位</a
                >
                <div class="dropdown-divider"></div>
              </li>

              <li
                class="nav-item waves-light text-center"
                mdbRippleRadius
                *ngIf="auth.user$ | async"
              >
                <a class="nav-link" (click)="navbar.hide()" routerLink="/me/order"
                  >我的行程</a
                >
                <div class="dropdown-divider"></div>
              </li>

              <li
                class="nav-item waves-light text-center"
                mdbRippleRadius
                *ngIf="auth.user$ | async"
              >
                <a class="nav-link" (click)="navbar.hide()" routerLink="/mesg"
                  >我的訊息</a
                >
                <div class="dropdown-divider"></div>
              </li>

              <li
                class="nav-item waves-light text-center"
                mdbRippleRadius
                *ngIf="auth.user$ | async"
              >
                <a class="nav-link" (click)="navbar.hide()" routerLink="/like"
                  >我的最愛</a
                >
                <div class="dropdown-divider"></div>
              </li>

              <li
                class="nav-item waves-light text-center"
                mdbRippleRadius
                *ngIf="auth.user$ | async"
              >
                <a
                  class="nav-link"
                  id="user-setting"
                  (click)="navbar.hide()"
                  routerLink="/setting"
                  >設定</a
                >
                <div class="dropdown-divider"></div>
              </li>

              <li class="nav-item waves-light text-center" mdbRippleRadius>
                <a
                  class="nav-link"
                  id="user-setting"
                  (click)="navbar.hide()"
                  routerLink="/icamping-rule"
                  >規則</a
                >
                <div class="dropdown-divider"></div>
              </li>

              <li class="nav-item waves-light text-center" mdbRippleRadius>
                <a
                  class="nav-link"
                  id="user-setting"
                  (click)="navbar.hide()"
                  routerLink="/icamping-intro"
                  >介紹</a
                >
                <div class="dropdown-divider"></div>
              </li>

              <li class="nav-item waves-light text-center" mdbRippleRadius>
                <a
                  class="nav-link"
                  id="login"
                  routerLink="/login"
                  *ngIf="!(auth.user$ | async)"
                  #guest
                  >登入</a
                >
              </li>

              <li class="nav-item waves-light text-center" mdbRippleRadius>
                <a
                  class="nav-link"
                  id="logout"
                  (click)="navbar.hide(); logout()"
                  *ngIf="auth.user$ | async"
                  #authenticated
                  >登出</a
                >
              </li>-->
        <!-- <li class=" nav-item waves-light nav-filler"><a class="nav-link"></a></li> -->
        <!-- </ul> -->
      </div>
    </div>
    <div class="row" *ngIf="goSearch && stores$ | async as stores">
      <form class="form-inline md-form speech-bubble mt-0">
        <input
          [(ngModel)]="storeNameSelected"
          class="form-control form-control-sm mr-3 w-75 bb-n"
          type="text"
          list="browsers"
          placeholder="請輸入營地名稱？"
          aria-label="Search"
          name="storeNameSearch"
          (change)="onstoreNameSelectedChange($event)"
          mdbInput
        />
        <datalist id="browsers">
          <option
            id="{{ sn.store_name }}"
            value="{{ sn.store_alias }}"
            *ngFor="let sn of stores"
          ></option>
        </datalist>
        <mdb-icon
          (click)="routeStore()"
          fas
          icon="search"
          aria-hidden="true"
          class="grey-text"
        ></mdb-icon>
      </form>
    </div>
  </mdb-navbar>
  <!-- Sidebar navigation -->
  <mdb-side-nav #sidenav class="white" [fixed]="false">
    <!-- Logo -->
    <div class="grey-text mt-2 pl-125">
      <!-- <a routerLink="/" (click)="sidenav.hide()"
        ><img
          src="/assets/images/icamping.png"
          class="logo1 img-fluid flex-center"
      /></a>
       <a href="https://iglamping.tw/" target="_blank"
        ><img src="/assets/images/cva.png" class="logo1 img-fluid flex-center"
      /></a>-->
      <h5 class="h5-responsive">區域搜尋</h5>
    </div>
    <!--/. Logo -->
    <!-- Side navigation links -->
    <mdb-accordion [multiple]="true">
      <mdb-accordion-item [collapsed]="false">
        <mdb-accordion-item-head>
          <div class="collapse-header">北部</div>
        </mdb-accordion-item-head>
        <mdb-accordion-item-body>
          <div class="row">
            <a
              mdbBtn
              type="button"
              mdbWavesEffect
              (click)="sidenav.hide(); goStoreSearchResult('新北')"
              class="m-0 mr-1 mb-1 f-b-btn i-green-bg text-white col"
            >
              新北
            </a>
            <a
              mdbBtn
              type="button"
              mdbWavesEffect
              (click)="sidenav.hide(); goStoreSearchResult('桃園')"
              class="m-0 mr-1 mb-1 f-b-btn i-green-bg text-white col"
            >
              桃園
            </a>
          </div>
          <div class="row">
            <a
              mdbBtn
              type="button"
              mdbWavesEffect
              (click)="sidenav.hide(); goStoreSearchResult('新竹')"
              class="m-0 mr-1 mb-1 f-b-btn i-green-bg text-white col"
            >
              新竹
            </a>
            <a class="m-0 mr-1 mb-1 f-b-btn col"></a>
          </div>
          <!-- <ul>
            <li (click)="sidenav.hide(); goStoreSearchResult('台北')">台北</li>
            <li (click)="sidenav.hide(); goStoreSearchResult('新北')">新北</li>
            <li (click)="sidenav.hide(); goStoreSearchResult('基隆')">基隆</li>
            <li (click)="sidenav.hide(); goStoreSearchResult('桃園')">桃園</li>
            <li (click)="sidenav.hide(); goStoreSearchResult('新竹')">新竹</li>
            <li (click)="sidenav.hide(); goStoreSearchResult('苗栗')">苗栗</li>
          </ul>-->
        </mdb-accordion-item-body>
      </mdb-accordion-item>

      <mdb-accordion-item [collapsed]="false">
        <mdb-accordion-item-head>
          <div class="collapse-header">中部</div>
        </mdb-accordion-item-head>
        <mdb-accordion-item-body>
          <div class="row">
            <a
              mdbBtn
              type="button"
              mdbWavesEffect
              (click)="sidenav.hide(); goStoreSearchResult('苗栗')"
              class="m-0 mr-1 mb-1 f-b-btn i-green-bg text-white col"
            >
              苗栗
            </a>
            <a
              mdbBtn
              type="button"
              mdbWavesEffect
              (click)="sidenav.hide(); goStoreSearchResult('台中')"
              class="m-0 mr-1 mb-1 f-b-btn i-green-bg text-white col"
            >
              台中
            </a>
          </div>
          <div class="row">
            <a
              mdbBtn
              type="button"
              mdbWavesEffect
              (click)="sidenav.hide(); goStoreSearchResult('南投')"
              class="m-0 mr-1 mb-1 f-b-btn i-green-bg text-white col"
            >
              南投
            </a>
            <a
              mdbBtn
              type="button"
              mdbWavesEffect
              (click)="sidenav.hide(); goStoreSearchResult('彰化')"
              class="m-0 mr-1 mb-1 f-b-btn i-green-bg text-white col"
            >
              彰化
            </a>
          </div>
          <!--<ul>
            <li (click)="sidenav.hide(); goStoreSearchResult('台中')">台中</li>
            <li (click)="sidenav.hide(); goStoreSearchResult('彰化')">彰化</li>
            <li (click)="sidenav.hide(); goStoreSearchResult('南投')">南投</li>
            <li (click)="sidenav.hide(); goStoreSearchResult('雲林')">雲林</li>
          </ul>-->
        </mdb-accordion-item-body>
      </mdb-accordion-item>

      <mdb-accordion-item [collapsed]="false">
        <mdb-accordion-item-head>
          <div class="collapse-header">南部</div>
        </mdb-accordion-item-head>
        <mdb-accordion-item-body>
          <div class="row">
            <a
              mdbBtn
              type="button"
              mdbWavesEffect
              (click)="sidenav.hide(); goStoreSearchResult('嘉義')"
              class="m-0 mr-1 mb-1 f-b-btn i-green-bg text-white col"
            >
              嘉義
            </a>
            <a
              mdbBtn
              type="button"
              mdbWavesEffect
              (click)="sidenav.hide(); goStoreSearchResult('高雄')"
              class="m-0 mr-1 mb-1 f-b-btn i-green-bg text-white col"
            >
              高雄
            </a>
          </div>
          <div class="row">
            <a
              mdbBtn
              type="button"
              mdbWavesEffect
              (click)="sidenav.hide(); goStoreSearchResult('屏東')"
              class="m-0 mr-1 mb-1 f-b-btn i-green-bg text-white col"
            >
              屏東
            </a>
            <a class="m-0 mr-1 mb-1 f-b-btn col"></a>
          </div>
          <!-- <ul>
            <li (click)="sidenav.hide(); goStoreSearchResult('嘉義')">嘉義</li>
            <li (click)="sidenav.hide(); goStoreSearchResult('台南')">台南</li>
            <li (click)="sidenav.hide(); goStoreSearchResult('高雄')">高雄</li>
            <li (click)="sidenav.hide(); goStoreSearchResult('屏東')">屏東</li>
          </ul>-->
        </mdb-accordion-item-body>
      </mdb-accordion-item>
      <mdb-accordion-item [collapsed]="false">
        <mdb-accordion-item-head>
          <div class="collapse-header">東部</div>
        </mdb-accordion-item-head>
        <mdb-accordion-item-body>
          <div class="row">
            <a
              mdbBtn
              type="button"
              mdbWavesEffect
              (click)="sidenav.hide(); goStoreSearchResult('宜蘭')"
              class="m-0 mr-1 mb-1 f-b-btn i-green-bg text-white col"
            >
              宜蘭
            </a>
            <a
              mdbBtn
              type="button"
              mdbWavesEffect
              (click)="sidenav.hide(); goStoreSearchResult('花蓮')"
              class="m-0 mr-1 mb-1 f-b-btn i-green-bg text-white col"
            >
              花蓮
            </a>
          </div>
          <div class="row">
            <a
              mdbBtn
              type="button"
              mdbWavesEffect
              (click)="sidenav.hide(); goStoreSearchResult('台東')"
              class="m-0 mr-1 mb-1 f-b-btn i-green-bg text-white col"
            >
              台東
            </a>
            <a class="m-0 mr-1 mb-1 f-b-btn col"></a>
          </div>
          <!--<ul>
            <li (click)="sidenav.hide(); goStoreSearchResult('宜蘭')">宜蘭</li>
            <li (click)="sidenav.hide(); goStoreSearchResult('花蓮')">花蓮</li>
            <li (click)="sidenav.hide(); goStoreSearchResult('台東')">台東</li>
          </ul>-->
        </mdb-accordion-item-body>
      </mdb-accordion-item>
      <mdb-accordion-item [collapsed]="false">
        <mdb-accordion-item-head>
          <div class="collapse-header">離島</div>
        </mdb-accordion-item-head>
        <mdb-accordion-item-body>
          <div class="row">
            <a
              mdbBtn
              type="button"
              mdbWavesEffect
              (click)="sidenav.hide(); goStoreSearchResult('金門')"
              class="m-0 mr-1 mb-1 f-b-btn i-green-bg text-white col"
            >
              金門
            </a>
            <a
              mdbBtn
              type="button"
              mdbWavesEffect
              (click)="sidenav.hide(); goStoreSearchResult('澎湖')"
              class="m-0 mr-1 mb-1 f-b-btn i-green-bg text-white col"
            >
              澎湖
            </a>
          </div>
        </mdb-accordion-item-body>
      </mdb-accordion-item>
    </mdb-accordion>
    <!--/. Side navigation links -->
    <div class="f-b">
      <div class="row pl-125">
        <a
          mdbBtn
          type="button"
          mdbWavesEffect
          routerLink="/camp/search"
          (click)="sidenav.hide()"
          class="m-0 mr-1 mb-1 f-b-btn i-green-d-bg text-white"
        >
          快速訂位
        </a>
        <a
          mdbBtn
          type="button"
          mdbWavesEffect
          routerLink="/store/search"
          (click)="sidenav.hide()"
          class="m-0 mr-1 mb-1 f-b-btn i-green-d-bg text-white"
        >
          營地搜尋
        </a>
        <!-- <a
          mdbBtn
          type="button"
          mdbWavesEffect
          routerLink=""
          (click)="sidenav.hide()"
          class="m-0 mr-1 mb-1 f-b-btn i-green-d-bg text-white"
        >
          露營裝備
        </a> -->
      </div>

      <div class="row pl-125">
        <!-- <a
          mdbBtn
          type="button"
          mdbWavesEffect
          routerLink=""
          class="m-0 mr-1 mb-1 f-b-btn i-green-bg text-white"
        >
          豪華露營
        </a> -->
        <a
          mdbBtn
          type="button"
          mdbWavesEffect
          class="m-0 mr-1 mb-1 f-b-btn i-green-d-bg text-white"
          routerLink="/hot-search"
          (click)="sidenav.hide()"
        >
          熱門搜尋
        </a>
        <!--<a
          mdbBtn
          type="button"
          mdbWavesEffect
          class="m-0 mr-1 mb-1 f-b-btn i-green-bg text-white"
          routerLink="/icamping-intro"
          (click)="sidenav.hide()"
        >
          聯絡我們
        </a>-->
      </div>
    </div>
  </mdb-side-nav>
  <!--/. Sidebar navigation -->
</header>
<!--Main Navigation-->

<div class="mt-90"></div>
<!--Search Form-->

<!-- Routed views go here -->
<mdb-progress-bar
  db-progress-bar
  *ngIf="pageLoadingIcon"
  class="progress"
  mode="indeterminate"
>
</mdb-progress-bar>
<!-- pageLoadingIcon:{{ pageLoadingIcon}} -->
<div class="container-fluid">
  <router-outlet (activate)="onScrollContentToTop($event)"></router-outlet>
</div>

<div class="footer mb-60">
  <app-footer class="smallnoshow"></app-footer>
</div>
