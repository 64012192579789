<app-loading-spinner *ngIf="showSpinner"></app-loading-spinner>

<div
  *ngIf="caseModelMetadata"
  [ngClass]="{ 'case-model': hideCaseModel == true }"
  class="pb-3 px-0"
>
  <div class="row sidebooking" *ngIf="atleastOneStuff || reSearching">
    <div class="col-lg-5 col-md-4 col md-form">
      <mdb-date-picker
        id="checkin_date"
        name="checkin_date"
        [(ngModel)]="checkinDate"
        inlineIcon="far fa-calendar-check prefix grey-text"
        [inline]="true"
        [options]="stuffSearchCalOption"
        [placeholder]="'抵達日期'"
        (dateChanged)="onDateChange($event)"
      ></mdb-date-picker>
    </div>
    <div class="col-lg-3col-md-4 col md-form">
      <mdb-select
        [options]="optionsSelect"
        placeholder="住幾個晚上"
        class="w-100"
        [(ngModel)]="stay"
        (ngModelChange)="onStayChange($event)"
      ></mdb-select>
      <!-- <input
        [mdbValidation]="false"
        mdbInput
        [(ngModel)]="stay"
        value="stay"
        type="number"
        min="1"
        max="5"
        id="stay"
        class="form-control ml-1 pl-3"
        (change)="onStayChange($event)"
        mdbActive
      />
      <label for="stay" class="pl-25"> 住幾個晚上</label> -->
      <div class="red-text center" for="stay" *ngIf="stay > 1">
        大於1晚的訂位將無法修改天數、區域
      </div>
    </div>
    <button
      mdbBtn
      type="button"
      color="info"
      class="btn btn-success col-lg-2 col-md-2 col mr-2 mt-4 height-fit"
      mdbRippleRadius
      (click)="reSearchIndex()"
      [disabled]="!reSearch"
    >
      重新查詢
    </button>
    <button
      mdbBtn
      type="button"
      color="info"
      class="btn btn-success col-lg-2 col-md-2 col mr-2 mt-4 height-fit"
      mdbRippleRadius
      (click)="goStore()"
    >
      營區完整資訊
    </button>
    <!-- <div class="col-lg-2 col-md-2 col mr-2 mt-4">
      
    </div> -->
    <!-- <div class="col-lg-2 col-md-2 col mr-2 mt-4">
      
    </div> -->
  </div>

  <div class="small-width" *ngIf="atleastOneStuff || reSearching">
    <div class="row">
      <div class="w-100 md-form">
        <mdb-date-picker
          id="checkin_date"
          name="checkin_date"
          [(ngModel)]="checkinDate"
          inlineIcon="far fa-calendar-check prefix grey-text"
          [inline]="true"
          [options]="stuffSearchCalOption"
          [placeholder]="'抵達日期'"
          (dateChanged)="onDateChange($event)"
        ></mdb-date-picker>
      </div>
    </div>
    <div class="row">
      <div class="w-100 md-form">
        <mdb-select
          [options]="optionsSelect"
          placeholder="住幾個晚上"
          class="w-100"
          [(ngModel)]="stay"
          (ngModelChange)="onStayChange($event)"
        ></mdb-select>
        <!-- <input
          [mdbValidation]="false"
          mdbInput
          [(ngModel)]="stay"
          value="stay"
          type="number"
          min="1"
          max="5"
          id="stay"
          class="w-100 form-control"
          (change)="onStayChange($event)"
          mdbActive
        />
        <i class="fas fa-bed prefix yoyo grey-text"></i>
        <label class="hardfix-text" for="inputIconEx2">住幾個晚上</label> -->

        <div class="red-text center" for="stay" *ngIf="stay > 1">
          大於1晚的訂位將無法修改天數、區域
        </div>
      </div>
    </div>
    <div class="row">
      <button
        mdbBtn
        type="button"
        color="info"
        id="case-create"
        class="btn btn-success ma-center col"
        mdbRippleRadius
        (click)="reSearchIndex()"
        [disabled]="!reSearch"
      >
        重新查詢
      </button>
      <button
        mdbBtn
        type="button"
        color="info"
        id="store-go"
        class="btn btn-success ma-center col"
        mdbRippleRadius
        (click)="goStore()"
      >
        營區完整資訊
      </button>
    </div>
  </div>

  <h4 class="h4-responsive mt-4" *ngIf="birdEyeViewImage">營區圖</h4>
  <div class="row" *ngIf="birdEyeViewImage">
    <div class="col mb-4 px-2 px-0">
      <div
        class="view overlay rounded z-depth-1 waves-light h-max"
        *ngIf="atleastOneStuff"
      >
        <img
          class="card-img-top"
          src="{{ birdEyeViewImage.thumbnail_600c_url }}"
          alt=""
        />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-9 col-12 px-0" *ngIf="!atleastOneStuff">
      <!-- <h4 class="h4-responsive">目前沒有可提供的營地喔！</h4> -->
    </div>
    <div class="col-lg-9 col-12 px-0" *ngIf="atleastOneStuff">
      <h4 class="h4-responsive mt-4">當天空位狀況</h4>
      <!-- Grid row -->
      <div class="row">
        <!-- Grid column -->
        <div
          class="col-lg-3 col-sm-6 col-12 mb-4 px-2 px-0"
          *ngFor="let result of searchResults; index as i"
        >
          <div
            class="view overlay rounded z-depth-1 waves-light h-max"
            *ngIf="atleastOneStuff"
          >
            <img class="card-img-top" src="{{ result.stuff.image }}" alt="" />
          </div>
          <mdb-card-body class="mt-1">
            <h5 class="h5-responsive">
              <strong>{{ result.stuff_name }}</strong>
            </h5>
            <div class="grey-text" [innerHtml]="result.stuff.description"></div>
            <div class="text-default">
              每{{ result.stuff.unit }}
              {{ result.stuff_price | currency: "TWD":number:"1.0-0" }}
            </div>
            <div class="text-default">
              總共 <strong>{{ result.default_max_total }}</strong
              >{{ result.stuff.unit }}
            </div>
            <div class="text-default">
              剩餘
              <strong class="balance" id="balance-{{ result.stuff_name }}">{{
                result.rest_quantity
              }}</strong
              >{{ result.stuff.unit }}可訂
            </div>
            <div *ngIf="bookable">
              <div class="row">
                <div class="col-6 px-1">
                  <a
                    *ngIf="result.rest_quantity > 0"
                    id="increase-{{ result.stuff_name }}"
                    class="increase btn btn-sm btn-default waves-light w-100"
                    mdbRippleRadius
                    (click)="addUnit(searchResults, i)"
                  >
                    <i class="fas fa-plus"></i>
                  </a>
                </div>
                <div class="col-6 px-1">
                  <a
                    *ngIf="result.original_rest_quantity > result.rest_quantity"
                    id="decrease-{{ result.stuff_name }}"
                    class="decrease btn btn-sm btn-blue-grey waves-light w-100"
                    mdbRippleRadius
                    (click)="subtractUnit(searchResults, i)"
                  >
                    <i class="fas fa-minus"></i>
                  </a>
                </div>
              </div>
              <div class="row">
                <a
                  *ngIf="!result.queue && result.original_rest_quantity <= 0"
                  class="btn i-green-bg waves-light w-100"
                  mdbRippleRadius
                  (click)="addQueue(i)"
                >
                  已客滿 點選空位通知
                </a>
              </div>
              <div class="row">
                <a
                  *ngIf="result.queue && result.original_rest_quantity <= 0"
                  class="btn btn-info waves-light w-100"
                  mdbRippleRadius
                >
                  已加入排隊
                </a>
              </div>
            </div>
            <div *ngIf="!bookable">
              <a
                class="btn btn-warning waves-light w-100"
                href="tel:{{ store_tel }}"
                mdbRippleRadius
              >
                立即撥打電話訂位 {{ store_tel }}</a
              >
            </div>
          </mdb-card-body>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </div>

    <!-- caseModelMetadata: {{ caseModelMetadata | json }} -->
    <div class="col-lg-3 sidebooking scroll" *ngIf="atleastOneStuff">
      <div class="mt">
        <h4 class="h4-responsive">訂購細項</h4>
        <!-- Section description -->
        <div class="p5">
          <h5 class="i-green">基本資訊</h5>
          <div>✧ 入住營區：{{ caseModelMetadata.store_alias }}</div>
          <div>
            ✧ 入營日期：{{ caseModelMetadata.checkin_date | utcDateFormat }}
          </div>
          <div>
            ✧ 退營日期：{{ caseModelMetadata.checkout_date | utcDateFormat }}
          </div>
        </div>
        <hr />

        <div *ngIf="(caseModel | json) != ({} | json)">
          <div class="p5">
            <h5 class="i-green">訂購項目</h5>
            <div class="row">
              <div class="col">
                <div
                  class="text-left"
                  *ngFor="let item of caseModel | keyvalue"
                >
                  <div class="grey-text">
                    品項： {{ item.key }}
                    <div class="grey-text">
                      數量： {{ item.value[0] }}
                      <div>
                        <div class="grey-text">
                          價錢：
                          {{ item.value[1] | currency: "TWD":number:"1.0-0" }}
                        </div>
                      </div>
                    </div>

                    <hr />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="p5 col">
                <div class="grey-text" id="total-qty">總數：{{ totalQty }}</div>
                <div>
                  <div class="grey-text">
                    訂位手續費：{{ serviceFee }}
                  </div>
                </div>
                <div class="default-text" id="total-price">
                  總價錢：{{ totalPrice | currency: "TWD":number:"1.0-0" }}
                </div>
                <div class="red-text">
                  送出訂單後，將無法部分變更天數、日期、區域，如需換區，訂位者必須負擔取消資料處理費，訂單送出前務必雙重確認區域及數量。
                </div>
              </div>
              <button
                type="button"
                class="btn i-green-bg white-text mb-3 waves-light align-middle booking-btn w-100"
                id="case-create-modal"
                (click)="caseCreateModal.show()"
              >
                送出訂單
              </button>
            </div>
          </div>

          <!-- Section: Pricing v.5 -->
        </div>
      </div>
    </div>
    <!-- 螢幕小於 768px 出現 -->
    <div class="row footerbooking">
      <button
        *ngIf="(caseModel | json) != ({} | json)"
        type="button"
        class="btn i-green-bg waves-light w-100 py-3 white-text"
        data-toggle="modal"
        data-target="#basicExample"
        (click)="fluid.show()"
        mdbWavesEffect
      >
        前往結帳 - 總數量：{{ totalQty }}
      </button>
      <div
        mdbModal
        #fluid="mdb-modal"
        class="modal fade right scroll"
        id="fullHeightModalRight"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-full-height modal-right" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close pull-right"
                aria-label="Close"
                (click)="fluid.hide()"
              >
                <span aria-hidden="true">×</span>
              </button>
              <h4 class="h4-responsive mx-5">訂購細項</h4>
            </div>
            <div class="modal-body">
              <!-- Section description -->
              <div class="p5">
                <h5 class="i-green">基本資訊</h5>
                <div>✧ 入住營區：{{ caseModelMetadata.store_alias }}</div>
                <div>
                  ✧ 入營日期：{{
                    caseModelMetadata.checkin_date | utcDateFormat
                  }}
                </div>
                <div>
                  ✧ 退營日期：{{
                    caseModelMetadata.checkout_date | utcDateFormat
                  }}
                </div>
              </div>
              <hr />
              <div class="p5">
                <h5 class="i-green">訂購項目</h5>
                <div
                  class="section-description"
                  *ngFor="let item of caseModel | keyvalue"
                >
                  <div class="grey-text">品項： {{ item.key }}</div>
                  <div class="grey-text">數量： {{ item.value[0] }}</div>
                  <div class="grey-text">
                    價錢：
                    {{ item.value[1] | currency: "TWD":number:"1.0-0" }}
                  </div>
                </div>
              </div>
              <hr />
              <div class="p5">
                <div class="grey-text" id="total-qty">總數：{{ totalQty }}</div>
                <div>
                  <div class="grey-text">
                    訂位手續費：{{ serviceFee }}
                  </div>
                </div>
                <div class="default-text" id="total-price">
                  總價錢：{{ totalPrice | currency: "TWD":number:"1.0-0" }}
                </div>
                <div *ngIf="totalQty > 0" class="red-text">
                  送出訂單後，將無法部分變更天數、日期、區域，如需換區，訂位者必須負擔取消資料處理費，訂單送出前務必雙重確認區域及數量。
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                class="btn i-green-bg w-100"
                (click)="fluid.hide(); footerCaseCreateModal.show()"
              >
                送出訂單
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        mdbModal
        #footerCaseCreateModal="mdbModal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="footerCaseCreateModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close pull-right"
                aria-label="Close"
                (click)="footerCaseCreateModal.hide()"
              >
                <span aria-hidden="true">×</span>
              </button>
              <h4 class="h4-responsive">確認訂單?</h4>
            </div>
            <div class="modal-body text-left">
              <div class="agreement-overflow" [innerHtml]="agreementDesc"></div>
            </div>
            <div class="modal-footer">
              <div class="row">
                <div class="col-sm-6 col-12 pl-0">
                  <button
                    type="button"
                    [disabled]="clicked"
                    mdbBtn
                    id="case-create"
                    class="relative waves-light w-100 i-green-bg"
                    (click)="
                      caseCreate(caseModelMetadata, caseModel);
                      caseCreateModal.hide()
                    "
                  >
                    我同意以上條款並確認此訂單
                  </button>
                </div>
                <div class="col-sm-6 col-12 pl-0">
                  <button
                    id="fuck"
                    type="button"
                    mdbBtn
                    class="waves-light w-100 i-green-l-bg"
                    aria-label="Close"
                    (click)="footerCaseCreateModal.hide()"
                  >
                    不同意
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      mdbModal
      #caseCreateModal="mdbModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="caseCreateModalLabel"
      aria-hidden="true"
      [config]="{ backdrop: false, ignoreBackdropClick: true }"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close pull-right"
              aria-label="Close"
              (click)="caseCreateModal.hide()"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="h4-responsive">確認訂單?</h4>
          </div>
          <div class="modal-body">
            <div class="agreement-overflow" [innerHtml]="agreementDesc"></div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="col btn btn-danger"
              aria-label="Close"
              mdbRippleRadius
              (click)="caseCreateModal.hide()"
            >
              不同意
            </button>
            <button
              type="button"
              id="case-create"
              class="btn i-green-bg"
              mdbRippleRadius
              (click)="
                caseCreate(caseModelMetadata, caseModel); caseCreateModal.hide()
              "
              [disabled]="bookingSubmit"
            >
              我同意以上條款並確認此訂單
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
