import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  promptEvent: any;

  constructor() {
    window.addEventListener('beforeinstallprompt', event => {
      console.log('app install 視窗跳出來前 阻止它!!');
      event.preventDefault();
      this.promptEvent = event;
      return false;
    });
  }
}
