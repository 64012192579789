import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BookingService } from '../service/booking.service';

@Component({
  selector: 'icamping-rule',
  templateUrl: './icamping-rule.component.html',
  styleUrls: ['./icamping-rule.component.scss']
})
export class IcampingRuleComponent implements OnInit {
  showSpinner: boolean;
  policyDesp: any = '';

  constructor(
    private _bookingService: BookingService,
    private sanitizer: DomSanitizer,
  ) {}

  // 強制把Froala Editor的 Powered by訊息給隱藏起來！
  // 注意：ngAfterViewInit() 沒有用！
  ngAfterViewChecked() {
    $('icamping-rule > div > p:nth-child(13)').hide();
    // document.querySelector("body > app-root > div.container-fluid > icamping-rule > div > p:nth-child(13)")
  }

  ngOnInit() {
    this.showSpinner = true;
    this._bookingService.ruleGet().subscribe((result: any) => {
      this.policyDesp = this.sanitizer.bypassSecurityTrustHtml(result.item.policy_description);
      this.showSpinner = false;
    });
  }

}
