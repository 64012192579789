<div class="text-center pb-3 mt2r">
  <!-- Card -->

  <div class="card testimonial-card ">
    <!-- Bacground color -->
    <div class="card-up indigo lighten-1"></div>

    <div *ngIf="this.auth.user$ | async as user; else loading">
      <!-- User: {{ user.photoURL | json }} -->

      <!-- Avatar -->
      <div class="avatar mx-auto white">
        <img src="{{ user.photoURL }}" class="rounded-circle" />
      </div>

      <div class="card-body">
        <!-- Name -->
        <!--
          <h4 class="card-title">{{ user.displayName }}:: {{ this.auth.user$ | async | json}}</h4>
        -->
        <h4 class="card-title">{{ user.displayName }}</h4>
        <hr />
        <app-loading-spinner *ngIf="showSpinner"> </app-loading-spinner>

        <!-- Quotation -->

        <!-- Card -->
        <div class="row" *ngIf="showTmpl">
          <!-- Card body -->
          <div class="card-body">
            <div
              *ngIf="user.verified; then isVerifiedTmpl; else isNotVerifiedTmpl"
            ></div>

            <ng-template #isVerifiedTmpl>
              <p class="h4 text-center py-4">已完成手機驗證</p>

              <!-- Material input text -->
              <div *ngIf="user.verified; then Home" class="col-md-12"></div>
              <ng-template #Home>
                <!-- <a class="btn btn-outline-purple" id="home" (click)="home()">
                  開始使用
                </a> -->
                <a class="btn btn-outline-purple" id="home" (click)="keepBooking()">
                  繼續我的訂位流程
                </a>
              </ng-template>
            </ng-template>

            <ng-template #isNotVerifiedTmpl>
              <p class="h4 text-center py-4">
                <i class="fa fa-mobile-phone fa-3x prefix grey-text"></i>
              <!-- <i class="fa fa-exclamation-triangle prefix grey-text"></i> -->
                請輸入手機號碼完成綁定
                <!-- 請輸入手機號碼完成最後驗證 -->
              </p>

              <!-- Material input text -->
              <div class="md-form">
                <input
                  type="tel"
                  autofocus
                  class="form-control w-100"
                  placeholder="手機號碼"
                  [(ngModel)]="phoneNumber"
                />
              </div>

              <div class="md-form">
                <input
                  type="tel"
                  autofocus
                  class="form-control w-100"
                  placeholder="確認手機號碼"
                  [(ngModel)]="phoneNumberCheck"
                />
              </div>
              <p *ngIf="phoneNumberCheck.length > 0 && phoneNumber != phoneNumberCheck" class="h5 text-center py-4">
                <i class="fa fa-exclamation-triangle prefix red-text"></i>
                與手機號碼不相符
              </p>

              <div class="col-md-12">
                <div class="text-center py-4 mt-3">
                  <button
                    mdbBtn
                    type="button"
                    color="purple"
                    [disabled]="(phoneNumber.length < 7 || phoneNumber != phoneNumberCheck) || isDisabled"
                    size="lg"
                    (click)="userPhoneNumberVerify(phoneNumber)"
                    mdbWavesEffect
                  >
                    綁定我的手機
                  </button>
                </div>
              </div>
            </ng-template>
          </div>
          <!-- Card body -->
        </div>
        <!-- Card -->
      </div>
    </div>

    <ng-template #loading></ng-template>
  </div>
  <!-- Card -->
</div>
