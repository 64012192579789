import { AuthService } from "../core/auth.service";
import { CarouselService } from "../service/carousel.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { HomeService } from "../service/home.service";
import { ModalDirective } from "ng-uikit-pro-standard";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { StoreService } from "../service/store.service";
import { UserService } from "../service/user.service";
import * as _ from "lodash";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  @ViewChild("basicModal") basicModal: ModalDirective;
  // prettier-ignore
  showAppMenu(): void { this._homeService.showMenu$.emit() }

  areas: any;
  carousels$: Observable<any[]>;
  carouselsLaptop$: Observable<any[]>;
  conditions: any;
  experiences$: any;
  facilities: any;
  keepRefreshStores: any;
  objectKeys = Object.keys;
  phone_account_migration_redirect_url: string;
  stores$: any;
  storeTop$: any;
  subjects: Array<any> = [];
  tags: any;
  x = 0;

  constructor(
    private auth: AuthService,
    private router: Router,
    private _homeService: HomeService,
    private _userService: UserService,
    private _storeService: StoreService,
    private _imageService: CarouselService
  ) {}

  ngOnInit() {
    const filter = this._homeService.getStoreFilter();
    const tagFilter = this._homeService.getExperienceFilter().tagFilter;
    this.areas = filter.areaFilter;
    this.conditions = filter.conditionFilter;
    this.facilities = filter.facilityFilter;
    this.tags = tagFilter;
    this.storeTop$ = this._homeService.getStoreTops();
    this.stores$ = this._homeService.getStores();
    this.experiences$ = this._homeService.getExperience();
    this.carousels$ = this._imageService.list();
    this.carouselsLaptop$ = this._imageService.listLaptop();

    this.keepRefreshStores = setInterval(() => {
      this.x++;
      if (this.x > 180) {
        clearInterval(this.keepRefreshStores);
      }
    }, 1000);
  }

  ngAfterViewInit() {
    // listen for auth state changes after logged in.
    this.auth.user$.subscribe((user) => {
      if (user && user.providerId === "phone") {
        console.log("Going to redirect to migration page or abort:", user);
        let has_phone_account_migration = window.sessionStorage.getItem(
          "has_phone_account_migration"
        );
        this.phone_account_migration_redirect_url =
          window.sessionStorage.getItem("phone_account_migration_redirect_url");

        console.log(has_phone_account_migration);
        console.log(this.phone_account_migration_redirect_url);

        if (has_phone_account_migration === "true") {
          console.log(this.phone_account_migration_redirect_url);
          this.basicModal.show();
        }
      }
    });
  }

  goNoAuthRedirect() {
    // Redirect the entire page to the constructed URL
    window.sessionStorage.removeItem("has_phone_account_migration");
    window.sessionStorage.removeItem("phone_account_migration_redirect_url");
    window.location.href = this.phone_account_migration_redirect_url;
  }

  NoGoAuthRedirect() {
    window.sessionStorage.removeItem("has_phone_account_migration");
    window.sessionStorage.removeItem("phone_account_migration_redirect_url");
    this.basicModal.hide();
  }

  subscribeStore(store) {
    if (!this.auth.userLoggedin) {
      this.router.navigate(["/login"]);
      return;
    }

    store.user_like = !store.user_like;
    if (store.user_like) {
      this._storeService.pushLikeStoreName(store.store_name);
    } else {
      this._storeService.removeLikeStoreName(store.store_name);
    }
    this._userService
      .userLikeUpdate(store.store_name, !store.user_like)
      .subscribe((resp: any) => {
        this._storeService.getUserLikedStores();
      });
  }

  onFacilityChange(e) {
    const index = e.element.tabIndex;
    if (index !== 0) {
      this.facilities["全選"] = false;
    } else {
      Object.keys(this.facilities).forEach((k) => {
        if (k !== "全選") {
          this.facilities[k] = false;
        }
      });
    }
    this._homeService.setStoreFilter(
      this.areas,
      this.conditions,
      this.facilities
    );
    this.x++;
  }

  onAreaChange(e) {
    this._homeService.setStoreFilter(
      this.areas,
      this.conditions,
      this.facilities
    );
    this.x++;
  }

  onConditionChange(e) {
    const index = e.element.tabIndex;
    if (index !== 0) {
      this.conditions["全選"] = false;
    } else {
      Object.keys(this.conditions).forEach((k) => {
        if (k !== "全選") {
          this.conditions[k] = false;
        }
      });
    }
    this._homeService.setStoreFilter(
      this.areas,
      this.conditions,
      this.facilities
    );
    this.x++;
  }

  goStoreSearchResult(resetFilter = false, slogan = "") {
    if (resetFilter) {
      this._homeService.setAllStoreFilter();
    }
    this._homeService.clearStoreCityFilter();
    this._homeService.setDefaultExperienceFilter();
    this._homeService.setStoreSrearchResultSlogan(slogan);
    this.router.navigate(["/store/search-result"]);
  }

  goStoreSearchResultForExperience(name: string, tags = [], cities = []) {
    console.log(tags);
    console.log(cities);
    this._homeService.setExperienceFilter(tags, cities);
    this._homeService.setStoreSrearchResultSlogan(name);
    this.router.navigate(["/store/search-result"]);
  }

  areaRemove(a: string) {
    return a.replace("營區", "");
  }
}
