import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-me-trip",
  templateUrl: "./trip.component.html",
  styleUrls: ["./trip.component.scss"],
})
export class MeTripComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    window.sessionStorage.setItem("urlBeforeLogin", "/me/trip");
  }
}
