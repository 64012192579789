import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../../core/auth.service";
import { BookingService } from "../../../service/booking.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { HttpErrorResponse } from "@angular/common/http";
import { StoreService } from "../../../service/store.service";
import { ToastService, ModalDirective } from "ng-uikit-pro-standard";
import { IMyOptions } from "ng-uikit-pro-standard";
import * as moment from "moment";
import * as _ from "lodash";

@Component({
  selector: "app-stuff-search-result",
  templateUrl: "./search-result.component.html",
  styleUrls: ["./search-result.component.scss"],
})
export class StoreStuffSearchResultComponent implements OnInit {
  @ViewChild("caseCreateModal") public caseCreateModal: ModalDirective;
  @ViewChild("footerCaseCreateModal")
  public footerCaseCreateModal: ModalDirective;

  _dayBefore = moment().utcOffset("Asia/Taipei").subtract(1, "days");
  dayBefore = this._dayBefore.toDate();
  yearAfter = this._dayBefore.add(1, "years").add(1, "days").toDate();
  clicked = false;
  birdEyeViewImage: any;
  closeDate: Date;
  openDate: Date;
  checkinDate = "";
  stay: any;
  user: any;
  hideCaseModel: boolean;
  weekdayOpen: Array<string>;

  holidayList: Array<any>;
  cnyList: Array<any>;
  reSearch = false;
  reSearching = false;

  optionsSelect = [
    { value: "1", label: "1晚" },
    { value: "2", label: "2晚" },
    { value: "3", label: "3晚" },
    { value: "4", label: "4晚" },
    { value: "5", label: "5晚" },
  ];

  stuffSearchCalOption: IMyOptions = {
    closeAfterSelect: true,
    showTodayBtn: false,
    showClearDateBtn: false,
    // Strings and translations
    dayLabels: {
      su: "日",
      mo: "一",
      tu: "二",
      we: "三",
      th: "四",
      fr: "五",
      sa: "六",
    },
    dayLabelsFull: {
      su: "日",
      mo: "一",
      tu: "二",
      we: "三",
      th: "四",
      fr: "五",
      sa: "六",
    },
    monthLabels: {
      1: "一月",
      2: "二月",
      3: "三月",
      4: "四月",
      5: "五月",
      6: "六月",
      7: "七月",
      8: "八月",
      9: "九月",
      10: "十月",
      11: "十一月",
      12: "十二月",
    },
    monthLabelsFull: {
      1: "一月",
      2: "二月",
      3: "三月",
      4: "四月",
      5: "五月",
      6: "六月",
      7: "七月",
      8: "八月",
      9: "九月",
      10: "十月",
      11: "十一月",
      12: "十二月",
    },

    // // Buttons
    todayBtnTxt: "今日",
    clearBtnTxt: "清除",
    closeBtnTxt: "確認",

    firstDayOfWeek: "mo",
    selectionTxtFontSize: "14px",
  };

  agreementDesc = "";
  atleastOneStuff = false;
  bookable = true;
  bookingSubmit = false;
  searchResults: Array<any> = [];
  stuff: any;
  caseModel: any = {};
  casePrice: any;
  caseModelMetadata: any;
  store_alias: string;
  store_tel: string;
  totalQty = 0;
  totalPrice = 0;
  serviceFee = 0;
  userVerified = true;
  userTel = "";
  showSpinner = false;

  constructor(
    private _bookingService: BookingService,
    private _storeService: StoreService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private toastService: ToastService,
    public auth: AuthService
  ) {}

  // 強制把Froala Editor的 Powered by訊息給隱藏起來！
  // 注意：ngAfterViewInit() 沒有用！
  ngAfterViewChecked() {
    $("mdb-card-body > div.grey-text > p:nth-child(2)").hide();
  }

  ngOnInit() {
    window.sessionStorage.setItem(
      "urlBeforeLogin",
      "/store/stuff/search-result"
    );

    // Boooking info
    const stay = this.route.snapshot.paramMap.get("stay");
    this.stay = stay;
    this.checkinDate = this.route.snapshot.paramMap.get("checkin_date");
    const checkinDateObj = moment(
      this.route.snapshot.paramMap.get("checkin_date")
    ).utcOffset("Asia/Taipei");
    const checkin_date = checkinDateObj.format();
    const checkout_date = checkinDateObj.add(stay, "days").format();
    let stuffIndex = 0;
    const stuffMap = {};
    const store_name = this.route.snapshot.paramMap.get("store_name");

    // check the checkin date is valid.
    if (checkinDateObj.isBefore()) {
      this.router.navigate(["/"]);
      return;
    }

    for (let i = 0; i < this.optionsSelect.length; i++) {
      if (this.optionsSelect[i].value === this.stay) {
        this.optionsSelect[i]["selected"] = true;
      }
    }

    this.auth.user$.subscribe((user: any) => {
      if (!user) {
        return;
      }
      if (!user.verified) {
        this.userVerified = false;
        this.router.navigate(["/profile"]);
      } else {
        this.user = user;
        if (this.user.phoneNumber.startsWith("09")) {
          this.userTel = `+886${this.user.phoneNumber.slice(1)}`;
        } else {
          this.userTel = this.user.phoneNumber;
        }
      }
    });

    this._storeService
      .storeNameGetBirdEyeViewImage(store_name)
      .subscribe((result: any) => {
        console.log(result);
        if (!result) {
          return;
        }
        this.birdEyeViewImage = result[0];
      });

    // Store info
    this._storeService.storeNameGet(store_name).subscribe((store: any) => {
      this.closeDate = moment(store.items.close_date)
        .add(8, "hour")
        .utcOffset("Asia/Taipei")
        .toDate();
      this.openDate = moment(store.items.open_date)
        .add(8, "hour")
        .utcOffset("Asia/Taipei")
        .toDate();
      this.weekdayOpen = store.items.weekday_open;
      const days = this.getBookableDuration();
      const startDate = days[0];
      const endDate = days[1];
      this.stuffSearchCalOption["disableUntil"] = {
        year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
        day: startDate.getDate(),
      };

      this.stuffSearchCalOption["disableSince"] = {
        year: endDate.getFullYear(),
        month: endDate.getMonth() + 1,
        day: endDate.getDate() + 1,
      };

      this.store_alias = store.items.store_alias;
      this.store_tel = store.items.tel_boss;
      this.caseModelMetadata = {
        store_name: store_name,
        store_alias: this.store_alias,
        checkin_date: checkin_date,
        checkout_date: checkout_date,
        user_tel: this.userTel,
      };
    });

    Promise.all([
      this._storeService.stuffStoreNameList(store_name),
      this._bookingService.caseIndexDateRestCount(
        checkin_date,
        checkout_date,
        store_name,
        "1"
      ),
    ]).then((results: any) => {
      const stuffResp = results[0];
      const indexResp = results[1];
      stuffResp.subscribe((stuff: any) => {
        if (!stuff.items) {
          this.showSpinner = false;
          return;
        }

        stuff.items.forEach((stuffObj: any) => {
          const stuffName = stuffObj.name;
          stuffObj["image"] = "";
          stuffMap[stuffName] = stuffObj;
        }); // forEach stuffObj end.

        indexResp.subscribe((res: any) => {
          res.items.forEach((resultObj: any, i) => {
            if (stuffMap[resultObj.stuff_name] !== null) {
              resultObj["stuff"] = stuffMap[resultObj.stuff_name];
              resultObj.description = this.sanitizer.bypassSecurityTrustHtml(
                resultObj.description
              );
              this.atleastOneStuff = true;
            }
            // switch the rearched stuff to the first index
            if (
              resultObj.stuff_name ===
              this.route.snapshot.paramMap.get("stuff_name")
            ) {
              stuffIndex = i;
              console.log(stuffIndex);
            }
            // bind original rest quantity
            resultObj.original_rest_quantity = resultObj.rest_quantity;
          });
          const tmp = res.items[0];
          res.items[0] = res.items[stuffIndex];
          res.items[stuffIndex] = tmp;
          this.showSpinner = false;
          this.searchResults = res.items;
          this.caseModel = {};

          this.searchResults.forEach((result: any) => {
            if (!result.service_fee) {
              result.service_fee = 0;
            }
            if (result.stuff) {
              const stuffName = result.stuff.name;
              this._storeService
                .storeNameGetStuffImageLimit(store_name, stuffName)
                .subscribe((campImages: any) => {
                  this._storeService.hidePageLoading();
                  result.stuff.image = campImages[0].thumbnail_600c_url;
                });
            }
          });
        });
      });
    });

    this._bookingService.ruleGet().subscribe((result: any) => {
      this.agreementDesc = result.item.policy_description;
      // this.serviceFee = parseInt(result.item.service_fee, 0);
      this.holidayList = result.item.holiday_list;
      this.cnyList = result.item.cny_list;
      this.bookable = this.isBookable(
        this.route.snapshot.paramMap.get("checkin_date"),
        result.item.holiday_list,
        result.item.cny_list
      );
    });

    this._bookingService
      .caseIndexCalList(store_name)
      .subscribe((result: any) => {
        const disableDateRanges = [];
        if (!result.cal_dates) {
          return;
        }

        result.cal_dates.forEach((d) => {
          d.day = parseInt(d.day, 10);
          d.month = parseInt(d.month, 10);
          d.year = parseInt(d.year, 10);
          disableDateRanges.push({ begin: d, end: d });
        });
        this.stuffSearchCalOption["markDates"] = [
          {
            dates: result.cal_dates,
            color: "#e51075",
          },
        ];
      });
  }

  onDateChange(e: any): any {
    this.reSearch = true;
    this.atleastOneStuff = false;
    this.reSearching = true;

    if (
      e.actualDateFormatted === undefined ||
      e.actualDateFormatted.length !== 10
    ) {
      this.checkinDate = this.route.snapshot.paramMap.get("checkin_date");
      return null;
    }

    this.bookable = this.isBookable(
      this.checkinDate,
      this.holidayList,
      this.cnyList
    );

    const checkout = moment(
      moment(e.actualDateFormatted).utcOffset("Asia/Taipei")
    )
      .utcOffset("Asia/Taipei")
      .add(this.stay - 1, "day");

    if (checkout.isAfter(this.closeDate)) {
      this.reSearch = false;
      this.atleastOneStuff = true;
      this.reSearching = false;
      this.bookable = this.isBookable(
        this.route.snapshot.paramMap.get("checkin_date"),
        this.holidayList,
        this.cnyList
      );
      alert("入營期間超過店家可接受訂位日，請重新選擇，謝謝！");
    } else if (
      !this.isStoreOpen(moment(e.actualDateFormatted).utcOffset("Asia/Taipei"))
    ) {
      this.reSearch = false;
      this.atleastOneStuff = true;
      this.reSearching = false;
      this.bookable = this.isBookable(
        this.route.snapshot.paramMap.get("checkin_date"),
        this.holidayList,
        this.cnyList
      );
      alert("入營期間涵蓋公休日，請重新選擇，謝謝！");
    }
  }

  isStoreOpen(d: moment.Moment): boolean {
    const holidays = this.holidayList.concat(this.cnyList);

    for (let i = 0; i < this.stay; i++) {
      if (i > 0) {
        d.add(1, "day");
      }

      let wd = d.day() - 1;
      if (d.day() === 0) {
        wd = 6;
      }

      if (
        this.weekdayOpen[wd] === "0" &&
        holidays.indexOf(d.format("YYYY-MM-DD")) === -1
      ) {
        this.stay = this.route.snapshot.paramMap.get("stay");
        this.checkinDate = this.route.snapshot.paramMap.get("checkin_date");
        return false;
      }
    }
    return true;
  }

  onStayChange(e: any) {
    this.reSearch = true;
    this.atleastOneStuff = false;
    this.reSearching = true;
    this.bookable = this.isBookable(
      this.checkinDate,
      this.holidayList,
      this.cnyList
    );

    const checkout = moment(moment(this.checkinDate))
      .utcOffset("Asia/Taipei")
      .add(this.stay - 1, "day");

    if (checkout.isAfter(this.closeDate)) {
      this.reSearch = false;
      this.atleastOneStuff = true;
      this.reSearching = false;
      this.bookable = this.isBookable(
        this.route.snapshot.paramMap.get("checkin_date"),
        this.holidayList,
        this.cnyList
      );
      alert("入營期間超過店家可接受訂位日，請重新選擇，謝謝！");
    } else if (!this.isStoreOpen(moment(this.checkinDate))) {
      this.reSearch = false;
      this.atleastOneStuff = true;
      this.reSearching = false;
      this.bookable = this.isBookable(
        this.route.snapshot.paramMap.get("checkin_date"),
        this.holidayList,
        this.cnyList
      );
      alert("入營期間涵蓋公休日，請重新選擇，謝謝！");
    }
  }

  getBookableDuration() {
    let start = moment(this.openDate)
      .utcOffset("Asia/Taipei")
      .subtract(1, "days")
      .toDate();
    if (this.dayBefore > start) {
      start = this.dayBefore;
    }

    let end = this.closeDate;
    if (end > this.yearAfter) {
      end = this.yearAfter;
    }

    return [start, end];
  }

  reSearchIndex() {
    if (this.checkinDate === null) {
      this.checkinDate = this.route.snapshot.paramMap.get("checkin_date");
    }
    this.showSpinner = true;
    this.reSearch = false;
    this.caseModel = {};
    this.totalQty = 0;
    this.totalPrice = 0;
    this.serviceFee = 0;

    const checkinDateObj = moment(this.checkinDate).utcOffset("Asia/Taipei");
    const checkin_date = checkinDateObj.format();
    const checkout_date = checkinDateObj.add(this.stay, "days").format();
    this.bookable = this.isBookable(
      checkinDateObj.format("YYYY-MM-DD"),
      this.holidayList,
      this.cnyList
    );
    this.caseModelMetadata["checkin_date"] = checkin_date;
    this.caseModelMetadata["checkout_date"] = checkout_date;

    this._bookingService
      .caseIndexDateRestCount(
        checkin_date,
        checkout_date,
        this.caseModelMetadata.store_name,
        "1"
      )
      .subscribe((newIndexResp: any) => {
        const newIndexes = {};
        newIndexResp.items.forEach((i) => {
          newIndexes[i.stuff_name] = {
            original_rest_quantity: i.rest_quantity,
            // 'original_rest_quantity': i.original_rest_quantity,
            over_sold: i.over_sold,
            rest_quantity: i.rest_quantity,
            stuff_price: i.stuff_price,
            service_fee: i.service_fee,
          };
        });

        this.searchResults.forEach((r: any) => {
          const tmp = newIndexes[r.stuff_name];
          if (!r.service_fee) {
            r.service_fee = 0;
          }
          r.original_rest_quantity = tmp.original_rest_quantity;
          r.over_sold = tmp.over_sold;
          r.rest_quantity = tmp.rest_quantity;
          r.stuff_price = tmp.stuff_price;
          r.service_fee = tmp.service_fee;
          r.queue = false;
        });

        this.showSpinner = false;
        this.atleastOneStuff = true;
      });
  }

  isBookable(
    checkinDate: string,
    holidays: Array<string>,
    cnys: Array<string>
  ): boolean {
    const closestHoliday = [];
    let i = 0;
    let d = "";

    // push today in ary
    closestHoliday.push(moment().add(i++, "days").format("YYYY-MM-DD"));

    // push tomorrow in ary
    closestHoliday.push(moment().add(i++, "days").format("YYYY-MM-DD"));

    // push the day after tomorrow in ary if today is
    if (moment().day() === 5) {
      closestHoliday.push(moment().add(i++, "days").format("YYYY-MM-DD"));
    }

    d = moment()
      .add(i - 1, "days")
      .format("YYYY-MM-DD");

    const nd = moment().add(i, "days").format("YYYY-MM-DD");

    const vacationDays = _.union(holidays, cnys);
    let exist = vacationDays.indexOf(d);
    const existNextDay = vacationDays.indexOf(nd);

    if (exist === -1 && existNextDay !== -1) {
      return closestHoliday.indexOf(checkinDate) === -1;
    }

    for (; i <= 20; i++) {
      d = moment().add(i, "days").format("YYYY-MM-DD");
      exist = vacationDays.indexOf(d);
      if (exist !== -1) {
        closestHoliday.push(vacationDays[exist]);
      } else {
        break;
      }
    }

    return closestHoliday.indexOf(checkinDate) === -1;
  }

  addUnit(stuffs, i) {
    if (stuffs[i].rest_quantity - 1 < 0) {
      return;
    }

    const q = 1;
    let unitServiceFee = 0;
    this.searchResults[i].rest_quantity = stuffs[i].rest_quantity - q;

    if (this.caseModel[this.searchResults[i].stuff_name]) {
      this.caseModel[this.searchResults[i].stuff_name][0] += q;
      this.caseModel[this.searchResults[i].stuff_name][1] +=
        q * parseInt(this.searchResults[i].stuff_price, 0);
    } else {
      this.caseModel[this.searchResults[i].stuff_name] = [
        q,
        q * parseInt(this.searchResults[i].stuff_price, 0),
      ];
    }

    this.totalPrice += q * parseInt(this.searchResults[i].stuff_price, 0);
    if (!this.searchResults[i].daily_reset) {
      if (this.caseModel[this.searchResults[i].stuff_name][0] > 1) {
        unitServiceFee = 0;
      } else {
        unitServiceFee = q * parseInt(this.searchResults[i].service_fee, 0);
      }
    } else {
      unitServiceFee = q * parseInt(this.searchResults[i].service_fee, 0);
    }
    this.serviceFee += unitServiceFee;
    this.totalPrice += unitServiceFee;
    this.totalQty += q;
  }

  subtractUnit(stuffs, i) {
    const q = 1;
    let unitServiceFee = 0;
    if (!this.caseModel[this.searchResults[i].stuff_name]) {
      return;
    } else if (this.caseModel[this.searchResults[i].stuff_name][0] <= q) {
      delete this.caseModel[this.searchResults[i].stuff_name];
      this.searchResults[i].rest_quantity = stuffs[i].rest_quantity + q;
    } else {
      this.caseModel[this.searchResults[i].stuff_name][0] -= q;
      this.caseModel[this.searchResults[i].stuff_name][1] -=
        q * parseInt(this.searchResults[i].stuff_price, 0);
      this.searchResults[i].rest_quantity = stuffs[i].rest_quantity + q;
    }

    this.totalPrice -= q * parseInt(this.searchResults[i].stuff_price, 0);
    if (!this.searchResults[i].daily_reset) {
      if (this.caseModel[this.searchResults[i].stuff_name]) {
        unitServiceFee = 0;
      } else {
        unitServiceFee = q * parseInt(this.searchResults[i].service_fee, 0);
      }
    } else {
      unitServiceFee = q * parseInt(this.searchResults[i].service_fee, 0);
    }
    this.serviceFee -= unitServiceFee;
    this.totalPrice -= unitServiceFee;
    this.totalQty -= q;
  }

  goStore() {
    this.router.navigate([`/store/${this.caseModelMetadata.store_name}`, {}]);
  }

  addQueue(i) {
    this._bookingService
      .queueCreate({
        stuff_name: this.searchResults[i].stuff_name,
        checkin_date: this.caseModelMetadata.checkin_date,
        checkout_date: this.caseModelMetadata.checkout_date,
        store_name: this.caseModelMetadata.store_name,
      })
      .subscribe((queueResult: any) => {
        console.log(queueResult);
        if (queueResult.error_mesg === "" && queueResult.status === "ok") {
          this.searchResults[i].queue = true;
        }
      });
  }

  caseCreate(caseModelMetadata, caseModel) {
    this.clicked = true; // 鎖住綠色[我同意以上條款並確認此訂單]按鈕
    this.showSpinner = true; // 顯示loading
    this.footerCaseCreateModal.hide(); // 關閉Modal
    this.caseCreateModal.hide();
    this.hideCaseModel = true; // 把整個div caseModelMetadata 給hide起來

    if (this.bookingSubmit) {
      return;
    }

    this.bookingSubmit = true;

    if (!this.userVerified) {
      this.router.navigate(["/profile"]);
    }

    this._bookingService.caseCreate(caseModelMetadata, caseModel).subscribe(
      (result: any) => {
        console.log(result);
        if (result.error_mesg === "" && result.status === "ok") {
          this.router.navigate([
            `/me/trip/info/${this.caseModelMetadata.store_name}/${result.items[0].case_id}`,
            {},
          ]);
        } else {
          if (result.error_mesg === "Stuff is over sold.") {
            this.toastService.error(
              "修改失敗，部分商品在最後一刻別其他客人訂走了。系統即將在8秒後為您重新整理資訊，已獲得最新的訂位狀況。",
              "",
              { extendedTimeOut: 8000, tapToDismiss: true }
            );
            setTimeout(function () {
              location.reload();
            }, 8000);
            return;
          } else if (
            result.error_mesg === "User has not yet done phone verification."
          ) {
            this.toastService.error("訂位失敗:您尚未完成電話號碼驗證!");
            this.router.navigate(["/profile"]);
          } else if (result.error_mesg === "No user.") {
            this.toastService.error("訂位失敗:無使用者");
            this.router.navigate(["/"]);
          } else if (result.error_mesg === "User is banned.") {
            this.toastService.error("訂位失敗:你已經列入黑名單!");
            this.router.navigate(["/"]);
          } else {
            this.toastService.error("訂位失敗!");
            return;
          }
        }
      },
      (errRes: HttpErrorResponse) => {
        console.log(errRes.error.error);
        this.toastService.error(
          `訂位失敗:${errRes.error.error.code}:${errRes.error.error.message}`
        );
      }
    );
  }
}
