<div *ngIf="auth.user$ | async">
  <div class="pb-3 mt2r mx-m5" *ngIf="showSpinner">
    <div class="row">
      <app-loading-spinner
        class="col-md-12 mt-4 mb-4 px-0"
      ></app-loading-spinner>
    </div>
  </div>
</div>

<!-- <div class="pb-3 mt2r mx-m5" *ngIf="!(auth.user$ | async)"> -->
<div class="pb-3 mt2r mx-m5">
  <div class="row">
    <app-loading-spinner
      class="col-md-12 mt-4 mb-4 px-0"
      *ngIf="showSpinner"
    ></app-loading-spinner>
    <div class="col-md-12 mt-2 mb-4 px-0">
      <img
        src="../../assets/icons/icon-152x152.png"
        class="icamping-icon mx-auto d-block"
      />
    </div>

    <div class="col-md-12 mt-3 px-0">
      <div>
        <div class="d-flex justify-content-center row">
          <div class="col"></div>
          <div class="col-md-10 col-12">
            <mdb-card>
              <mdb-card-header> 使用 愛露營手機號碼登入 </mdb-card-header>
              <mdb-card-body class="px-lg-5 pt-0">
                <span class="user-input-password"
                  >請輸入台灣手機號碼 09開頭, 以便取得驗證碼</span
                >
                <div class="md-form">
                  <input
                    mdbInput
                    [(ngModel)]="phoneModel"
                    class="form-control w100"
                    type="tel"
                    maxlength="10"
                    [mdbValidation]="false"
                  />
                </div>

                <button
                  mdbBtn
                  [disabled]="!isPhoneNumberValid()"
                  id="signUpPhone-btn"
                  color="success"
                  rounded="true"
                  block="true"
                  class="my-4 waves-effect z-depth-0 w100"
                  (click)="
                    icampingPhoneTestMode
                      ? signInWithPhoneV2()
                      : signInWithPhone()
                  "
                >
                  送出
                </button>
              </mdb-card-body>
              <mdb-card-footer *ngIf="icampingPhoneTestMode">
                <div class="row text-center">
                  <a class="waves-light" (click)="phoneUserInput.show()"
                    >忘記密碼?<font color="#e51075"
                      >請點我重設進行手機驗證流程</font
                    ></a
                  >
                </div>
              </mdb-card-footer>
            </mdb-card>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>

    <!-- Facebook登入 -->
    <div class="col-md-12 px-0">
      <br />

      <button
        mdbBtn
        class="mx-auto d-block"
        type="button"
        color="primary"
        rounded="true"
        mdbWavesEffect
        [disabled]="showSpinner"
        (click)="signInWithFacebook()"
      >
        <i class="fab fa-facebook-f pr-1"></i> 使用 Facebook帳號登入
      </button>
    </div>

    <!-- Google登入 -->
    <div class="col-md-12 mt-3 px-0">
      <div class="d-flex justify-content-center">
        <button
          mdbBtn
          class="mx-auto d-block"
          type="button"
          color="white"
          rounded="true"
          mdbWavesEffect
          [disabled]="showSpinner"
          (click)="signInWithGoogle()"
        >
          <i class="fab fa-google pr-1"></i> 使用 Google 帳號登入
        </button>
      </div>
    </div>

    <!-- 愛露營email登入 -->
    <div class="col-md-12 mt-3 px-0">
      <button
        mdbBtn
        class="mx-auto d-block"
        type="button"
        color="default"
        rounded="true"
        mdbWavesEffect
        (click)="test.toggle()"
      >
        <i class="fas fa-campground"></i> 使用其它帳號登入
      </button>
    </div>

    <!-- 愛露營email登入Form -->
    <div class="col-md-12 mt-3 px-0">
      <div mdbCollapse #test="bs-collapse">
        <app-loading-spinner *ngIf="showSpinner"></app-loading-spinner>
        <div class="d-flex justify-content-center row" *ngIf="showLoginWindow">
          <div class="col"></div>
          <div class="col-md-10 col-12">
            <mdb-card>
              <mdb-card-body class="px-lg-5 pt-0">
                <form class="text-center" style="color: #757575">
                  <div class="md-form">
                    <input
                      type="email"
                      id="icampingUsername"
                      name="email"
                      class="form-control w100"
                      [(ngModel)]="icampingUserModel.email"
                      [mdbValidation]="false"
                      mdbInput
                    />
                    <label for="icampingUsername">登入帳號(Mail)</label>
                  </div>

                  <div class="md-form">
                    <input
                      type="password"
                      id="icampingPassword"
                      name="password"
                      class="form-control w100"
                      [(ngModel)]="icampingUserModel.password"
                      [mdbValidation]="false"
                      mdbInput
                    />
                    <label for="icampingPassword">密碼</label>
                  </div>

                  <button
                    mdbBtn
                    color="success"
                    rounded="true"
                    block="true"
                    class="my-4 waves-effect z-depth-0 w100"
                    id="signInWithIcamping"
                    mdbWavesEffect
                    [disabled]="
                      showSpinner ||
                      !icampingUserModel.email ||
                      !icampingUserModel.password ||
                      !icampingUserModel.email.includes('@')
                    "
                    (click)="signInWithIcamping(icampingUserModel)"
                  >
                    登入
                  </button>

                  <div class="row text-center">
                    <a
                      class="waves-light"
                      (click)="resetPasswordToggle.toggle()"
                      >需要重設愛露營帳號密碼?<font color="#e51075">
                        請點我重設</font
                      ></a
                    >
                  </div>

                  <div class="" mdbCollapse #resetPasswordToggle="bs-collapse">
                    <div class="md-form">
                      <input
                        type="text"
                        name="forgotpassword"
                        id="inputSMEx"
                        placeholder="請輸入email帳號"
                        class="form-control w100"
                        [(ngModel)]="icampingUserModelResetPassword.email"
                        [mdbValidation]="false"
                        mdbInput
                      />
                    </div>
                    <div class="md-form">
                      <button
                        class="w100"
                        mdbBtn
                        color="success"
                        rounded="true"
                        block="true"
                        mdbWavesEffect
                        (click)="resetPassword(icampingUserModelResetPassword)"
                      >
                        送出密碼重設申請
                      </button>
                    </div>
                  </div>
                </form>
              </mdb-card-body>
            </mdb-card>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  mdbModal
  #phoneVerifyCodeInput="mdb-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="phoneVerifyCodeInput"
  aria-hidden="true"
>
  <div class="modal-dialog-centered" role="dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="phoneVerifyCodeInput.hide()"
        >
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 font-weight-bold">
          請輸入6位數簡訊驗證碼?
        </h4>
      </div>
      <div class="modal-body">
        <div class="agreement-overflow">
          <div class="md-form user-input-confirmation-sms-code">
            <input
              type="text"
              id="materialFormCancelBankDesc"
              name="phoneVerifyCode"
              maxlength="6"
              class="form-control validate"
              [(ngModel)]="UserInputConfirmationSmsCode"
              mdbInput
              [mdbValidation]="false"
            />
            <label for="phoneUserInputSMSCode">輸入簡訊驗證碼</label>
          </div>
          <!-- <div id="textExample1" class="form-text">
            We'll never share your email with anyone else.
          </div> -->
          <button
            mdbBtn
            class="user-input-confirmation-sms-code-btn"
            id="smsCodeConfirm-btn-phoneVerifyCodeInput"
            color="success"
            style="background-color: #63c3a1"
            mdbWavesEffect
            (click)="phoneLoginConfirm()"
            [disabled]="
              UserInputConfirmationSmsCode.length == 0 ||
              !userInputConfirmationSmsCodeValidators()
            "
          >
            登入
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  mdbModal
  #phoneVerifyCodeInputV2="mdb-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="phoneVerifyCodeInputV2"
  aria-hidden="true"
>
  <div class="modal-dialog-centered" role="dialog">
    <div class="modal-content">
      <!-- 2024 icamping progress-bar start -->
      <div class="progress-container" *ngIf="showProgressBar">
        <div class="progress-materializecss">
          <div class="indeterminate"></div>
        </div>
      </div>
      <!-- 2024 icamping progress-bar end -->

      <div class="modal-header">
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="phoneVerifyCodeInputV2.hide()"
        >
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 font-weight-bold">
          請完成輸入新密碼及簡訊驗證碼
        </h4>
      </div>
      <div class="modal-body">
        <div class="agreement-overflow">
          <div class="md-form user-input-confirmation-sms-code">
            <input
              type="tel"
              id="UserInputConfirmationSmsCode"
              name="UserInputConfirmationSmsCode"
              maxlength="6"
              class="form-control validate"
              [(ngModel)]="UserInputConfirmationSmsCode"
              mdbInput
              [mdbValidation]="false"
            />
            <label for="phoneUserInputSMSCode">請輸入簡訊驗證碼</label>
          </div>
          <div
            *ngIf="disableUserInputUsername != true"
            class="md-form user-input-confirmation-sms-code"
          >
            <input
              type="text"
              id="UserInputUsername"
              name="UserInputUsername"
              maxlength="6"
              minlength="2"
              class="form-control validate"
              [(ngModel)]="UserInputUsername"
              mdbInput
              [mdbValidation]="false"
            />
            <label for="phoneUserInputSMSCode"
              >請輸入真實姓名，未來無法更改</label
            >
          </div>
          <div class="md-form user-input-confirmation-sms-code">
            <input
              type="password"
              id="phoneLoginPassword"
              name="phoneLoginPassword"
              maxlength="10"
              class="form-control validate"
              [(ngModel)]="phoneLoginPassword"
              mdbInput
              mdbValidation
            />
            <label for="phoneLoginPassword"
              >新密碼至少需8碼且包含英文字母與數字</label
            >
            <mdb-error *ngIf="passwordValidators(phoneLoginPassword) != true">
              {{ passwordValidators(phoneLoginPassword) }}
            </mdb-error>
          </div>
          <div class="md-form user-input-confirmation-sms-code">
            <input
              type="password"
              id="phoneLoginPassword2"
              name="phoneLoginPassword2"
              maxlength="10"
              class="form-control validate"
              [(ngModel)]="phoneLoginPassword2"
              mdbInput
              mdbValidation
            />
            <label for="phoneLoginPassword2">請重複新密碼</label>
            <mdb-error *ngIf="passwordValidators(phoneLoginPassword2) != true">
              {{ passwordValidators(phoneLoginPassword2) }}
            </mdb-error>
          </div>
          <button
            mdbBtn
            class="user-input-confirmation-sms-code-btn"
            id="smsCodeConfirm-btn-signInWithPhoneVerifyCode"
            color="success"
            style="background-color: #63c3a1"
            mdbWavesEffect
            (click)="signInWithPhoneVerifyCode()"
            [disabled]="
              UserInputUsername.length == 0 ||
              phoneLoginPassword.length == 0 ||
              phoneLoginPassword2.length == 0 ||
              phoneLoginPassword != phoneLoginPassword2 ||
              UserInputConfirmationSmsCode.length == 0 ||
              passwordValidators(phoneLoginPassword) != true ||
              !userInputConfirmationSmsCodeValidators()
            "
          >
            登入
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  mdbModal
  #phoneLoginWithPassword="mdb-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="phoneLoginWithPassword"
  aria-hidden="true"
>
  <div class="modal-dialog-centered" role="dialog">
    <div class="modal-content">
      <!-- 2024 icamping progress-bar start -->
      <div class="progress-container" *ngIf="showProgressBar">
        <div class="progress-materializecss">
          <div class="indeterminate"></div>
        </div>
      </div>
      <!-- 2024 icamping progress-bar end -->

      <div class="modal-header">
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="phoneLoginWithPassword.hide()"
        >
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 font-weight-bold">請輸入密碼</h4>
      </div>
      <div class="modal-body">
        <div class="agreement-overflow">
          <div class="md-form user-input-confirmation-sms-code">
            <input
              type="password"
              id="phoneLoginPassword"
              name="phoneLoginPassword"
              maxlength="10"
              class="form-control validate"
              [(ngModel)]="phoneLoginPassword"
              mdbInput
              mdbValidation
            />
            密碼至少需8碼且包含英文字母與數字
            <mdb-error *ngIf="passwordValidators(phoneLoginPassword) != true">
              {{ passwordValidators(phoneLoginPassword) }}
            </mdb-error>
          </div>
          <button
            mdbBtn
            class="user-input-confirmation-sms-code-btn"
            id="smsCodeConfirm-btn-phoneLoginWithPassword"
            color="success"
            style="background-color: #63c3a1"
            mdbWavesEffect
            (click)="signInWithPhoneAndPassword()"
            [disabled]="
              phoneLoginPassword.length == 0 ||
              passwordValidators(phoneLoginPassword) != true
            "
          >
            登入
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  mdbModal
  #phoneUserInput="mdb-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="phoneUserInput"
  aria-hidden="true"
>
  <div class="modal-dialog-centered" role="dialog">
    <div class="modal-content">
      <!-- 2024 icamping progress-bar start -->
      <div class="progress-container" *ngIf="showProgressBar">
        <div class="progress-materializecss">
          <div class="indeterminate"></div>
        </div>
      </div>
      <!-- 2024 icamping progress-bar end -->

      <div class="modal-header">
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="phoneUserInput.hide()"
        >
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 font-weight-bold">請輸入台灣手機號碼</h4>
      </div>
      <div class="modal-body">
        <div class="agreement-overflow">
          <div class="md-form user-input-confirmation-sms-code">
            <input
              type="tel"
              id="phoneModelInPhoneUserInput"
              name="phoneModelInPhoneUserInput"
              maxlength="10"
              minlength="10"
              class="form-control validate"
              [(ngModel)]="phoneModel"
              mdbInput
              [mdbValidation]="false"
            />
            <label for="phoneModelInPhoneUserInput"
              >09開頭, 以便取得驗證碼</label
            >
          </div>
          <button
            mdbBtn
            class="user-input-confirmation-sms-code-btn"
            id="smsCodeConfirm-btn-phoneUserInput"
            color="success"
            style="background-color: #63c3a1"
            mdbWavesEffect
            (click)="resetUserPhoneCode()"
            [disabled]="!isPhoneNumberValid()"
          >
            發送驗證碼並且重置密碼
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
