import { BookingService } from "../../../../service/booking.service";
import { Component, Input, OnInit } from "@angular/core";
import { IMyOptions, ToastService } from "ng-uikit-pro-standard";
import { StoreService } from "../../../../service/store.service";
import { TripService } from "../../../../service/trip.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";

import * as _ from "lodash";
import * as moment from "moment";
import { transformAll } from "@angular/compiler/src/render3/r3_ast";

@Component({
  selector: "app-me-trip-edit-case-detail",
  templateUrl: "./case-detail.component.html",
  styleUrls: ["./case-detail.component.scss"],
})
export class MeTripEditCaseDetailComponent implements OnInit {
  @Input() caseId: string;
  @Input() storeName: string;

  _: any = _;
  canDateUpdate = true;
  case: any;
  checkInDate: Date;
  checkOutDate: Date;
  closeDate: Date;
  openDate: Date;
  refundedPrice = 0;
  refundPenalty = 0;
  refundPrice = 0;
  canSearch = true;
  searched = false;
  showSpinner = true;
  quantitiesValid = true;

  user_bank = "";
  user_bank_description = "";
  invoice_id = "";

  verifyCheckInDate: IMyOptions = {
    closeAfterSelect: true,
    showTodayBtn: false,
    showClearDateBtn: false,
    // Strings and translations
    dayLabels: {
      su: "日",
      mo: "一",
      tu: "二",
      we: "三",
      th: "四",
      fr: "五",
      sa: "六",
    },
    dayLabelsFull: {
      su: "日",
      mo: "一",
      tu: "二",
      we: "三",
      th: "四",
      fr: "五",
      sa: "六",
    },
    monthLabels: {
      1: "一月",
      2: "二月",
      3: "三月",
      4: "四月",
      5: "五月",
      6: "六月",
      7: "七月",
      8: "八月",
      9: "九月",
      10: "十月",
      11: "十一月",
      12: "十二月",
    },
    monthLabelsFull: {
      1: "一月",
      2: "二月",
      3: "三月",
      4: "四月",
      5: "五月",
      6: "六月",
      7: "七月",
      8: "八月",
      9: "九月",
      10: "十月",
      11: "十一月",
      12: "十二月",
    },

    // Buttons
    todayBtnTxt: "今日",
    clearBtnTxt: "清除",
    closeBtnTxt: "確認",

    // First day of the week
    firstDayOfWeek: "mo",
    selectionTxtFontSize: "16px",
    dateFormat: "yyyy-mm-dd",
  };
  holidays: Array<any> = [];
  weekdayOpen: Array<string>;

  constructor(
    private _bookingService: BookingService,
    private _location: Location,
    private _storeService: StoreService,
    private _tripService: TripService,
    private router: Router,
    private toastService: ToastService
  ) {}

  invoiceValidators() {
    return /^(\d{8}|)$/.test(this.invoice_id);
  }

  getToday() {
    const d = moment().utcOffset("Asia/Taipei").toDate();
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    return d;
  }

  getBookableDuration() {
    // this.checkInDate = moment(_case.item.checkin_date).add(8, 'hour').utcOffset('Asia/Taipei').toDate();
    let start = moment().utcOffset("Asia/Taipei").add(1, "days").toDate();

    // 如果店家開放時間 比 訂單checkin date還晚
    if (this.openDate > start) {
      start = moment(this.openDate)
        .utcOffset("Asia/Taipei")
        .subtract(1, "days")
        .toDate();
    }
    const yearAfter = moment()
      .utcOffset("Asia/Taipei")
      .add(1, "years")
      .toDate();

    // 如果店家最後可訂位時間在一年後

    const diffDays = Math.ceil(
      Math.abs(this.checkOutDate.getTime() - this.checkInDate.getTime()) /
        (1000 * 3600 * 24)
    );

    let end = moment(this.closeDate)
      .utcOffset("Asia/Taipei")
      .subtract(diffDays - 1, "day")
      .toDate();

    console.log(
      moment(this.closeDate)
        .utcOffset("Asia/Taipei")
        .subtract(diffDays - 1, "day")
    );

    if (end > yearAfter) {
      end = yearAfter;
    }
    return [start, end];
  }

  inXDays(d: Date, x: number) {
    const today = this.getToday();
    const timeDiff = Math.abs(d.getTime() - today.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (diffDays < x) {
      return true;
    }
    return false;
  }

  isTransferToBoss(c: any) {
    if (c.postpone_status) {
      return false;
    }
    return c.tag_color && c.tag_color === "#FF4081";
  }

  isValidateCase(c: any) {
    if (
      c.cancel_status ||
      c.close_status ||
      c.status === "checkout"
      // c.checkin_date < this.getToday()
      // !moment().isBefore(c.checkin_date)
    ) {
      return false;
    }
    return true;
  }

  filterEmptyStuff() {
    return this.case.stuff_info.filter((s) => s[5] > 0);
  }

  ngOnInit() {
    this._bookingService.ruleGet().subscribe((result: any) => {
      this.holidays = result.item.holiday_list.concat(result.item.cny_list);
    });

    this._tripService
      .caseCaseIdList(this.caseId, this.storeName)
      .subscribe((_case: any) => {
        _case.item.stuff_info = JSON.parse(_case.item.stuff_info);
        _case.item.stuff_info.forEach((stuff) => {
          // Get Stuff images
          this._storeService
            .storeNameGetStuffImageLimit(this.storeName, stuff[0])
            .subscribe((stuffImage: any) => {
              // stuff['image'] = this.sanitizer.bypassSecurityTrustStyle(`url(${stuffImage[0].thumbnail_600c_url})`);
              stuff[7] = stuffImage[0].thumbnail_600c_url;
            });
          stuff[5] = stuff[2];
          stuff[6] = stuff[2]; // 之後要新時段剩餘的數量
        });

        if (!this.isValidateCase(_case.item)) {
          this.router.navigate(["/"]);
          return;
        }

        this.checkInDate = moment(_case.item.checkin_date)
          .add(8, "hour")
          .utcOffset("Asia/Taipei")
          .toDate();
        this.checkOutDate = moment(_case.item.checkout_date)
          .add(8, "hour")
          .utcOffset("Asia/Taipei")
          .toDate();

        if (
          (this.inXDays(this.checkInDate, 2) &&
            !(
              _case.item.postpone_status == true &&
              _case.item.postpone_reason == "postponed_by_boss_weather"
            )) ||
          this.isTransferToBoss(_case.item)
        ) {
          this.router.navigate([
            `/me/trip/info/${this.storeName}/${this.caseId}`,
          ]);
        }

        if (this.inXDays(this.checkInDate, 15) && !_case.item.postpone_status) {
          // 15天內訂單 且 沒有延期通知 => 只能減帳
          this.canDateUpdate = false;
          this.toastService.warning(
            "提醒您：訂單入住日在15天內，只能減帳，謝謝。"
          );
        } else {
          _case.item.checkin_date = moment(_case.item.checkin_date)
            .add(8, "hour")
            .utcOffset("Asia/Taipei")
            .format("YYYY-MM-DD");
          _case.item.checkout_date = moment(_case.item.checkout_date)
            .add(8, "hour")
            .utcOffset("Asia/Taipei")
            .format("YYYY-MM-DD");
        }

        this.user_bank = _case.item.user_bank;
        this.user_bank_description = _case.item.user_bank_description;
        this.invoice_id = _case.item.invoice_id;
        this.case = _case.item;
        // Get store close date
        this._storeService
          .storeNameGet(this.storeName)
          .subscribe((_store: any) => {
            this.closeDate = moment(_store.items.close_date)
              .add(8, "hour")
              .utcOffset("Asia/Taipei")
              .toDate();
            this.openDate = moment(_store.items.open_date)
              .add(8, "hour")
              .utcOffset("Asia/Taipei")
              .toDate();
            this.weekdayOpen = _store.items.weekday_open;

            const days = this.getBookableDuration();
            let startDate = days[0];
            const endDate = days[1];

            // For issue #699.
            if (startDate.getDay() === 5) {
              let goodStartDate = new Date(startDate);
              goodStartDate.setDate(goodStartDate.getDate() + 3);
              startDate = goodStartDate;
            }

            this.verifyCheckInDate = {
              disableUntil: {
                year: startDate.getFullYear(),
                month: startDate.getMonth() + 1,
                day: startDate.getDate(),
              },
              disableSince: {
                year: endDate.getFullYear(),
                month: endDate.getMonth() + 1,
                day: endDate.getDate() + 1,
              },
            };

            this.showSpinner = false;
          });
      });
  }

  isStoreOpen(d: moment.Moment, diff: number): boolean {
    const _d = d.clone();
    for (let i = 0; i < diff; i++) {
      if (i > 0) {
        _d.add(1, "day");
      }

      let wd = _d.day() - 1;
      if (_d.day() === 0) {
        wd = 6;
      }

      if (
        this.weekdayOpen[wd] === "0" &&
        this.holidays.indexOf(_d.format("YYYY-MM-DD")) === -1
      ) {
        return false;
      }
    }
    return true;
  }

  onDateChange(e: any): any {
    this.searched = false;
    if (!e.actualDateFormatted || e.actualDateFormatted.length !== 10) {
      this.toastService.warning("請選擇選擇入露日，謝謝。");
      return null;
    }

    const d = moment(e.actualDateFormatted).utcOffset("Asia/Taipei");
    const diffDays = Math.ceil(
      Math.abs(this.checkOutDate.getTime() - this.checkInDate.getTime()) /
        (1000 * 3600 * 24)
    );

    if (!this.isStoreOpen(d, diffDays)) {
      this.canSearch = false;
      this.toastService.warning("入營期間涵蓋公休日，請重新選擇，謝謝！");
      this.case.checkout_date = d.add(diffDays, "day").format("YYYY-MM-DD");
    } else {
      this.canSearch = true;
      this.case.checkout_date = d.add(diffDays, "day").format("YYYY-MM-DD");
    }
  }

  caseUpdate(_case: any) {
    if (!this.chceckQuantitiesValid() || this.showSpinner) {
      return;
    }
    if (!this.invoiceValidators() || this.showSpinner) {
      this.toastService.error(`修改失敗，請輸入正確統一編號，謝謝。`);
      return;
    }
    const stuff_name_list = [];
    const stuff_quantity_list = [];
    let quantityEdited = false;

    const diffDays = Math.ceil(
      Math.abs(this.checkOutDate.getTime() - this.checkInDate.getTime()) /
        (1000 * 3600 * 24)
    );

    this.case.stuff_info.forEach((stuff) => {
      stuff_name_list.push(stuff[0]);
      stuff_quantity_list.push(stuff[2]);
      if (stuff[2] !== stuff[5]) {
        quantityEdited = true;
      }
    });

    // 沒有任何更新
    if (!quantityEdited && !this.canDateUpdate) {
      this._location.back();
      return;
    }

    // 不可以把全部數量都改0
    if (!_.sum(stuff_quantity_list)) {
      this.case.stuff_info.forEach((stuff) => {
        stuff[2] = stuff[5];
      });
      this.toastService.error("不可以把全部數量都改為 0，謝謝！");
      return;
    }

    this.showSpinner = true;

    const newCheckInDate = moment(this.case.checkin_date).utcOffset(
      "Asia/Taipei"
    );
    // 消費者使用他日功能
    if (
      this.canDateUpdate &&
      !newCheckInDate.isSame(moment(this.checkInDate).utcOffset("Asia/Taipei"))
    ) {
      // 確認有沒有改超過 "可預訂數量"
      if (!this.chceckQuantitiesValid()) {
        this.toastService.error("請減量或重新選擇日期，謝謝！");
        return;
      }

      if (!this.isStoreOpen(newCheckInDate, diffDays)) {
        this.toastService.error("入營期間涵蓋公休日，請重新選擇，謝謝！");
        return;
      }

      this._bookingService
        .caseDateUpdate({
          case_id: this.caseId,
          checkin_date: newCheckInDate,
          checkout_date: moment(this.case.checkout_date).utcOffset(
            "Asia/Taipei"
          ),
          store_name: this.storeName,
          stuff_name_list: stuff_name_list,
          stuff_quantity_list: stuff_quantity_list,
          user_bank: this.user_bank,
          user_bank_description: this.user_bank_description,
          invoice_id: this.invoice_id,
        })
        .subscribe((result: any) => {
          console.log(result);
          if (result.error_mesg === "" && result.status === "ok") {
            this.toastService.success("修改成功");
            // 回到訂單
            this.router.navigate([
              `/me/trip/info/${this.storeName}/${this.caseId}`,
            ]);
          } else {
            if (result.error_mesg === "Stuff happen over sold.") {
              this.showSpinner = false;
              this.toastService.error(
                "修改失敗，部分商品在最後一刻別其他客人訂走了。系統即將為您重新整理資訊，已獲得最新的訂位狀況。"
              );

              setTimeout(function () {
                location.reload();
              }, 5000);

              return;
            }
            this.toastService.error(
              `修改失敗 [${result.error_mesg}]，請聯絡客服人員，謝謝。`
            );
            // 回到訂單
            this.router.navigate([
              `/me/trip/info/${this.storeName}/${this.caseId}`,
            ]);
          }
        });
      return;
    }

    // 消費者使用同日減帳
    this._bookingService
      .caseQuantityUpdate({
        case_id: this.caseId,
        store_name: this.storeName,
        stuff_name_list: stuff_name_list,
        stuff_quantity_list: stuff_quantity_list,
        user_bank: this.user_bank,
        user_bank_description: this.user_bank_description,
        invoice_id: this.invoice_id,
      })
      .subscribe((result: any) => {
        console.log(result);
        if (result.error_mesg === "" && result.status === "ok") {
          this.toastService.success("修改成功");
          // 回到訂單
          this.router.navigate([
            `/me/trip/info/${this.storeName}/${this.caseId}`,
          ]);
        } else {
          this.showSpinner = false;
          this.toastService.error(
            `修改失敗 [原因:疑似黑名單，${result.error_mesg}]，請聯絡客服人員，謝謝。`
          );
          // 回到訂單
          this.router.navigate([
            `/me/trip/info/${this.storeName}/${this.caseId}`,
          ]);
        }
      });
  }

  search() {
    const newCheckInDate = moment(this.case.checkin_date).utcOffset(
      "Asia/Taipei"
    );
    if (!this.case.checkin_date) {
      this.toastService.error("請選擇選擇入露日，謝謝。");
      return;
    }

    if (
      newCheckInDate.isSame(moment(this.checkInDate).utcOffset("Asia/Taipei"))
    ) {
      if (this.case.postpone_status) {
        this.toastService.error("延期訂單，請更改露營時段，謝謝。");
        return;
      }
      this.searched = true;
      return;
    }

    const restCaseIndex = {};
    this._bookingService
      .caseIndexDateRestCount(
        newCheckInDate,
        moment(this.case.checkout_date).utcOffset("Asia/Taipei"),
        this.storeName,
        1,
        this.caseId
      )
      .subscribe((res: any) => {
        if (res.status === "ok" && res.error_mesg === "") {
          res.items.forEach((s) => {
            restCaseIndex[s.stuff_name] = parseInt(s.rest_quantity, 0);
          });
          let quantitiesValid = true;
          this.case.stuff_info.forEach((s) => {
            s[2] = s[5];
            s[6] = restCaseIndex[s[0]];
            if (s[6] < s[2]) {
              quantitiesValid = false;
            }
          });
          this.quantitiesValid = quantitiesValid;
          this.searched = true;
        }
      });
  }

  chceckQuantitiesValid() {
    for (let i = 0; i < this.case.stuff_info.length; i++) {
      const min = Math.min(
        this.case.stuff_info[i][5],
        this.case.stuff_info[i][6]
      );
      if (
        this.case.stuff_info[i][2] === null ||
        min < this.case.stuff_info[i][2]
      ) {
        this.quantitiesValid = false;
        this.toastService.error(
          `${this.case.stuff_info[i][0]}的數量不可超過${min}，請修正。`
        );
        return false;
      }
    }
    this.quantitiesValid = true;
    return true;
  }
}
