import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { BookingService } from '../../service/booking.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
// import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
// import { HttpErrorResponse } from '@angular/common/http';
import { StoreService } from '../../service/store.service';
// import { ToastService } from '../../../typescripts/pro/alerts/toast/toast.service';
import * as moment from 'moment';

@Component({
  selector: 'app-camp-search-result',
  templateUrl: './camp-search-result.component.html',
  styleUrls: ['./camp-search-result.component.scss'],
})

export class CampSearchResultComponent implements OnInit, OnDestroy {
  stores: Array<any> = [];
  user: any;
  navigationSubscription;
  showSpinner = true;

  constructor(
    private route: ActivatedRoute,
    public auth: AuthService,
    private router: Router,
    private _storeService: StoreService,
    private _bookingService: BookingService, // private toastService: ToastService,
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.init();
      }
    });
  }

  init() {
    this.auth.user$.subscribe((user: any) => {
      this.user = user;
    });

    const stayDays = this.route.snapshot.paramMap.get('stay');
    const checkinDateObj = moment(
      this.route.snapshot.paramMap.get('checkin_date'),
    ).utcOffset('Asia/Taipei');
    const area = this.route.snapshot.paramMap.get('area');
    const checkinDate = checkinDateObj.format();
    const checkoutDate = checkinDateObj.add(stayDays, 'days').format();
    const facility = this.route.snapshot.paramMap.get('facility');
    const quantity = this.route.snapshot.paramMap.get('quantity');

    if (this._bookingService.matchLastCaseIndexQuickSearch(
        area, checkinDate, checkoutDate, facility, quantity)) {
          this.stores = this._bookingService.getStoresCaseIndexQuickSearch();
          this.showSpinner = false;
    } else {

      this._bookingService
        .caseIndexQuickSearch(
          area,
          checkinDate,
          checkoutDate,
          facility,
          quantity,
        )
        .subscribe((resp: any) => {
          if (resp.status !== 'ok' || resp.error_mesg !== '') {
            this.stores = [];
            this.showSpinner = false;
            return;
          }
          resp.items.forEach((storeObj: any) => {
            this._storeService
              .storeNameGetImageLimit(storeObj.store_name)
              .subscribe((image: any) => {
                storeObj['image'] = image[0].thumbnail_600c_url;
                this._storeService.hidePageLoading();
              });
          });

          this.stores = this._bookingService
            .setStoresCaseIndexQuickSearch(
              area, checkinDate, checkoutDate, facility, quantity, resp.items);
          this.showSpinner = false;
        });
    }
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  stuffSearch(sIndex: number) {
    if (!this.user || !this.user.verified) {
      this.router.navigate([`/store/${this.stores[sIndex].store_name}`]);
      return;
    }
    const stayDays = this.route.snapshot.paramMap.get('stay');
    const checkinDateObj = moment(
      this.route.snapshot.paramMap.get('checkin_date'),
    ).utcOffset('Asia/Taipei');
    const checkinDate = checkinDateObj.format('YYYY-MM-DD');

    this.router.navigate([
      '/store/stuff/search-result',
      {
        store_name: this.stores[sIndex].store_name,
        checkin_date: checkinDate,
        stay: stayDays,
      },
    ]);
  }
}
