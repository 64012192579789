<app-loading-spinner *ngIf="showSpinner"></app-loading-spinner>

<div class="row" *ngIf="!showSpinner && events">
  <!--第二個月-->
  <div class="col-md-6 mb-3 pr-md-1 p-0">
    <mwl-utils-calendar-header
      [(view)]="view"
      [(viewDate)]="viewDate1"
      [locale]="locale"
    >
    </mwl-utils-calendar-header>
    <ng-template #customHeadTemplate let-days="days" let-locale="locale">
      <div class="cal-cell-row cal-header">
        <div
          class="cal-cell"
          *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
          [class.cal-past]="day.isPast"
          [class.cal-today]="day.isToday"
          [class.cal-future]="day.isFuture"
          [class.cal-weekend]="day.isWeekend"
          [ngClass]="day.cssClass"
        >
          {{
            day.date
              | calendarDate: "monthViewColumnHeader":locale
              | calWeekdayFormat
          }}
        </div>
      </div>
    </ng-template>

    <ng-template #customCellTemplate let-day="day" let-locale="locale">
      <div class="cal-cell-top">
        <span class="cal-day-number">{{
          day.date | calendarDate: "monthViewDayNumber":locale
        }}</span>
      </div>

      <div *ngFor="let e of day.events">
        <font
          *ngIf="day.badgeTotal"
          style="margin: 5px"
          color="{{ e.color }}"
          >{{ e.title }}</font
        >
      </div>
    </ng-template>

    <div [ngSwitch]="view">
      <!-- <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate1" [locale]="locale" [events]="events"
        [headerTemplate]="customHeadTemplate" [cellTemplate]="customCellTemplate"
        (dayClicked)="dateClicked($event.day.date)">
      </mwl-calendar-month-view> -->
      <mwl-calendar-month-view
        *ngSwitchCase="'month'"
        [viewDate]="viewDate1"
        [locale]="locale"
        [events]="events"
        [headerTemplate]="customHeadTemplate"
        [cellTemplate]="customCellTemplate"
        (dayClicked)="dateClicked($event.day.date)"
      >
      </mwl-calendar-month-view>
    </div>
  </div>

  <!-- 銷售狀況 -->

  <!-- <div class="progress">
    <div class="progress-bar" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0"
      aria-valuemax="100"></div>
  </div> -->
  <div class="col-md-6 mb-3 pl-md-1 p-0">
    <h3>{{ lastClickedDate }} 空位狀況</h3>
    <table id="tableSortExample" mdbTable class="z-depth-1">
      <thead>
        <tr>
          <th
            *ngFor="let head of headElements; let i = index"
            aria-controls="tableSortExample"
            scope="col"
            [mdbTableSort]="stuffs"
            [sortBy]="headElementKeys[i]"
          >
            {{ head | titlecase }}
            <mdb-icon fas icon="sort" *ngIf="i < 3"></mdb-icon>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let stuff of stuffs; let i = index">
          <th scope="row">{{ stuff.name }}</th>
          <td>{{ stuff.cap }}</td>
          <!-- <td>{{ stuff.sold > stuff.cap ? stuff.cap : stuff.sold }}</td> -->
          <td>{{ stuff.cap - stuff.sold > 0 ? stuff.cap - stuff.sold : 0 }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
