import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ExperienceService } from '../service/experience.service';
import { Router } from '@angular/router';
import { StoreService } from '../service/store.service';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss'],
})
export class ExperienceComponent implements OnInit {
  experienceName: string;
  experienceDescription: SafeHtml;
  experienceImages: any;
  stuffs: Array<any> = [];

  constructor(
    private _experienceService: ExperienceService,
    private _storeService: StoreService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) {}


  goHomePage() {
    this.router.navigate(['/']);
  }
  ngOnInit() {
    const experienceName = this.route.snapshot.paramMap.get('name');

    this._experienceService
      .experienceNameGet(experienceName)
      .subscribe((experience: any) => {
        if (!experience.items) {
          this.goHomePage();
          return;
        }
        this.experienceName = experience.items.name;
        this.experienceDescription = this.sanitizer.bypassSecurityTrustHtml(
          experience.items.description,
        );
      });

    this._storeService
      .stuffExperienceNameList(experienceName)
      .subscribe((stuff: any) => {
        if (!stuff.items) {
          this.goHomePage();
          return;
        }

        const x = [];
        stuff.items.forEach((stuffObj: any) => {
          this._storeService
            .storeNameGetExperienceStuffGoldenImage(
              stuffObj.store_name,
              experienceName,
            )
            .subscribe((stuffImages: any) => {
              stuffObj['image'] = stuffImages[0];
              x.push(stuffImages[0]);
              this.experienceImages = x;
            });

          // stuffObj.description = this.sanitizer.bypassSecurityTrustHtml(
          //   stuffObj.description,
          // );

          stuffObj.description = stuffObj.description;
        }); // forEach stuffObj end.

        this.stuffs = stuff.items;
      });
  }

  getStore(storeName: string): void {
    this.router.navigate([`/store/${storeName}`]);
  }
}
