import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable()
export class SeoService {
  constructor(private meta: Meta, private titleService: Title) { }

  generateTags(config: any) {
    config = {
      title: '台灣愛露營:不用30秒幫你找到台灣優質營地！',
      description:
        '想露營，需要一個一個找有空位營地很麻煩？愛露營 30 秒幫您找到有空位的營地！讓你說走就走露營去！',
      image: 'https://m.icamping.app/assets/images/icamping-og.jpg',
      slug: '',
      ...config,
    };

    this.titleService.setTitle(config.title);

    console.log(config.title)
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:site_name', content: '台灣愛露營' });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({
      property: 'og:description',
      content: config.description.replace(/<\/?[^>]+(>|$)/g, ""),
    });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:image:width', content: '125' });
    this.meta.updateTag({ property: 'og:image:height', content: '125' });
    this.meta.updateTag({
      property: 'og:url',
      content: `https://m.icamping.app/${config.slug}`,
    });
  }
}
