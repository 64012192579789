import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AuthService } from "../../core/auth.service";
import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { take, tap } from "rxjs/operators";
import { ToastService } from "ng-uikit-pro-standard";

import { environment } from "../../../environments/environment";
import { MessagingService } from "../../core/messaging.service";
import { UserService } from "../../service/user.service";

@Component({
  selector: "app-user-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class UserProfileComponent implements OnInit {
  isDisabled: boolean;
  phoneNumber = "";
  phoneNumberCheck = "";
  showSpinner: boolean;
  showTmpl: boolean;
  userUid = "";

  constructor(
    private afs: AngularFirestore,
    private location: Location,
    private router: Router,
    private toastService: ToastService,
    private _userService: UserService,
    public auth: AuthService,
    public msg: MessagingService
  ) {}

  ngOnInit() {
    console.log("現在進入 /profile頁面");
    window.sessionStorage.setItem("urlBeforeLogin", "/profile");

    this.showSpinner = false;
    this.showTmpl = true;
    this.isDisabled = false;

    this.auth.user$.pipe(take(1)).subscribe((user: any) => {
      if (!user) {
        console.error("這是/profile: 使用者尚未登入新版愛露營！");
        this.router.navigate(["/login"]);
      } else {
        console.log("這是/profile: 使用者已經成功登入新版愛露營");
        this.userUid = user.uid;
      }
    });
  }

  userPhoneNumberVerify(phoneNumber: string) {
    this.showSpinner = true;
    this.showTmpl = false;
    this.isDisabled = true;

    this.afs
      .doc(`users/${this.userUid}`)
      .set(
        { phoneNumber: phoneNumber, uid: this.userUid, verified: true },
        { merge: true }
      )
      .then((result) => {
        this._userService
          .updateFirebaseAccountPhoneNumberAndIcampingCoreNDB(
            this.userUid,
            phoneNumber
          )
          .subscribe((r: any) => {
            this.showSpinner = false;
            this.showTmpl = true;
            this.isDisabled = false;

            this.toastService.success(`手機號碼已綁定`);
          });
      });
  }

  home() {
    window.location.href = `${environment.baseUrl}`;
  }

  keepBooking() {
    this.location.back();
  }
}
