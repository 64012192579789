import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/compat/firestore";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import { map } from "rxjs/operators";
import * as moment from "moment";
import { StoreService } from "../service/store.service";
import * as _ from "lodash";
import { of, BehaviorSubject } from "rxjs";

interface ImageInterface {
  caption: string;
  fileName: string;
  golden: boolean;
  imageType: string;
  orig_url: string;
  path: string;
  storeName: string;
  thumbnail_256_url: string;
}

interface StoreFilterInterface {
  areaFilter: any;
  cityFilter: string;
  conditionFilter: any;
  facilityFilter: any;
}

interface CampFilterInterface {
  areaFilter: any;
  checkinDateFilter: any;
  facilityFilter: any;
  stayFilter: any;
}

interface ExperienceFilterInterface {
  cityFilter: any;
  tagFilter: any;
}

@Injectable()
export class HomeService {
  stores: Array<any> = [];
  storeTops: Array<any> = [];
  experiences: Array<any> = [];
  campFilter: CampFilterInterface;
  storeFilter: StoreFilterInterface;
  experienceFilter: ExperienceFilterInterface;
  ImageCollection: AngularFirestoreCollection<ImageInterface>;
  storeSearchResultSlogan = "營地搜尋結果";
  storeAliasesMap = {};
  public showMenu$ = new EventEmitter<boolean>();

  constructor(
    private afs: AngularFirestore,
    private _http: HttpClient,
    private _storeService: StoreService
  ) {
    this.setDefaultCampFilter();
    this.setDefaultStoreFilter();
    this.setDefaultExperienceFilter();
    // this._storeService.facilityList().subscribe((f: any) => {
    //   if (f.length > 0) {
    //     f[0].name.forEach(fa => {
    //       this.storeFilter.facilityFilter[fa] = false;
    //     });
    //     this.storeFilter.facilityFilter['全選'] = true;
    //   }
    // });

    this._storeService.conditionList().subscribe((c: any) => {
      if (c.length > 0) {
        c[0].name.forEach((n) => {
          this.storeFilter.conditionFilter[n] = false;
        });
        this.storeFilter.conditionFilter["全選"] = true;
      }
    });

    this.storeListTop().subscribe((result: any) => {
      if (!result.items) {
        return;
      }

      result.items.forEach((storeObj: any) => {
        storeObj.altitude = parseInt(storeObj.altitude, 0);
        this._storeService
          .storeNameGetImageLimit(storeObj.store_name)
          .subscribe((image: any) => {
            // console.log(image, storeObj)
            storeObj["image"] = image[0].thumbnail_400c_url;
          });
      });

      _.shuffle(result.items).forEach((storeObj: any) => {
        this.storeTops.push(storeObj);
      });
    });

    this.storeList().subscribe((result: any) => {
      if (!result.items) {
        return;
      }

      result.items.forEach((storeObj: any) => {
        storeObj.altitude = parseInt(storeObj.altitude, 0);
        this._storeService
          .storeNameGetImageLimit(storeObj.store_name)
          .subscribe((image: any) => {
            storeObj["image"] = image[0].thumbnail_400c_url;
          });
      });

      _.shuffle(result.items).forEach((storeObj: any) => {
        this.stores.push(storeObj);
        this.storeAliasesMap[storeObj.store_alias] = storeObj.store_name;
      });
      this._storeService.hidePageLoading();
    });

    this.experienceListName().subscribe((result: any) => {
      if (!result.items) {
        return;
      }

      result.items.forEach((exp: any) => {
        //console.log(exp);
        this._storeService
          .storeNameGetExperienceStuffGoldenImage(exp.store_name, exp.name)
          .subscribe((image: any) => {
            //console.log(image);
            exp["image"] = image[0].thumbnail_400c_url;
          });
        this.experiences.push(exp);
      });
      this._storeService.hidePageLoading();
    });
  }

  experienceListName(status = true) {
    return this._http.post(
      `${environment.cloudEndpointGuestTier2}/experience/list`,
      {
        status: status,
      },
      { params: { key: environment.cloudEndpointApiKey } }
    );
  }

  getStores() {
    return new BehaviorSubject<any>(this.stores);
  }

  getExperience() {
    return new BehaviorSubject<any>(this.experiences);
  }

  getExperienceFilter() {
    return this.experienceFilter;
  }

  getStoreFilter() {
    return this.storeFilter;
  }

  getStoreTops() {
    return new BehaviorSubject<any>(this.storeTops);
  }

  mapStoreAlias(alias) {
    return this.storeAliasesMap[alias];
  }

  setDefaultCampFilter() {
    this.campFilter = {
      areaFilter: "中部營區",
      checkinDateFilter: moment().format("YYYY-MM-DD"),
      facilityFilter: { 全選: true },
      stayFilter: 1,
    };
  }

  setDefaultStoreFilter() {
    // https://github.com/axacheng/icamping-core/issues/558
    // 預設是『北部營區』要被clicked，其餘3個不要clicked。

    this.storeFilter = {
      areaFilter: {
        北部營區: true,
        中部營區: false,
        南部營區: false,
        東部營區: false,
        離島營區: false,
      },
      // 'areaFilter': { '北部營區': true, '中部營區': true, '南部營區': true, '東部營區': true },
      cityFilter: "",
      conditionFilter: { 全選: true },
      facilityFilter: { 全選: true },
    };
  }

  setDefaultExperienceFilter() {
    this.experienceFilter = {
      tagFilter: [],
      cityFilter: [],
    };
  }

  setAllStoreFilter(city = "") {
    this.storeFilter.areaFilter = {
      北部營區: true,
      中部營區: true,
      南部營區: true,
      東部營區: true,
      離島營區: true,
    };
    this.storeFilter.cityFilter = city;
    // for (var key in this.storeFilter.conditionFilter){
    //   this.storeFilter.conditionFilter[key] = false;
    // }
    // for (var key in this.storeFilter.facilityFilter){
    //   this.storeFilter.facilityFilter[key] = false;
    // }

    this.storeFilter.conditionFilter["全選"] = true;
    this.storeFilter.facilityFilter["全選"] = true;
  }

  clearStoreCityFilter() {
    this.storeFilter.cityFilter = "";
  }

  setStoreFilter(areaFilter: any, conditionFilter: any, facilityFilter: any) {
    this.storeFilter = {
      areaFilter: areaFilter,
      cityFilter: "",
      // 'cityFilter': this.storeFilter.cityFilter,
      conditionFilter: conditionFilter,
      facilityFilter: facilityFilter,
    };
  }

  setExperienceFilter(tagFilter: any, cityFilter: any) {
    this.experienceFilter = {
      tagFilter: tagFilter,
      cityFilter: cityFilter,
    };
  }

  getCampFilter() {
    return this.campFilter;
  }

  setCampFilter(area: any, checkinDate: any, facility: any, stay: any) {
    this.campFilter = {
      areaFilter: area,
      checkinDateFilter: checkinDate,
      facilityFilter: facility,
      stayFilter: stay,
    };
  }

  storeList() {
    return this._http.get(`${environment.cloudEndpointGuestTier1}/store/list`, {
      params: {
        only_show_user_like: false,
        key: environment.cloudEndpointApiKey,
      },
    });
  }

  storeListTop() {
    return this._http.get(
      `${environment.cloudEndpointGuestTier1}/store/list_top`,
      {
        params: { key: environment.cloudEndpointApiKey },
      }
    );
  }

  replaceImageUrlToCdnIp(obj) {
    for (const key in obj) {
      if (
        typeof obj[key] === "string" &&
        obj[key].includes(
          "https://storage.googleapis.com/icamping-tree.appspot.com"
        )
      ) {
        obj[key] = obj[key].split("?")[0];
        obj[key] = obj[key].replace(
          "https://storage.googleapis.com/icamping-tree.appspot.com",
          "https://cdn.icamping.app"
        );
      }
    }
  }

  experienceImageList(experienceName: string) {
    this.ImageCollection = this.afs.collection<ImageInterface>(
      "images",
      (ref) =>
        ref
          .where("isExperience", "==", true)
          .where("imageType", "==", "stuff")
          .where("experienceName", "==", experienceName)
    );

    return this.ImageCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((action) => {
          const data = action.payload.doc.data();
          this.replaceImageUrlToCdnIp(data);
          console.log(data);
          const id = action.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  subjectImageList() {
    this.ImageCollection = this.afs.collection<ImageInterface>(
      "images",
      (ref) => ref.where("imageType", "==", "experience")
    );

    return this.ImageCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((action) => {
          const data = action.payload.doc.data();
          this.replaceImageUrlToCdnIp(data);
          console.log(data);
          const id = action.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  setStoreSrearchResultSlogan(slogan = "") {
    this.storeSearchResultSlogan = slogan;
  }

  getStoreSrearchResultSlogan() {
    return this.storeSearchResultSlogan;
  }
}
