import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { StoreService } from '../service/store.service';

@Pipe({ name: 'storeFilter' })
export class StorePipe implements PipeTransform {
  transform(stores: any, _areaFilter: any, _facilityFilter: any, _conditionFilter:any, x): Array<any> {
    if (!stores) {
      return stores;
    }

    const areaFilter = _.keys(_.pickBy(_areaFilter));
    if (areaFilter.length === 0) {
      return [];
      // return stores;
    }

    stores = stores.filter((it) => {
      return areaFilter.some((a) => {
        return it.area === a;
      });
    });

    const facilityFilter = _.keys(_.pickBy(_facilityFilter));
    if (!_facilityFilter['全選'] && facilityFilter.length > 0) {
      stores = stores.filter((it) => {
        if (!it.facility) {
          return false;
        }
        return facilityFilter.every((f) => {
          return JSON.stringify(it.facility).includes(f);
        });
      });
    }

    const conditionFilter = _.keys(_.pickBy(_conditionFilter));
    if (!_conditionFilter['全選'] && conditionFilter.length > 0) {
      stores = stores.filter((it) => {
        if (!it.facility) {
          return false;
        }
        return conditionFilter.every((c) => {
          return JSON.stringify(it.facility).includes(c);
        });
      });
    }
    return stores;
    // return stores.slice(0, 8);
  }
}

@Pipe({ name: 'storeSlicer' })
export class StoreSlicePipe implements PipeTransform {
  transform(stores: any, len: number, x): Array<any> {
    if (!stores) {
      return stores;
    }

    return stores.slice(0, len);
  }
}

@Pipe({ name: 'storeLiker' })
export class StoreLikePipe implements PipeTransform {
  constructor(
    private _storeService: StoreService
  ) {
  }

  transform(stores: any, x=0): Array<any> {
    const likedStores = this._storeService.getLikedStoreNames();
    if (!stores) {
      return stores;
    }
    stores.forEach((store: any)=> {
      store.user_like = likedStores.has(store.store_name)
    });
    return stores;
  }
}

@Pipe({ name: 'tagFilter' })
export class TagPipe implements PipeTransform {
  transform(experiences: any, _tagFilter: any, x): Array<any> {
    return experiences;
    // if (!experiences || _tagFilter['全選'] ) {
    //   return experiences;
    // }

    // const tagFilter = _.keys(_.pickBy(_tagFilter));
    // if (tagFilter.length === 0) {
    //   return [];
    //   // return stores;
    // }

    // experiences = experiences.filter((it) => {
    //   return tagFilter.some((a) => {
    //     return it.tags.indexOf(a) !== -1;
    //   });
    // });

    // return experiences;
    // // return stores.slice(0, 8);
  }
}

@Pipe({ name: 'hotSearchFilter' })
export class HotSearchPipe implements PipeTransform {
  transform(stores: any, tags: any, cities: any, x): Array<any> {
    if (cities && cities.length > 0) {
      stores = stores
      .filter((it) => {
        return cities.some((f) => {
          return JSON.stringify(it.city).includes(f);
        });
      })
    }

    if (tags && tags.length > 0) {
      stores = stores
      .filter((it) => {
        if (!it.facility) {
          return false;
        }
        return tags.every((f) => {
          return JSON.stringify(it.facility).includes(f);
        });
      });
    }

    return stores;
  }
}
