<!-- Grid row -->
<div class="row mx-m1">
  <div class="col-md-3 col-sm-6 col-12 px-0 pb-2" *ngFor="let stuff of stuffs">
    <div class="card mx-1">
      <div>
        <mdb-carousel
          class="carousel slide carousel-fade"
          [animation]="'fade'"
          [interval]="'0'"
        >
          <mdb-carousel-item *ngFor="let image of stuff.images">
            <img
              class="img-fluid"
              src="{{ image.thumbnail_600c_url }}"
              alt=""
            />
          </mdb-carousel-item>
        </mdb-carousel>
      </div>
      <mdb-card-body class="pb-1">
        <div class="row mt-m10">
          <div class="col-7 ml-0">
            <h5 class="h5-responsive">
              <strong>{{ stuff.name }}</strong>
            </h5>
          </div>
          <div class="col-5">
            <div class="d-flex justify-content-end mr-m4">
              <!-- <i class="fa fa-dollar" aria-hidden="true"></i> -->
              <h5>
                (平日)每{{ stuff.unit }}
                {{ stuff.price_weekday | currency: "TWD":"symbol":"2.0" }} 起
              </h5>
            </div>
          </div>
        </div>
        <div
          class="row grey-text px-2 mb-m10"
          [innerHtml]="stuff.description"
        ></div>
        <a
          class="btn btn-blue-grey mb-3 disabled w-100 ml-0"
          *ngIf="!stuff.sellable"
          mdbRippleRadius
          >關閉中</a
        >
        <!-- <div *ngIf="!userLoggedin || !userVerified; else showAll">
          <a *ngIf="userLoggedin && !userVerified" (click)="goUserVerify()"
            class="btn btn-success mb-3 waves-light align-middle w-100 ml-0" mdbRippleRadius>驗證</a>
          <a *ngIf="!userLoggedin" (click)="goUserLogin()"
            class="btn btn-success mb-3 waves-light align-middle user-login-btn w-100 ml-0" mdbRippleRadius>登入</a>
        </div>
        <ng-template #showAll>
          <a *ngIf="stuff.sellable && stuff.category === 'camp'"
            class="btn btn-success mb-3 waves-light align-middle booking-btn w-100 ml-0"
            (click)="stuffSearchModalShow(stuff)" mdbRippleRadius>預訂</a>
          <a *ngIf="stuff.sellable && stuff.category === 'experience'"
            class="btn btn-purple mb-3 waves-light align-middle exp-btn w-100 ml-0"
            (click)="stuffSearchModalShow(stuff)" mdbRippleRadius>體驗</a>
          <a *ngIf="stuff.sellable && stuff.category === 'shop'"
            class="btn btn-info mb-3 waves-light align-middle product-btn w-100 ml-0"
            (click)="stuffSearchModalShow(stuff)" mdbRippleRadius>商品</a>

          <a class="btn btn-blue-grey mb-3 disabled w-100 ml-0" *ngIf="!stuff.sellable" mdbRippleRadius>關閉中</a>
        </ng-template> -->
      </mdb-card-body>
    </div>
  </div>

  <!-- Section: Blog v.1 -->
</div>
<footer class="page-footer py-3 fixed-line-icon-footer">
  <a
    class="set-url-target"
    rel="noopener"
    data-mobile-target="_blank"
    data-desktop-target="_blank"
    target="_blank"
    href="{{ lineLink }}"
  >
    <span class="sr-only" style="height: 54px; width: 54px">Line</span>
    <div class="svg chaty-animation-jump line-icon start-now">
      <svg
        class="ico_d animated pulse infinite"
        width="49"
        height="49"
        viewBox="0 0 39 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="transform: rotate(0deg)"
      >
        <circle
          class="color-element"
          cx="19.4395"
          cy="19.4395"
          r="19.4395"
          fill="#38B900"
        ></circle>
        <path
          d="M24 9.36561C24 4.19474 18.6178 0 12 0C5.38215 0 0 4.19474 0 9.36561C0 13.9825 4.25629 17.8606 10.0229 18.5993C10.4073 18.6785 10.9565 18.8368 11.0664 19.1797C11.1762 19.4699 11.1487 19.9184 11.0938 20.235C11.0938 20.235 10.9565 21.0528 10.9291 21.2111C10.8741 21.5013 10.6819 22.3456 11.9725 21.8443C13.2632 21.3167 18.8924 17.9398 21.3913 15.1433C23.1487 13.2702 24 11.4234 24 9.36561Z"
          transform="translate(7 10)"
          fill="white"
        ></path>
        <path
          d="M1.0984 0H0.24714C0.10984 0 -2.09503e-07 0.105528 -2.09503e-07 0.211056V5.22364C-2.09503e-07 5.35555 0.10984 5.43469 0.24714 5.43469H1.0984C1.2357 5.43469 1.34554 5.32917 1.34554 5.22364V0.211056C1.34554 0.105528 1.2357 0 1.0984 0Z"
          transform="translate(15.4577 16.8593)"
          fill="#38B900"
          class="color-element"
        ></path>
        <path
          d="M4.66819 0H3.81693C3.67963 0 3.56979 0.105528 3.56979 0.211056V3.19222L1.18078 0.0791458C1.18078 0.0791458 1.18078 0.0527642 1.15332 0.0527642C1.15332 0.0527642 1.15332 0.0527641 1.12586 0.0263821C1.12586 0.0263821 1.12586 0.0263821 1.0984 0.0263821H0.247139C0.10984 0.0263821 4.19006e-07 0.13191 4.19006e-07 0.237438V5.25002C4.19006e-07 5.38193 0.10984 5.46108 0.247139 5.46108H1.0984C1.2357 5.46108 1.34554 5.35555 1.34554 5.25002V2.26885L3.73455 5.38193C3.76201 5.40831 3.76201 5.43469 3.78947 5.43469C3.78947 5.43469 3.78947 5.43469 3.81693 5.43469C3.81693 5.43469 3.81693 5.43469 3.84439 5.43469C3.87185 5.43469 3.87185 5.43469 3.89931 5.43469H4.75057C4.88787 5.43469 4.99771 5.32917 4.99771 5.22364V0.211056C4.91533 0.105528 4.80549 0 4.66819 0Z"
          transform="translate(17.6819 16.8593)"
          fill="#38B900"
          class="color-element"
        ></path>
        <path
          d="M3.62471 4.22112H1.34554V0.237438C1.34554 0.105528 1.2357 0 1.0984 0H0.24714C0.10984 0 -5.23757e-08 0.105528 -5.23757e-08 0.237438V5.25002C-5.23757e-08 5.30278 0.0274599 5.35555 0.0549198 5.40831C0.10984 5.43469 0.16476 5.46108 0.21968 5.46108H3.56979C3.70709 5.46108 3.78947 5.35555 3.78947 5.22364V4.4058C3.87185 4.32665 3.76201 4.22112 3.62471 4.22112Z"
          transform="translate(10.8993 16.8593)"
          fill="#38B900"
          class="color-element"
        ></path>
        <path
          d="M3.56979 1.29272C3.70709 1.29272 3.78947 1.18719 3.78947 1.05528V0.237438C3.78947 0.105528 3.67963 -1.00639e-07 3.56979 -1.00639e-07H0.219679C0.164759 -1.00639e-07 0.10984 0.0263821 0.0549199 0.0527641C0.02746 0.105528 -2.09503e-07 0.158292 -2.09503e-07 0.211056V5.22364C-2.09503e-07 5.2764 0.02746 5.32917 0.0549199 5.38193C0.10984 5.40831 0.164759 5.43469 0.219679 5.43469H3.56979C3.70709 5.43469 3.78947 5.32917 3.78947 5.19726V4.37941C3.78947 4.2475 3.67963 4.14198 3.56979 4.14198H1.29062V3.29775H3.56979C3.70709 3.29775 3.78947 3.19222 3.78947 3.06031V2.24247C3.78947 2.11056 3.67963 2.00503 3.56979 2.00503H1.29062V1.16081H3.56979V1.29272Z"
          transform="translate(23.421 16.8329)"
          fill="#38B900"
          class="color-element"
        ></path>
      </svg></div
  ></a>
</footer>

<footer
  mdbBtn
  type="button"
  mdbWavesEffect
  class="page-footer i-green-bg text-white text-center py-3 fixed-w100"
  *ngIf="stuffs.length > 0"
  (click)="
    !userLoggedin
      ? goUserLogin()
      : !userVerified
      ? goUserVerify()
      : stuffSearchModalShow(stuffs[0])
  "
>
  <!-- <button
    type="button"
    class="btn btn-success waves-light align-middle product-btn w-100 m0"
    (click)="
      !userLoggedin
        ? goUserLogin()
        : !userVerified
        ? goUserVerify()
        : stuffSearchModalShow(stuffs[0])
    "
    mdbWavesEffect
  > -->
  <h5 class="mb-0">
    {{
      !userLoggedin
        ? "訂位前請先登入"
        : !userVerified
        ? "訂位前請先綁定手機"
        : "立即訂位"
    }}
  </h5>
  <!-- </button> -->
</footer>

<div
  *ngIf="stuffSearchModalShown"
  [config]="{ show: true }"
  (onHidden)="onHidden()"
  mdbModal
  #stuffSearchModal="mdb-modal"
  class="modal fade right"
  id="fullHeightModalBottom"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-full-height modal-right" role="document">
    <div class="modal-content">
      <div class="modal-header b0">
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="stuffSearchModal.hide()"
        >
          <span aria-hidden="true">×</span>
        </button>
        選擇預訂日期
      </div>
      <div class="modal-body">
        <form
          [formGroup]="stuffSearchForm"
          (ngSubmit)="
            stuffSearchFormSubmit(
              stuffSearchModalStoreName,
              stuffSearchModalStuffName
            )
          "
          novalidate
        >
          <div class="md-form mt-0 mb-0">
            <mdb-date-picker
              formControlName="checkin_date"
              id="checkin_date"
              name="checkin_date"
              inlineIcon="far fa-calendar-check prefix grey-text"
              [inline]="true"
              [options]="stuffSearchCalOption"
              [placeholder]="'抵達日期'"
              (dateChanged)="onDateChange($event)"
              class="w-100"
            ></mdb-date-picker>
          </div>

          <div class="md-form mt-3">
            <mdb-select
              [mdbValidation]="false"
              mdbActive
              formControlName="stay"
              id="stay"
              [options]="optionsSelect"
              placeholder="住幾個晚上"
              class="w-100"
            ></mdb-select>
            <!--<input
                [mdbValidation]="false"
                mdbInput
                formControlName="stay"
                type="number"
                min="1"
                max="60"
                id="stay"
                class="w-100 py-2"
                mdbActive
              />
              <i class="fas fa-bed prefix yoyo grey-text"></i>
              <label for="stay"> 住幾個晚上</label>-->
            <div
              class="red-text center"
              for="stay"
              *ngIf="stuffSearchForm.value.stay > 1"
            >
              大於1晚的訂位將無法修改天數、區域
            </div>
          </div>

          <div class="text-center">
            <button
              mdbBtn
              mdbRippleRadius
              type="submit"
              size="sm"
              id="submit-search"
              class="btn btn-default waves-light"
              [disabled]="!stuffSearchForm.valid"
            >
              搜尋
            </button>
          </div>
          <p class="note note-success">
            <small>
              <strong>【附註一】</strong>只接受{{
                openDate | utcDateFormat
              }}到{{ closeDate | utcDateFormat }}的預訂
            </small>
            <br />

            <small>
              <strong>【附註二】</strong>{{ weekdayOpen | weekdayFormat }}
            </small>
            <br />

            <small>
              <strong>【附註三】</strong>客滿日期用
              <font color="#e51075">紅線</font>表示
            </small>
          </p>
        </form>
      </div>
    </div>
  </div>
</div>
