import { ActivatedRoute } from '@angular/router';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-me-trip-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class MeTripEditComponent implements OnInit {
  caseId: string;
  storeName: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.caseId = this.route.snapshot.paramMap.get('case_id');
    this.storeName = this.route.snapshot.paramMap.get('store_name');
  }
}
