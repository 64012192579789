import { Component, OnInit } from '@angular/core';
import { StoreService } from '../../../../service/store.service';
import { TripService } from '../../../../service/trip.service';
@Component({
  selector: 'app-me-trip-info-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss'],
})
export class MeTripInfoCompletedComponent implements OnInit {
  cases: Array<any> = [];
  showSpinner = true;

  constructor(
    private _storeService: StoreService,
    private _tripService: TripService,
  ) {}

  ngOnInit() {
    this._tripService
      .caseUserFirebaseUIDList('closed')
      .subscribe((_cases: any) => {
        // 如果抓不到任何closed訂單,還是要把showSpinner關起來
        if (_cases.items === undefined) {
          this.showSpinner = false;
        } else {
          // 如果有closed訂單，則顯示訂單列表
          _cases.items.forEach((caseObj: any) => {
            this._storeService
              .storeNameGetImageLimit(caseObj.store_name)
              .subscribe((image: any) => {
                caseObj['image'] = image[0].thumbnail_600c_url;
              });
          });
          this.cases = _cases.items;
          this.showSpinner = false;
        }
      });
  }
}
