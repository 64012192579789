<div class="text-center pb-3 mt2r">
  <!-- <h3>我的訂單</h3> -->
  <!-- Nav tabs -->
  <div class="bs">
    <mdb-tabset
      #staticTabs
      [buttonClass]="'md-tabs pills-secondary white mb-2 p-0'"
    >
      <!-- Panel 1 -->
      <mdb-tab heading="未付款/待確認">
        <app-me-trip-info-unpay></app-me-trip-info-unpay>
      </mdb-tab>
      <!-- Panel 2 -->
      <mdb-tab heading="已付款">
        <app-me-trip-info-paid></app-me-trip-info-paid>
      </mdb-tab>
      <!-- Panel 3 -->
      <mdb-tab heading="待退款">
        <app-me-trip-info-cancelled></app-me-trip-info-cancelled>
      </mdb-tab>
      <mdb-tab heading="取消/完成">
        <app-me-trip-info-completed></app-me-trip-info-completed>
      </mdb-tab>
      <mdb-tab heading="排隊中">
        <app-me-trip-info-queue></app-me-trip-info-queue>
      </mdb-tab>
    </mdb-tabset>
  </div>
  <div class="ss">
    <mdb-accordion [multiple]="false" aria-multiselectable="true">
      <mdb-accordion-item [collapsed]="false">
        <mdb-accordion-item-head
          ><div class="collapse-header mb-h">
            未付款/待確認
          </div></mdb-accordion-item-head
        >
        <mdb-accordion-item-body>
          <app-me-trip-info-unpay></app-me-trip-info-unpay>
        </mdb-accordion-item-body>
      </mdb-accordion-item>
      <mdb-accordion-item [collapsed]="true">
        <mdb-accordion-item-head
          ><div class="collapse-header mb-h">
            已付款
          </div></mdb-accordion-item-head
        >
        <mdb-accordion-item-body>
          <app-me-trip-info-paid></app-me-trip-info-paid>
        </mdb-accordion-item-body>
      </mdb-accordion-item>
      <mdb-accordion-item [collapsed]="true">
        <mdb-accordion-item-head
          ><div class="collapse-header mb-h">
            待退款
          </div></mdb-accordion-item-head
        >
        <mdb-accordion-item-body>
          <app-me-trip-info-cancelled></app-me-trip-info-cancelled>
        </mdb-accordion-item-body>
      </mdb-accordion-item>
      <mdb-accordion-item [collapsed]="true">
        <mdb-accordion-item-head
          ><div class="collapse-header mb-h">
            取消/完成
          </div></mdb-accordion-item-head
        >
        <mdb-accordion-item-body>
          <app-me-trip-info-completed></app-me-trip-info-completed>
        </mdb-accordion-item-body>
      </mdb-accordion-item>
      <mdb-accordion-item [collapsed]="true">
        <mdb-accordion-item-head
          ><div class="collapse-header mb-h">
            排隊中
          </div></mdb-accordion-item-head
        >
        <mdb-accordion-item-body>
          <app-me-trip-info-queue></app-me-trip-info-queue>
        </mdb-accordion-item-body>
      </mdb-accordion-item>
    </mdb-accordion>
  </div>
</div>
