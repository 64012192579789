import { AuthService } from '../core/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HomeService } from '../service/home.service';
import { StoreService } from '../service/store.service';
import { UserService } from '../service/user.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'store-search-result',
  templateUrl: './store-search-result.component.html',
  styleUrls: ['./store-search-result.component.scss']
})
export class StoreSearchResultComponent implements OnInit, OnDestroy {
  stores: Array<any> = [];
  showFilter = false;
  showSpinner = true;
  navigationSubscription;
  slogan = '營地搜尋結果';
  x = 0;
  keepRefreshStores:any;

  constructor(
    private auth: AuthService,
    private _userService: UserService,
    // private route: ActivatedRoute,
    private router: Router,
    private _homeService: HomeService,
    private _storeService: StoreService,
  ) {
    if (this._homeService.getStoreFilter() === undefined) {
      this._homeService.setAllStoreFilter()
    }

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.init();
      }
    });
  }

  init() {
    this.slogan = this._homeService.getStoreSrearchResultSlogan();
    this.storeList();
  }

  ngOnInit() {
    this.keepRefreshStores = setInterval(() => {
      this.x++;
      if (this.x > 180) {
        clearInterval(this.keepRefreshStores);
      }
    }, 1000);

    this.init();
  }

  subscribeStore(store) {
    if (!this.auth.userLoggedin) {
      this.router.navigate(['/login']);
      return;
    }

    store.user_like = !store.user_like;
    if (store.user_like) {
      this._storeService.pushLikeStoreName(store.store_name);
    } else {
      this._storeService.removeLikeStoreName(store.store_name);
    }
    this._userService
      .userLikeUpdate(store.store_name, !store.user_like)
      .subscribe((resp: any) => {
        this._storeService.getUserLikedStores();
      });
  }

  _filter(stores) {
    const filter = this._homeService.getStoreFilter();
    const expFilter = this._homeService.getExperienceFilter();
    if (
      (expFilter.tagFilter && expFilter.tagFilter.length > 0) ||
      (expFilter.cityFilter && expFilter.cityFilter.length > 0)
    ) {
      console.log(expFilter);
      if (expFilter.cityFilter && expFilter.cityFilter.length > 0) {
        stores = stores
        .filter((it) => {
          return expFilter.cityFilter.some((f) => {
            return JSON.stringify(it.city).includes(f);
          });
        })
      }

      if (expFilter.tagFilter && expFilter.tagFilter.length > 0) {
        stores = stores
        .filter((it) => {
          if (!it.facility) {
            return false;
          }
          return expFilter.tagFilter.every((f) => {
            return JSON.stringify(it.facility).includes(f);
          });
        });
      }

      return stores;
    } else if (filter.cityFilter.length > 0) {
      return stores.filter((it) => {
        return it.city && it.city.includes(filter.cityFilter);
      });
    } else {
      const areaFilter = _.keys(_.pickBy(filter.areaFilter));
      if (areaFilter.length === 0) {
        return [];
      }

      stores = stores.filter((it) => {
        return areaFilter.some((a) => {
          return it.area === a;
        });
      });

      const facilityFilter = _.keys(_.pickBy(filter.facilityFilter));
      if (!filter.facilityFilter['全選'] && facilityFilter.length > 0) {
        stores = stores.filter((it) => {
          if (!it.facility) {
            return false;
          }

          return facilityFilter.every((f) => {
            return JSON.stringify(it.facility).includes(f);
          });
        });
      }

      const conditionFilter = _.keys(_.pickBy(filter.conditionFilter));
      if (!filter.conditionFilter['全選'] && conditionFilter.length > 0) {
        stores = stores.filter((it) => {
          if (!it.facility) {
            return false;
          }

          return conditionFilter.every((c) => {
            return JSON.stringify(it.facility).includes(c);
          });
        });
      }
      return stores;
    }
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  storeList() {
    this._homeService.getStores().subscribe((stores:any) => {
      if (stores.length >= 1) {
        this.stores = this._filter(stores);
      } else {
        this.stores = stores
      }
      this.showSpinner = false;
      this._storeService.hidePageLoading();
    });
  }
}
