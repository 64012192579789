import { ActivatedRoute } from "@angular/router";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IMyOptions } from "ng-uikit-pro-standard";
import { ModalDirective } from "ng-uikit-pro-standard";
import { Router } from "@angular/router";
import { BookingService } from "../../service/booking.service";
import { StoreService } from "../../service/store.service";

import * as moment from "moment";

@Component({
  selector: "app-stuff",
  templateUrl: "./stuff.component.html",
  styleUrls: ["./stuff.component.scss"],
})
export class StoreStuffComponent implements OnInit {
  @ViewChild("autoShownModal") public autoShownModal: ModalDirective;

  @Input() closeDate: Date;
  @Input() openDate: Date;
  @Input() weekdayOpen: Array<string>;
  @Input() userLoggedin = true;
  @Input() userVerified = true;

  _dayBefore = moment().utcOffset("Asia/Taipei").subtract(1, "days");
  dayBefore = this._dayBefore.toDate();
  yearAfter = this._dayBefore.add(1, "years").add(1, "days").toDate();

  birdEyeViewImage: any;
  holidays: Array<any> = [];
  stuffs: Array<any> = [];

  // Date Picker
  stuffSearchCalOption: IMyOptions = {
    closeAfterSelect: true,
    showTodayBtn: false,
    showClearDateBtn: false,
    // Strings and translations
    dayLabels: {
      su: "日",
      mo: "一",
      tu: "二",
      we: "三",
      th: "四",
      fr: "五",
      sa: "六",
    },
    dayLabelsFull: {
      su: "日",
      mo: "一",
      tu: "二",
      we: "三",
      th: "四",
      fr: "五",
      sa: "六",
    },
    monthLabels: {
      1: "一月",
      2: "二月",
      3: "三月",
      4: "四月",
      5: "五月",
      6: "六月",
      7: "七月",
      8: "八月",
      9: "九月",
      10: "十月",
      11: "十一月",
      12: "十二月",
    },
    monthLabelsFull: {
      1: "一月",
      2: "二月",
      3: "三月",
      4: "四月",
      5: "五月",
      6: "六月",
      7: "七月",
      8: "八月",
      9: "九月",
      10: "十月",
      11: "十一月",
      12: "十二月",
    },

    // // Buttons
    todayBtnTxt: "今日",
    clearBtnTxt: "清除",
    closeBtnTxt: "確認",

    firstDayOfWeek: "mo",
    selectionTxtFontSize: "14px",
  };

  stuffSearchForm: FormGroup;
  stuffSearchModalShown = false;
  stuffSearchModalStoreName: string;
  stuffSearchModalStuffName: string;
  storeName: string;
  lineLink: string;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private _bookingService: BookingService,
    private _storeService: StoreService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  optionsSelect: Array<any>;

  // 強制把Froala Editor的 Powered by訊息給隱藏起來！
  // 注意：ngAfterViewInit() 沒有用！
  ngAfterViewChecked() {
    $("mdb-card-body > div.row.grey-text.px-2.mb-m10 > p:nth-child(2)").hide();
  }

  getBookableDuration() {
    let start = moment(this.openDate)
      .utcOffset("Asia/Taipei")
      .subtract(1, "days")
      .toDate();
    if (this.dayBefore > start) {
      start = this.dayBefore;
    }

    let end = this.closeDate;
    if (end > this.yearAfter) {
      end = this.yearAfter;
    }

    return [start, end];
  }

  ngOnInit() {
    const days = this.getBookableDuration();
    const startDate = days[0];
    const endDate = days[1];
    this.stuffSearchCalOption["disableUntil"] = {
      year: startDate.getFullYear(),
      month: startDate.getMonth() + 1,
      day: startDate.getDate(),
    };

    this.stuffSearchCalOption["disableSince"] = {
      year: endDate.getFullYear(),
      month: endDate.getMonth() + 1,
      day: endDate.getDate() + 1,
    };

    // initial stuffSearchForm
    this.stuffSearchForm = this.fb.group({
      checkin_date: [""], // formControlName
      stay: [1, Validators.required], // formControlName
    });

    // Fetch stuff detail
    this.route.params.subscribe((res) => {
      this.storeName = res.store_name;
      if (res.store_name === "glamping900") {
        this.lineLink = "https://lin.ee/hxtQunx";
      } else {
        this.lineLink = "https://lin.ee/XzC6RJJ";
      }

      this._bookingService
        .caseIndexCalList(res.store_name)
        .subscribe((result: any) => {
          const disableDateRanges = [];
          if (!result.cal_dates) {
            return;
          }

          result.cal_dates.forEach((d) => {
            d.day = parseInt(d.day, 10);
            d.month = parseInt(d.month, 10);
            d.year = parseInt(d.year, 10);
            disableDateRanges.push({ begin: d, end: d });
          });
          this.stuffSearchCalOption["markDates"] = [
            {
              dates: result.cal_dates,
              color: "#e51075",
            },
          ];

          //this.stuffSearchCalOption['disableDateRanges'] = disableDateRanges;
        });

      this._bookingService.ruleGet().subscribe((result: any) => {
        this.holidays = result.item.holiday_list.concat(result.item.cny_list);
      });

      this._storeService
        .stuffStoreNameList(res.store_name)
        .subscribe((stuff: any) => {
          if (!stuff.items) {
            return;
          }

          stuff.items.forEach((stuffObj: any) => {
            const stuffName = stuffObj.name;
            this._storeService
              .storeNameGetStuffImage(res.store_name, stuffName)
              .subscribe((stuffImages: any) => {
                stuffObj["images"] = stuffImages;
              });

            stuffObj.description = this.sanitizer.bypassSecurityTrustHtml(
              stuffObj.description
            );
          }); // forEach stuffObj end.

          this.stuffs = stuff.items;
        });

      this.optionsSelect = [
        { value: 1, label: "1晚" },
        { value: 2, label: "2晚" },
        { value: 3, label: "3晚" },
        { value: 4, label: "4晚" },
        { value: 5, label: "5晚" },
      ];

      // this._storeService
      //   .storeNameGetBirdEyeViewImage(res.store_name)
      //   .subscribe((result: any) => {
      //     console.log(result[0]);
      //     this.birdEyeViewImage = result[0];
      //   });

      // hide loading spinner
    });
  }

  public stuffSearchModalShow(stuff: any): void {
    this.stuffSearchModalStoreName = stuff.store_name;
    this.stuffSearchModalStuffName = stuff.name;
    this.stuffSearchModalShown = true;
  }

  public onHidden(): void {
    this.stuffSearchModalShown = false;
  }

  goUserLogin(): void {
    this.router.navigate(["/login"]);
  }

  goUserVerify(): void {
    this.router.navigate(["/profile"]);
  }

  isStoreOpen(d: moment.Moment): boolean {
    for (let i = 0; i < this.stuffSearchForm.value.stay; i++) {
      if (i > 0) {
        d.add(1, "day");
      }

      let wd = d.day() - 1;
      if (d.day() === 0) {
        wd = 6;
      }

      if (
        this.weekdayOpen[wd] === "0" &&
        this.holidays.indexOf(d.format("YYYY-MM-DD")) === -1
      ) {
        return false;
      }
    }
    return true;
  }

  onDateChange(e: any): any {
    if (e.actualDateFormatted.length !== 10) {
      return null;
    }

    if (
      !this.isStoreOpen(moment(e.actualDateFormatted).utcOffset("Asia/Taipei"))
    ) {
      alert("入營期間涵蓋公休日，請重新選擇，謝謝！");
    }
  }

  stuffSearchFormSubmit(stuffSearchModalStoreName, stuffSearchModalStuffName) {
    if (this.stuffSearchForm.value.checkin_date.length < 10) {
      alert("請選擇入營抵達日期，謝謝！");
      return;
    }

    const d = moment(this.stuffSearchForm.value.checkin_date).utcOffset(
      "Asia/Taipei"
    );

    if (!this.isStoreOpen(d)) {
      alert("入營期間涵蓋公休日，請重新選擇，謝謝！");
      return;
    }

    if (this.stuffSearchForm.valid) {
      const checkout = moment(this.stuffSearchForm.value.checkin_date)
        .utcOffset("Asia/Taipei")
        .add(this.stuffSearchForm.value.stay - 1, "day");
      if (checkout.isAfter(this.closeDate)) {
        alert("入營期間超過店家可接受訂位日，請重新選擇，謝謝！");
        return;
      }
      this.router.navigate([
        "/store/stuff/search-result",
        {
          store_name: stuffSearchModalStoreName,
          stuff_name: stuffSearchModalStuffName,
          checkin_date: this.stuffSearchForm.value.checkin_date,
          stay: this.stuffSearchForm.value.stay,
        },
      ]);
    } else {
      console.log("ERROR.......");
    }
  }
}
