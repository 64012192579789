import { ActivatedRoute } from "@angular/router";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/compat/firestore";
import { AuthService } from "../../core/auth.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { ToastService } from "ng-uikit-pro-standard";
import { UserService } from "../../service/user.service";
import { ModalDirective } from "ng-uikit-pro-standard";

@Component({
  selector: "app-user-verify",
  templateUrl: "./verify.component.html",
  styleUrls: ["./verify.component.scss"],
})
export class UserVerifyComponent implements OnInit {
  @ViewChild("emailModal") public emailModal: ModalDirective;

  pincode: number;
  email: string;
  showSpinner: boolean;
  showTmpl: boolean;
  isDisabled: boolean;

  constructor(
    private _http: HttpClient,
    private _userService: UserService,
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    public auth: AuthService
  ) {}

  ngOnInit() {
    const phoneNumber = this.route.snapshot.paramMap.get("phoneNumber");
    const uidFromURL = this.route.snapshot.paramMap.get("uid");
    const status = this.route.snapshot.paramMap.get("status");
    window.sessionStorage.setItem("urlBeforeLogin", "/verify");

    this.showSpinner = true;
    this.showTmpl = true;
    this.isDisabled = false;

    if (status === "ok") {
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(
        `users/${uidFromURL}`
      );

      userRef
        .set({ phoneNumber: phoneNumber, uid: uidFromURL }, { merge: true })
        .then((result) => {
          const firebaseUID = localStorage.getItem("firebaseUID");
          console.log("firebaseUID from localStorage:" + firebaseUID);
          console.log("uid from /profile URL:" + uidFromURL);

          if (firebaseUID === uidFromURL) {
            // prettier-ignore
            console.log(`firebaseUID與uidFromURL相同抓到的uid相同，所以開始進入手機驗證碼發送流程(userActivate)`);
            this.showSpinner = false;
            this.showTmpl = true;
            this._http
              .get(`${environment.cloudFunctionGuest}/userActivate`, {
                params: {
                  phoneNumber: phoneNumber,
                  uid: uidFromURL,
                },
              })
              .subscribe((res: any) => {
                this.showSpinner = false;
                this.showTmpl = true;
                if (res.result === "pin_sent_ok") {
                  this.toastService.success(`驗證碼已發出至: ${phoneNumber}`);
                } else {
                  this.toastService.error(res.result);
                }
              });
          } else {
            console.error("firebaseUID is NOT same as uidFromURL!");
            // prettier-ignore
            console.error(`firebaseUID:${firebaseUID} --- uidFromURL:${uidFromURL}`);
            // prettier-ignore
            this.toastService.error('Hacker!: firebaseUID is NOT same as uidFromURL!');
            this.auth.signOut();
          }
        })
        .catch((error) => console.log(error));
    }
  }

  backToProfile() {
    this.router.navigate(["/profile"]);
  }

  userPinCodeVerify(pinCode: number) {
    this.showSpinner = true;
    this.isDisabled = true;

    // sleep time expects milliseconds
    function sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    }

    const phoneNumber = this.route.snapshot.paramMap.get("phoneNumber");

    this._userService
      .userPinCodeVerify(pinCode, phoneNumber)
      .subscribe((result) => {
        if (result.verifyResult) {
          const firebaseUID = this.route.snapshot.paramMap.get("uid");
          const oauthType = localStorage.getItem("oauthType");
          const oauthUID = localStorage.getItem("oauthUID");

          // prettier-ignore
          console.log('Step1: 更新使用者電話號碼到Firebase內建的Authentication account和icamping-core的ndb');

          // Usage!
          this._userService
            .updateFirebaseAccountPhoneNumberAndIcampingCoreNDB(
              firebaseUID,
              phoneNumber
            )
            .subscribe(
              (r: any) => {
                console.log(r);
                this.toastService.success("手機號碼驗證成功");
                // prettier-ignore
                console.log('Step2: 新增一筆使用者的電話號碼到Firestore的phoneNumber collections');

                this.afs
                  .doc(`phoneNumber/${phoneNumber}`)
                  .set({ uid: firebaseUID }, { merge: true })
                  .then(() => {
                    this.showTmpl = false;
                    this.showSpinner = false;
                    this.router.navigate(["/profile"]);
                  });
              },
              (errRes: any) => {
                console.error(errRes);
                this.showSpinner = false;
                this.isDisabled = false;

                this.toastService.error(
                  `驗證碼正確但還是發生錯誤:${errRes.status}:${errRes.reason}`
                );
              }
            );
        } else {
          this.toastService.error(`驗證錯誤: ${result.mesg}`);
          this.showSpinner = false;
          this.isDisabled = false;
        }
      });
  }

  userPinCodeCreateByEmail() {
    this.isDisabled = true;

    const uidFromURL = this.route.snapshot.paramMap.get("uid");
    this._userService
      .userPinCodeCreateByEmail(uidFromURL, this.email)
      .subscribe((result) => {
        console.log(result);
        this.isDisabled = false;
        this.toastService.success(`驗證碼寄送至: ${this.email}`);
        this.emailModal.hide();
      });
  }

  userPinCodeResend() {
    const phoneNumber = this.route.snapshot.paramMap.get("phoneNumber");
    console.log(phoneNumber);
  }
}
