import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-me-trip-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class MeTripInfoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
