import { Component, OnInit } from '@angular/core';
import { BookingService } from '../service/booking.service';
import { VERSION } from '../../environments/version';

@Component({
  selector: 'icamping-intro',
  templateUrl: './icamping-intro.component.html',
  styleUrls: ['./icamping-intro.component.scss'],
})
export class IcampingIntroComponent implements OnInit {
  // policyDesc: string;
  version: any;

  constructor(private _bookingService: BookingService) {}

  ngOnInit() {
    // this.policyDesc = '';
    this.version = VERSION;
    // this._bookingService.ruleGet().subscribe((result: any) => {
    //   this.policyDesc = result.item.policy_description;
    // });
  }
}
