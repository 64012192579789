import { ButtonsModule, WavesModule, CollapseModule, MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';
import { CalendarHeaderComponent } from './calendar-header.component';
import { CalendarModule } from 'angular-calendar';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeZh from '@angular/common/locales/zh-Hant';


registerLocaleData(localeZh);

@NgModule({
  imports: [
    ButtonsModule,
    CalendarModule,
    CollapseModule,
    CommonModule,
    FormsModule,
    MDBBootstrapModulesPro.forRoot(),
    ToastModule.forRoot(),
    WavesModule,
  ],
  declarations: [CalendarHeaderComponent],
  exports: [CalendarHeaderComponent],
})
export class CalendarUtilsModule { }
