//import { FirebaseApp } from '@angular/fire/compat/compat';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastService } from 'ng-uikit-pro-standard';
import firebase from 'firebase/compat/app';

@Injectable()
export class MessagingService {
  private messageSource = new Subject();

  constructor(
    private afs: AngularFirestore,
    private afMessaging: AngularFireMessaging,
    private toastService: ToastService,
  ) { }
  // get permission to send messages
  getPermission(user) {
        this.afMessaging.requestToken
      .subscribe(
        (token) => {
          console.log('Permission granted! Save to the server!', token);
          this.saveToken(user, token);
        },
        (error) => {
          console.error('Unable to get permission to notify.', error);
          this.toastService.error(
            '您之前已經封鎖愛露營訊息推播，若要重新開啟請參考 https://support.google.com/chrome/answer/3220216',
          );
        },
      );
  }

  // Listen for token refresh
  monitorRefresh(user) {
      firebase
        .messaging()
        .getToken()
        .then(refreshedToken => {
          console.log('Token refreshed.');
          this.saveToken(user, refreshedToken);
        })
        .catch(err => console.log(err, 'Unable to retrieve new token'));
  }

  // used to show message when app is open
  receiveMessages() {
    firebase.messaging().onMessage((payload: any) => {
      console.log('Message received. ', payload);
      this.messageSource.next(payload);
      const options = { positionClass: 'toast-bottom-right', timeOut: 10000 };
      this.toastService.error(
        payload.notification.body,
        payload.notification.title,
        options,
      );
    });
  }

  // save the permission token in firestore
  private saveToken(user, token): void {
    const currentFcmTokens = user.fcmTokens || {};
    console.log(user.uid);
    console.log(currentFcmTokens, token);
    // If token does not exist in firestore, update db
    if (!currentFcmTokens[token]) {
      const userRef = this.afs.collection('users').doc(user.uid);
      const tokens = { ...currentFcmTokens, [token]: true };

      localStorage.setItem('localFcm', token); // 這是要給setting component用的

      userRef.update({ fcmTokens: tokens });
    }
  }
}
