import { Component, OnInit } from '@angular/core';
import { StoreService } from '../../../../service/store.service';
import { TripService } from '../../../../service/trip.service';

@Component({
  selector: 'app-me-trip-info-queue',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.scss'],
})
export class QueueComponent implements OnInit {
  queues: Array<any> = [];
  showSpinner = true;

  constructor(
    private _storeService: StoreService,
    private _tripService: TripService,
  ) {}

  ngOnInit() {
    this._tripService.queueUserFirebaseUIDList().subscribe((_cases: any) => {
      // 如果抓不到任何queue,還是要把showSpinner關起來
      if (_cases.items === undefined) {
        this.showSpinner = false;
      } else {
        // 如果有queue，則顯示queue列表
        _cases.items.forEach((queueObj: any) => {
          this._storeService
            .storeNameGetImageLimit(queueObj.store_name)
            .subscribe((image: any) => {
              queueObj['image'] = image[0].thumbnail_600c_url;
            });
        });
        this.queues = _cases.items;
        this.showSpinner = false;
      }
    });
  }
}
