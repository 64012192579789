import { Component, OnInit } from "@angular/core";
import { MesgService } from "../service/mesg.service";
import { StoreService } from "../service/store.service";
import { ToastService } from "ng-uikit-pro-standard";

@Component({
  selector: "app-mesg",
  templateUrl: "./mesg.component.html",
  styleUrls: ["./mesg.component.scss"],
})
export class MesgComponent implements OnInit {
  showSpinner: boolean;
  mesgs: Array<any> = [];

  constructor(
    private _mesgService: MesgService,
    private _storeService: StoreService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    window.sessionStorage.setItem("urlBeforeLogin", "/mesg");

    let defaultImageUrl =
      "https://firebasestorage.googleapis.com/v0/b/icamping-tree.appspot.com/";
    defaultImageUrl += "o/images%2Fstore%2Ffs009%2Ficon600.jpg?";
    defaultImageUrl += "alt=media&token=80203831-1cba-44bf-845d-22fdfc4190d4";

    this.showSpinner = true;
    this._mesgService.mesgUserIdList().subscribe((result: any) => {
      if (result.items) {
        result.items.forEach((mesg: any) => {
          mesg["image"] = defaultImageUrl;

          this._storeService
            .storeNameGetImageLimit(mesg.store_name)
            .subscribe((image: any) => {
              mesg["image"] = image[0].thumbnail_600c_url;
            });
        });
        this.mesgs = result.items;
      }
      // hide loading spinner
      this.showSpinner = false;
    });
  }

  // function to delete message
  deleteMesg(mi: number) {
    this._mesgService
      .mesgIdDelete(this.mesgs[mi].id)
      .subscribe((deleteResult: any) => {
        this.mesgs.splice(mi, 1);
        this.toastService.success("已刪除訊息");
      });
  }
}
